<template>
  <Scene>
    <Background skyColor="0.949019608 0.941176471 0.901960784"></Background>
    <Viewpoint
      fieldOfView="0.25"
      position="5.67865035461008233e+02 3.87799079517475775e+02 1.25946228169990462e+02"
      description="Default View"
      orientation="9.37145543520705893e-01 2.11497642045378242e-01 2.77537344637664329e-01 -4.91978582149180887e+00"
      centerOfRotation="5.09962586649147852e+02 5.09687954966512621e+02 8.85472278778916433e+01"
    ></Viewpoint>
    <NavigationInfo
      type='"EXAMINE" "FLY" "ANY"'
      speed="4.00000000000000000e+00"
      headlight="true"
    ></NavigationInfo>
    <DirectionalLight
      ambientIntensity="1.00000000000000000e+00"
      intensity="0.00000000000000000e+00"
      color="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
    ></DirectionalLight>
    <Transform
      DEF="ROOT"
      translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
    >
      <Transform
        translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000006e-01"
              diffuseColor="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00"
              specularColor="0.00000000000000000e+00 0.00000000000000000e+00 5.00000000000000028e-02"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            2 3 -1 
            4 5 -1 
            6 7 -1 
            8 9 -1 
            10 11 -1 
            12 13 -1 
            14 15 -1 
            16 17 -1 
            18 19 -1 
            20 21 -1 
            22 23 -1 
            24 25 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0000"
              point="
              5.10000000000000000e+02 5.10000000000000000e+02 9.95000000000000000e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 7.94000015258789063e+01,
              5.00000000000000000e+02 5.01000000000000000e+02 9.83000030517578125e+01,
              5.00000000000000000e+02 5.01000000000000000e+02 7.70999984741210938e+01,
              5.20000000000000000e+02 5.18000000000000000e+02 1.00000000000000000e+02,
              5.20000000000000000e+02 5.18000000000000000e+02 7.70999984741210938e+01,
              5.01000000000000000e+02 5.20000000000000000e+02 1.00000000000000000e+02,
              5.01000000000000000e+02 5.20000000000000000e+02 7.74000015258789063e+01,
              5.19000000000000000e+02 5.02000000000000000e+02 9.96999969482421875e+01,
              5.19000000000000000e+02 5.02000000000000000e+02 7.70999984741210938e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 1.00000000000000000e+02,
              5.04000000000000000e+02 5.08000000000000000e+02 7.70999984741210938e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 1.00000000000000000e+02,
              5.11000000000000000e+02 5.04000000000000000e+02 7.70999984741210938e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 1.00000000000000000e+02,
              5.15000000000000000e+02 5.12000000000000000e+02 7.90000000000000000e+01,
              5.07000000000000000e+02 5.17000000000000000e+02 9.95999984741210938e+01,
              5.07000000000000000e+02 5.17000000000000000e+02 7.76999969482421875e+01,
              5.17000000000000000e+02 5.09000000000000000e+02 9.94000015258789063e+01,
              5.17000000000000000e+02 5.09000000000000000e+02 8.01999969482421875e+01,
              5.01000000000000000e+02 5.11000000000000000e+02 9.95000000000000000e+01,
              5.01000000000000000e+02 5.11000000000000000e+02 8.03000030517578125e+01,
              5.13000000000000000e+02 5.21000000000000000e+02 9.95000000000000000e+01,
              5.13000000000000000e+02 5.21000000000000000e+02 8.05999984741210938e+01,
              5.10000000000000000e+02 5.00000000000000000e+02 9.83000030517578125e+01,
              5.10000000000000000e+02 5.00000000000000000e+02 8.58000030517578125e+01,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.18997228640831054e+02 4.98803911718817517e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            20 19 21 -1 
            19 18 21 -1 
            18 17 21 -1 
            17 16 21 -1 
            21 16 22 -1 
            16 15 22 -1 
            10 9 11 -1 
            11 9 12 -1 
            12 9 13 -1 
            13 9 14 -1 
            9 8 14 -1 
            14 8 15 -1 
            1 0 39 -1 
            39 38 1 -1 
            38 37 1 -1 
            37 36 1 -1 
            1 36 2 -1 
            36 35 2 -1 
            30 29 31 -1 
            31 29 32 -1 
            32 29 33 -1 
            33 29 34 -1 
            29 28 34 -1 
            34 28 35 -1 
            3 2 4 -1 
            27 26 28 -1 
            7 6 8 -1 
            23 22 24 -1 
            2 35 4 -1 
            4 35 5 -1 
            28 26 35 -1 
            26 25 35 -1 
            35 25 5 -1 
            5 25 6 -1 
            8 6 15 -1 
            6 25 15 -1 
            25 24 15 -1 
            24 22 15 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0001"
              point="
              -4.31812400505874827e-01 -4.15703875336581596e-03 0.00000000000000000e+00,
              -4.17262764869094416e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.01154239699801851e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.86084974218850796e-01 -4.15703875336581596e-03 0.00000000000000000e+00,
              -3.74653117647094747e-01 -1.19514864159266931e-02 0.00000000000000000e+00,
              -2.24480092681754395e-01 -2.08891197356632585e-01 0.00000000000000000e+00,
              -7.43070677164140436e-02 -1.19514864159266931e-02 0.00000000000000000e+00,
              -6.33948409888287490e-02 -4.15703875336581596e-03 0.00000000000000000e+00,
              -4.83255755078776938e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.27366801827557730e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.76674147018047178e-02 -3.63740890919506121e-03 0.00000000000000000e+00,
              -6.75518797421942319e-03 -1.45496356367803559e-02 0.00000000000000000e+00,
              -5.19629844170643729e-04 -2.80600115852193133e-02 0.00000000000000000e+00,
              -5.19629844170643729e-04 -4.26096472219996691e-02 0.00000000000000000e+00,
              -6.23555813004866843e-03 -5.61200231704386265e-02 0.00000000000000000e+00,
              -1.67840439667145069e-01 -2.81639375540534476e-01 0.00000000000000000e+00,
              -5.71592828587796919e-03 -5.07678357754801080e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 -5.21708363547410792e-01 0.00000000000000000e+00,
              -5.19629844170643729e-04 -5.35738369340020393e-01 0.00000000000000000e+00,
              -6.75518797421942319e-03 -5.49248745288459350e-01 0.00000000000000000e+00,
              -1.76674147018047178e-02 -5.59641342171873890e-01 0.00000000000000000e+00,
              -3.32563100269265277e-02 -5.63798380925239706e-01 0.00000000000000000e+00,
              -4.88452053520483931e-02 -5.63798380925239706e-01 0.00000000000000000e+00,
              -6.28752111446580497e-02 -5.59641342171873890e-01 0.00000000000000000e+00,
              -7.37874378722432889e-02 -5.51846894509313013e-01 0.00000000000000000e+00,
              -2.24480092681754395e-01 -3.54387553724436422e-01 0.00000000000000000e+00,
              -3.74653117647094747e-01 -5.51846894509313013e-01 0.00000000000000000e+00,
              -3.86084974218850796e-01 -5.60160972016044645e-01 0.00000000000000000e+00,
              -4.00634609855631152e-01 -5.63798380925239706e-01 0.00000000000000000e+00,
              -4.16743135024923717e-01 -5.63798380925239706e-01 0.00000000000000000e+00,
              -4.31292770661704072e-01 -5.59641342171873890e-01 0.00000000000000000e+00,
              -4.42724627233460122e-01 -5.49248745288459350e-01 0.00000000000000000e+00,
              -4.48960185363508846e-01 -5.35738369340020393e-01 0.00000000000000000e+00,
              -4.48960185363508846e-01 -5.21188733703240037e-01 0.00000000000000000e+00,
              -4.42724627233460122e-01 -5.07158727910630436e-01 0.00000000000000000e+00,
              -2.80600115852193022e-01 -2.81639375540534476e-01 0.00000000000000000e+00,
              -4.42724627233460122e-01 -5.61200231704386265e-02 0.00000000000000000e+00,
              -4.48960185363508846e-01 -4.26096472219996691e-02 0.00000000000000000e+00,
              -4.48960185363508846e-01 -2.80600115852193133e-02 0.00000000000000000e+00,
              -4.43244257077630821e-01 -1.45496356367803559e-02 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.98004888433184760e+02 5.19947228640831099e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            29 28 30 -1 
            28 27 30 -1 
            27 26 30 -1 
            26 25 30 -1 
            25 24 30 -1 
            24 23 30 -1 
            23 22 30 -1 
            22 21 30 -1 
            33 32 21 -1 
            32 31 21 -1 
            31 30 21 -1 
            21 20 33 -1 
            33 20 34 -1 
            1 0 2 -1 
            2 0 3 -1 
            3 0 4 -1 
            4 0 5 -1 
            5 0 6 -1 
            6 0 7 -1 
            0 74 7 -1 
            48 47 49 -1 
            49 47 50 -1 
            50 47 51 -1 
            47 46 51 -1 
            51 46 52 -1 
            46 45 52 -1 
            45 44 52 -1 
            52 44 53 -1 
            44 43 53 -1 
            43 42 53 -1 
            42 41 53 -1 
            41 40 53 -1 
            53 40 54 -1 
            40 39 54 -1 
            54 39 55 -1 
            39 38 55 -1 
            38 37 55 -1 
            37 36 55 -1 
            36 35 55 -1 
            73 72 74 -1 
            72 71 74 -1 
            74 71 7 -1 
            71 70 7 -1 
            69 68 70 -1 
            68 67 70 -1 
            67 66 70 -1 
            66 65 70 -1 
            65 64 70 -1 
            55 35 56 -1 
            56 35 57 -1 
            35 34 57 -1 
            57 34 58 -1 
            58 34 59 -1 
            59 34 60 -1 
            60 34 61 -1 
            61 34 62 -1 
            62 34 63 -1 
            63 34 64 -1 
            34 20 64 -1 
            20 19 64 -1 
            19 18 64 -1 
            64 18 70 -1 
            18 17 70 -1 
            17 16 70 -1 
            16 15 70 -1 
            15 14 70 -1 
            14 13 70 -1 
            13 12 70 -1 
            12 11 70 -1 
            11 10 70 -1 
            10 9 70 -1 
            70 9 7 -1 
            9 8 7 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0002"
              point="
              -4.47920925675167392e-01 3.60103482010314446e-01 0.00000000000000000e+00,
              -4.45322776454313729e-01 3.74653117647094802e-01 0.00000000000000000e+00,
              -4.35969439259240643e-01 3.87643863751363005e-01 0.00000000000000000e+00,
              -4.22978693154972440e-01 3.95957941258094637e-01 0.00000000000000000e+00,
              -4.07389797829850631e-01 3.99075720323119054e-01 0.00000000000000000e+00,
              -3.91281272660558066e-01 3.95957941258094637e-01 0.00000000000000000e+00,
              -3.77770896712119164e-01 3.87643863751363005e-01 0.00000000000000000e+00,
              -3.68937189361216777e-01 3.75172747491265446e-01 0.00000000000000000e+00,
              -3.65819410296192360e-01 3.60623111854485201e-01 0.00000000000000000e+00,
              -3.65819410296192360e-01 3.11777906502436197e-03 0.00000000000000000e+00,
              -3.63221261075338753e-01 -9.87296703924384067e-03 0.00000000000000000e+00,
              -3.54907183568607065e-01 -2.75403817410485585e-02 0.00000000000000000e+00,
              -3.43475326996851127e-01 -4.46881665986825910e-02 0.00000000000000000e+00,
              -3.31523840580924323e-01 -5.66396530146093397e-02 0.00000000000000000e+00,
              -3.12297536346607396e-01 -6.65126200538531526e-02 0.00000000000000000e+00,
              -2.82678635228875930e-01 -7.63855870930969932e-02 0.00000000000000000e+00,
              -2.50461584890290800e-01 -8.36604049114871851e-02 0.00000000000000000e+00,
              -2.23960462837583696e-01 -8.57389242881700930e-02 0.00000000000000000e+00,
              -1.89664893122315659e-01 -8.36604049114871851e-02 0.00000000000000000e+00,
              -1.49653395121169652e-01 -7.74248467814384472e-02 0.00000000000000000e+00,
              -1.11720416496706443e-01 -6.85911394305360744e-02 0.00000000000000000e+00,
              -8.21015153789749763e-02 -5.81985425471215206e-02 0.00000000000000000e+00,
              -8.21015153789749763e-02 3.60623111854485201e-01 0.00000000000000000e+00,
              -7.95033661581213136e-02 3.75172747491265446e-01 0.00000000000000000e+00,
              -7.01500289630482277e-02 3.87643863751363005e-01 0.00000000000000000e+00,
              -5.71592828587800250e-02 3.95957941258094637e-01 0.00000000000000000e+00,
              -4.15703875336582152e-02 3.99075720323119054e-01 0.00000000000000000e+00,
              -2.54618623643656505e-02 3.95957941258094637e-01 0.00000000000000000e+00,
              -1.19514864159266931e-02 3.87643863751363005e-01 0.00000000000000000e+00,
              -3.11777906502430646e-03 3.75172747491265446e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 3.60623111854485201e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 -7.89837363139506421e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -8.21015153789750041e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -2.27597871746778813e-01 0.00000000000000000e+00,
              -7.79444766256087718e-03 -2.74884187566315052e-01 0.00000000000000000e+00,
              -3.11777906502436197e-02 -3.16974204944143967e-01 0.00000000000000000e+00,
              -6.75518797421945649e-02 -3.52309034347753514e-01 0.00000000000000000e+00,
              -1.13798935873389351e-01 -3.77770896712119164e-01 0.00000000000000000e+00,
              -1.67320809822974370e-01 -3.93359792037240974e-01 0.00000000000000000e+00,
              -2.25519352370095905e-01 -3.99075720323118999e-01 0.00000000000000000e+00,
              -2.83198265073046684e-01 -3.95438311413923882e-01 0.00000000000000000e+00,
              -3.40357547931826709e-01 -3.84526084686338643e-01 0.00000000000000000e+00,
              -3.91800902504728765e-01 -3.67897929672875323e-01 0.00000000000000000e+00,
              -4.32851660194216281e-01 -3.47112735906046188e-01 0.00000000000000000e+00,
              -4.44283516765972275e-01 -3.35680879334290194e-01 0.00000000000000000e+00,
              -4.49999445051850300e-01 -3.21131243697509783e-01 0.00000000000000000e+00,
              -4.49999445051850300e-01 -3.05542348372388028e-01 0.00000000000000000e+00,
              -4.43763886921801576e-01 -2.90992712735607617e-01 0.00000000000000000e+00,
              -4.32851660194216281e-01 -2.79560856163851623e-01 0.00000000000000000e+00,
              -4.17782394713265171e-01 -2.73844927877973598e-01 0.00000000000000000e+00,
              -4.02193499388143305e-01 -2.73844927877973598e-01 0.00000000000000000e+00,
              -3.87124233907192195e-01 -2.80080486008022322e-01 0.00000000000000000e+00,
              -3.58544592477802238e-01 -2.93590861956461224e-01 0.00000000000000000e+00,
              -3.16454575099973212e-01 -3.06061978216558728e-01 0.00000000000000000e+00,
              -2.69168259280437028e-01 -3.15415315411631814e-01 0.00000000000000000e+00,
              -2.25519352370095905e-01 -3.18533094476656176e-01 0.00000000000000000e+00,
              -1.93821931875681475e-01 -3.16454575099973268e-01 0.00000000000000000e+00,
              -1.64722660602120707e-01 -3.10738646814095243e-01 0.00000000000000000e+00,
              -1.39260798237755057e-01 -3.01385309619022157e-01 0.00000000000000000e+00,
              -1.18995234315096676e-01 -2.89433823203095408e-01 0.00000000000000000e+00,
              -1.03406338989974811e-01 -2.75923447254656506e-01 0.00000000000000000e+00,
              -9.19744824182188170e-02 -2.60854181773705396e-01 0.00000000000000000e+00,
              -8.46996645998286390e-02 -2.45265286448583558e-01 0.00000000000000000e+00,
              -8.21015153789749763e-02 -2.30196020967632448e-01 0.00000000000000000e+00,
              -8.21015153789749763e-02 -1.43937466835291628e-01 0.00000000000000000e+00,
              -1.50692654809511051e-01 -1.61085251692925646e-01 0.00000000000000000e+00,
              -2.24480092681754451e-01 -1.66281550134632916e-01 0.00000000000000000e+00,
              -2.67609369747924819e-01 -1.63163771069608554e-01 0.00000000000000000e+00,
              -3.11258276658265998e-01 -1.53810433874535440e-01 0.00000000000000000e+00,
              -3.51269774659412004e-01 -1.39260798237755057e-01 0.00000000000000000e+00,
              -3.82447565309655679e-01 -1.20554123847608857e-01 0.00000000000000000e+00,
              -4.08948687362362784e-01 -9.66511510157553738e-02 0.00000000000000000e+00,
              -4.29733881129191919e-01 -6.59929902096824533e-02 0.00000000000000000e+00,
              -4.43763886921801576e-01 -3.27366801827558562e-02 0.00000000000000000e+00,
              -4.47920925675167392e-01 5.19629844170726996e-04 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.98940945948910723e+02 4.98917945948910756e+02 9.88522285645371426e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            20 19 21 -1 
            19 18 21 -1 
            18 17 21 -1 
            21 17 22 -1 
            22 17 23 -1 
            17 16 23 -1 
            3 2 4 -1 
            2 1 4 -1 
            1 0 4 -1 
            4 0 5 -1 
            5 0 6 -1 
            0 33 6 -1 
            30 29 31 -1 
            29 28 31 -1 
            28 27 31 -1 
            27 26 31 -1 
            31 26 32 -1 
            26 25 32 -1 
            25 24 32 -1 
            13 12 14 -1 
            12 11 14 -1 
            11 10 14 -1 
            14 10 15 -1 
            10 9 15 -1 
            15 9 16 -1 
            9 8 16 -1 
            8 7 16 -1 
            16 7 23 -1 
            23 7 24 -1 
            7 6 24 -1 
            6 33 24 -1 
            33 32 24 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0003"
              point="
              -4.23498322999143140e-01 -2.82678635228875985e-01 0.00000000000000000e+00,
              -4.40126478012606459e-01 -2.80080486008022322e-01 0.00000000000000000e+00,
              -4.53636853961045361e-01 -2.71766408501290690e-01 0.00000000000000000e+00,
              -4.62990191156118447e-01 -2.59295292241193243e-01 0.00000000000000000e+00,
              -4.65588340376972110e-01 -2.44226026760242132e-01 0.00000000000000000e+00,
              -4.64029450844459901e-01 -2.33313800032656837e-01 0.00000000000000000e+00,
              -4.58833152402752631e-01 -2.22921203149242270e-01 0.00000000000000000e+00,
              -1.21593383535950283e-01 2.02136009382413107e-01 0.00000000000000000e+00,
              -4.23498322999143140e-01 2.02136009382413107e-01 0.00000000000000000e+00,
              -4.39606848168435704e-01 2.04734158603266769e-01 0.00000000000000000e+00,
              -4.53117224116874662e-01 2.14087495798339800e-01 0.00000000000000000e+00,
              -4.62470561311947748e-01 2.27078241902608002e-01 0.00000000000000000e+00,
              -4.65588340376972110e-01 2.42147507383559224e-01 0.00000000000000000e+00,
              -4.62470561311947748e-01 2.57216772864510224e-01 0.00000000000000000e+00,
              -4.53117224116874662e-01 2.70727148812949181e-01 0.00000000000000000e+00,
              -4.39606848168435704e-01 2.79560856163851568e-01 0.00000000000000000e+00,
              -4.23498322999143140e-01 2.82678635228875985e-01 0.00000000000000000e+00,
              -4.26096472219996691e-02 2.82678635228875985e-01 0.00000000000000000e+00,
              -2.65011220527071045e-02 2.79560856163851568e-01 0.00000000000000000e+00,
              -1.24711162600973924e-02 2.71246778657119936e-01 0.00000000000000000e+00,
              -3.11777906502430646e-03 2.58775662397022377e-01 0.00000000000000000e+00,
              5.55111512312578270e-17 2.44226026760242132e-01 0.00000000000000000e+00,
              -2.07851937668290798e-03 2.32794170188486083e-01 0.00000000000000000e+00,
              -6.75518797421936767e-03 2.22921203149242186e-01 0.00000000000000000e+00,
              -3.43994956841021771e-01 -2.02136009382413162e-01 0.00000000000000000e+00,
              -4.26096472219996691e-02 -2.02136009382413162e-01 0.00000000000000000e+00,
              -2.65011220527071045e-02 -2.05253788447437524e-01 0.00000000000000000e+00,
              -1.24711162600973924e-02 -2.14087495798339911e-01 0.00000000000000000e+00,
              -3.11777906502430646e-03 -2.27597871746778840e-01 0.00000000000000000e+00,
              5.55111512312578270e-17 -2.42667137227729923e-01 0.00000000000000000e+00,
              -3.63740890919506121e-03 -2.57736402708681034e-01 0.00000000000000000e+00,
              -1.29907461042681471e-02 -2.70727148812949237e-01 0.00000000000000000e+00,
              -2.70207518968777483e-02 -2.80080486008022322e-01 0.00000000000000000e+00,
              -4.26096472219996691e-02 -2.82678635228875985e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 4.99997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="1.00000000000000019e-02 1.00000000000000019e-02 1.00000000000000019e-02"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0004"
              point="
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              2.00000000000000000e+01 0.00000000000000000e+00 0.00000000000000000e+00,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 4.99997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="1.00000000000000019e-02 1.00000000000000019e-02 1.00000000000000019e-02"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0005"
              point="
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              0.00000000000000000e+00 2.10000000000000000e+01 0.00000000000000000e+00,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 4.99997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="1.00000000000000019e-02 1.00000000000000019e-02 1.00000000000000019e-02"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0006"
              point="
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 2.29000015258789063e+01,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.04997228640831111e+02 4.99588575103220251e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            127 80 128 -1 
            80 109 128 -1 
            95 111 96 -1 
            111 110 96 -1 
            128 109 129 -1 
            129 109 130 -1 
            109 108 130 -1 
            130 108 131 -1 
            131 108 132 -1 
            108 107 132 -1 
            132 107 133 -1 
            107 106 133 -1 
            133 106 134 -1 
            106 105 134 -1 
            134 105 135 -1 
            105 104 135 -1 
            135 104 136 -1 
            136 104 137 -1 
            104 103 137 -1 
            137 103 138 -1 
            103 102 138 -1 
            138 102 139 -1 
            102 101 139 -1 
            139 101 140 -1 
            101 100 140 -1 
            140 100 141 -1 
            96 110 97 -1 
            110 141 97 -1 
            100 99 141 -1 
            99 98 141 -1 
            141 98 97 -1 
            111 95 112 -1 
            127 126 80 -1 
            81 80 126 -1 
            126 125 81 -1 
            81 125 82 -1 
            125 124 82 -1 
            124 123 82 -1 
            95 94 112 -1 
            112 94 113 -1 
            82 123 83 -1 
            123 122 83 -1 
            83 122 84 -1 
            122 121 84 -1 
            84 121 85 -1 
            121 120 85 -1 
            85 120 86 -1 
            120 119 86 -1 
            86 119 87 -1 
            119 118 87 -1 
            87 118 88 -1 
            118 117 88 -1 
            88 117 89 -1 
            117 116 89 -1 
            89 116 90 -1 
            116 115 90 -1 
            90 115 91 -1 
            115 114 91 -1 
            91 114 92 -1 
            114 113 92 -1 
            92 113 93 -1 
            113 94 93 -1 
            157 156 158 -1 
            158 156 159 -1 
            159 156 160 -1 
            154 153 155 -1 
            153 152 155 -1 
            152 151 155 -1 
            151 150 155 -1 
            166 165 167 -1 
            165 164 167 -1 
            164 163 167 -1 
            167 163 168 -1 
            163 162 168 -1 
            168 162 169 -1 
            162 161 169 -1 
            169 161 170 -1 
            161 160 170 -1 
            160 156 170 -1 
            156 155 170 -1 
            170 155 171 -1 
            155 150 171 -1 
            171 150 172 -1 
            172 150 173 -1 
            150 149 173 -1 
            173 149 174 -1 
            149 148 174 -1 
            174 148 175 -1 
            148 147 175 -1 
            175 147 176 -1 
            176 147 177 -1 
            177 147 178 -1 
            178 147 179 -1 
            197 196 198 -1 
            198 196 199 -1 
            199 196 200 -1 
            200 196 201 -1 
            196 195 201 -1 
            201 195 202 -1 
            202 195 203 -1 
            203 195 204 -1 
            204 195 205 -1 
            205 195 206 -1 
            206 195 207 -1 
            195 194 207 -1 
            194 193 207 -1 
            193 192 207 -1 
            192 191 207 -1 
            191 190 207 -1 
            190 189 207 -1 
            181 180 182 -1 
            180 179 182 -1 
            182 179 183 -1 
            183 179 184 -1 
            184 179 185 -1 
            185 179 186 -1 
            186 179 187 -1 
            188 187 189 -1 
            147 146 179 -1 
            146 145 179 -1 
            145 144 179 -1 
            144 143 179 -1 
            143 142 179 -1 
            142 221 179 -1 
            208 207 209 -1 
            179 221 187 -1 
            221 220 187 -1 
            220 219 187 -1 
            187 219 189 -1 
            219 218 189 -1 
            218 217 189 -1 
            217 216 189 -1 
            216 215 189 -1 
            215 214 189 -1 
            214 213 189 -1 
            213 212 189 -1 
            209 207 210 -1 
            207 189 210 -1 
            210 189 211 -1 
            212 211 189 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0007"
              point="
              -3.46863313580844268e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              -3.49523818382998397e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              -3.56507643488652937e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              -3.68147351998077244e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              -3.82780128409924980e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              -4.01403662025003882e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              -4.23685389743044705e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              -4.48627622263239600e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              -4.75232670284780889e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -6.29874511909989443e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -6.40183968018336658e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              -6.48498045525068401e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              -6.54484181329915149e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              -6.56146996831261431e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              -6.56146996831261431e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              -6.54484181329915149e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              -6.48498045525068401e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              -6.40183968018336658e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -6.29874511909989443e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.20590828659572280e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.10946498751763556e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -3.02299858144762623e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              -2.96313722339915875e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              -2.94318343738300237e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              -2.96313722339915875e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              -3.02299858144762623e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              -3.10946498751763556e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              -3.20590828659572280e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -6.03602026988717455e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -6.03602026988717455e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -4.75232670284780889e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -4.38983292355430876e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              -4.04729293027696502e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              -3.73468361602385501e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.46863313580844268e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -3.25246712063341992e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              -3.08618557049878672e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              -2.97976537841262157e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              -2.94318343738300237e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              -2.97976537841262157e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              -3.08618557049878672e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              -3.25246712063341992e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              -3.46863313580844268e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              -3.73468361602385501e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              -4.04729293027696502e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              -4.38983292355430876e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              -4.75232670284780889e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              -5.22124067422747418e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              -5.69680590761252326e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              -6.12248667595718388e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              -6.43842162121298633e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              -6.47832919324529799e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              -6.50825987226953173e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              -6.53486492029107358e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              -6.55481870630722940e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              -6.56812123031800033e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              -6.55814433730992241e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              -6.53486492029107358e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              -6.48830608625337590e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              -6.43509599021029333e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              -6.37523463216182584e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              -6.31869890511605026e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -6.26881444007566069e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -6.21892997503527112e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              -6.16571987899218854e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              -6.11916104495449087e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              -6.07925347292217921e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              -6.04599716289525246e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              -6.01274085286832571e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              -5.98613580484678498e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              -5.97283328083601406e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              -5.74669037265291394e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              -5.41745290338634056e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              -5.06161038609822533e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              -4.75232670284780889e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              -4.48627622263239600e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              -4.23685389743044705e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              -4.01403662025003882e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              -3.82780128409924980e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              -3.65486847195923115e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              2.16166015175022208e-02 -5.15472805417362401e-02 0.00000000000000000e+00,
              -4.95519019401206018e-02 -5.45403484441596698e-02 0.00000000000000000e+00,
              -9.34502311756637560e-02 -6.28544259508912462e-02 0.00000000000000000e+00,
              -1.21718094698551327e-01 -7.74872023627389273e-02 0.00000000000000000e+00,
              -1.44997511717399941e-01 -9.87712407799719583e-02 0.00000000000000000e+00,
              -1.63288482232209486e-01 -1.26706541202590339e-01 0.00000000000000000e+00,
              -1.75925880042441585e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -1.83574831348634726e-01 -2.04858869765867757e-01 0.00000000000000000e+00,
              -1.85902773050519610e-01 -2.55741024107065396e-01 0.00000000000000000e+00,
              -1.83574831348634726e-01 -3.06290615347993844e-01 0.00000000000000000e+00,
              -1.75925880042441585e-01 -3.49191255282729096e-01 0.00000000000000000e+00,
              -1.63288482232209486e-01 -3.84442943911271318e-01 0.00000000000000000e+00,
              -1.44997511717399941e-01 -4.12378244333889588e-01 0.00000000000000000e+00,
              -1.21718094698551327e-01 -4.33662282751122619e-01 0.00000000000000000e+00,
              -9.34502311756637560e-02 -4.47962496062701054e-01 0.00000000000000000e+00,
              -6.01939211487371173e-02 -4.56609136669701987e-01 0.00000000000000000e+00,
              -2.06189122166945404e-02 -4.59269641471856116e-01 0.00000000000000000e+00,
              1.86235336150788466e-02 -4.56609136669701987e-01 0.00000000000000000e+00,
              5.22124067422746752e-02 -4.47962496062701054e-01 0.00000000000000000e+00,
              8.01477071648929451e-02 -4.33662282751122619e-01 0.00000000000000000e+00,
              1.03427124183741670e-01 -4.12378244333889588e-01 0.00000000000000000e+00,
              1.21718094698551216e-01 -3.84442943911271318e-01 0.00000000000000000e+00,
              1.34355492508783425e-01 -3.49191255282729096e-01 0.00000000000000000e+00,
              1.42004443814976455e-01 -3.06290615347993844e-01 0.00000000000000000e+00,
              1.44664948617130640e-01 -2.55741024107065396e-01 0.00000000000000000e+00,
              1.42004443814976455e-01 -2.04858869765867757e-01 0.00000000000000000e+00,
              1.34355492508783425e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              1.21718094698551216e-01 -1.26706541202590339e-01 0.00000000000000000e+00,
              1.03427124183741670e-01 -9.87712407799719583e-02 0.00000000000000000e+00,
              7.24987558586999148e-02 -7.48266975605847984e-02 0.00000000000000000e+00,
              3.42539993277343191e-02 -5.10816922013592301e-01 0.00000000000000000e+00,
              -5.88636687476600251e-02 -5.06493601710091834e-01 0.00000000000000000e+00,
              -1.17394774395050860e-01 -4.93523640799590491e-01 0.00000000000000000e+00,
              -1.55306967825747155e-01 -4.72239602382357460e-01 0.00000000000000000e+00,
              -1.85570209950250420e-01 -4.42974049558662097e-01 0.00000000000000000e+00,
              -2.08849626969099034e-01 -4.06724671629312085e-01 0.00000000000000000e+00,
              -2.25145218882292941e-01 -3.63158905494038287e-01 0.00000000000000000e+00,
              -2.35122111890370911e-01 -3.12609314253109893e-01 0.00000000000000000e+00,
              -2.38447742893063586e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.35122111890370911e-01 -1.98540170860751708e-01 0.00000000000000000e+00,
              -2.25145218882292941e-01 -1.47990579619823315e-01 0.00000000000000000e+00,
              -2.08849626969099034e-01 -1.04424813484549517e-01 0.00000000000000000e+00,
              -1.85570209950250420e-01 -6.78428724549302586e-02 0.00000000000000000e+00,
              -1.55306967825747155e-01 -3.85773196312348965e-02 0.00000000000000000e+00,
              -1.17394774395050860e-01 -1.72932812140018655e-02 0.00000000000000000e+00,
              -7.24987558587000258e-02 -4.32332030350046637e-03 0.00000000000000000e+00,
              -2.06189122166945404e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              2.09514753169637302e-02 -2.99306790242337417e-03 0.00000000000000000e+00,
              5.88636687476599141e-02 -1.13071454091550616e-02 0.00000000000000000e+00,
              9.21199787745866638e-02 -2.49422325201949513e-02 0.00000000000000000e+00,
              1.20387842297474235e-01 -4.38983292355431542e-02 0.00000000000000000e+00,
              1.43667259316322848e-01 -6.78428724549302586e-02 0.00000000000000000e+00,
              1.63288482232209486e-01 -9.64432990780871302e-02 0.00000000000000000e+00,
              1.78253821744326357e-01 -1.30032172205282959e-01 0.00000000000000000e+00,
              1.88895840952942873e-01 -1.67611802535710008e-01 0.00000000000000000e+00,
              1.94881976757789732e-01 -2.09514753169637469e-01 0.00000000000000000e+00,
              1.97209918459674616e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              1.93884287456981830e-01 -3.12609314253109893e-01 0.00000000000000000e+00,
              1.83907394448903916e-01 -3.63158905494038287e-01 0.00000000000000000e+00,
              1.67279239435440541e-01 -4.06724671629312085e-01 0.00000000000000000e+00,
              1.43999822416592038e-01 -4.42974049558662097e-01 0.00000000000000000e+00,
              1.03094561083472369e-01 -4.76562922685857926e-01 0.00000000000000000e+00,
              6.04267153189256057e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              6.01606648387102094e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              5.94622823281447443e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              5.82983114772023026e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              5.68350338360175344e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              5.49726804745096498e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              5.27445077027055786e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              5.02502844506860891e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              4.75897796485319491e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              3.21255954860111048e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              3.10946498751763833e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              3.02632421245031979e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              2.96646285440185342e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              2.94983469938838949e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              2.94983469938838949e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              2.96646285440185342e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              3.02632421245031979e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              3.10946498751763833e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              3.21255954860111048e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              6.30539638110528156e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              6.40183968018336991e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              6.48830608625337701e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              6.54816744430184450e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              6.56812123031800033e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              6.54816744430184450e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              6.48830608625337701e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              6.40183968018336991e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              6.30539638110528156e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              3.47528439781382925e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              3.47528439781382925e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              4.75897796485319491e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              5.12147174414669504e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              5.46401173742403934e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              5.77662105167714879e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              6.04267153189256057e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              6.25883754706758388e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              6.42511909720221652e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              6.53153928928838168e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              6.56812123031800033e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              6.53153928928838168e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              6.42511909720221652e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              6.25883754706758388e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              6.04267153189256057e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              5.77662105167714879e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              5.46401173742403934e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              5.12147174414669504e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              4.75897796485319491e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              4.29006399347352962e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              3.81449876008848054e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              3.38881799174381992e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              3.07288304648801747e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              3.03297547445570581e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              3.00304479543147207e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              2.97643974740993023e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              2.95648596139377551e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              2.94318343738300459e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              2.95316033039108250e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              2.97643974740993023e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              3.02299858144762679e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              3.07620867749071047e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              3.13607003553917796e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              3.19260576258495243e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              3.24249022762534311e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              3.29237469266573379e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              3.34558478870881526e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              3.39214362274651293e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              3.43205119477882681e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              3.46530750480575245e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              3.49856381483267809e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              3.52516886285421993e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              3.53847138686498974e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              3.76461429504808986e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              4.09385176431466435e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              4.44969428160277847e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              4.75897796485319491e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              5.02502844506860891e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              5.27445077027055786e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              5.49726804745096498e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              5.68350338360175344e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              5.85643619574177210e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.09997228640831111e+02 4.99588575103220251e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            94 93 95 -1 
            93 92 95 -1 
            92 91 95 -1 
            91 90 95 -1 
            90 89 95 -1 
            89 88 95 -1 
            88 87 95 -1 
            95 87 96 -1 
            87 86 96 -1 
            85 84 86 -1 
            86 84 96 -1 
            104 103 105 -1 
            103 102 105 -1 
            102 101 105 -1 
            101 100 105 -1 
            100 99 105 -1 
            99 98 105 -1 
            105 98 80 -1 
            81 80 82 -1 
            82 80 83 -1 
            83 80 84 -1 
            84 80 96 -1 
            96 80 97 -1 
            80 98 97 -1 
            153 106 154 -1 
            106 135 154 -1 
            121 137 122 -1 
            137 136 122 -1 
            154 135 155 -1 
            155 135 156 -1 
            135 134 156 -1 
            156 134 157 -1 
            157 134 158 -1 
            134 133 158 -1 
            158 133 159 -1 
            133 132 159 -1 
            159 132 160 -1 
            132 131 160 -1 
            160 131 161 -1 
            131 130 161 -1 
            161 130 162 -1 
            162 130 163 -1 
            130 129 163 -1 
            163 129 164 -1 
            129 128 164 -1 
            164 128 165 -1 
            128 127 165 -1 
            165 127 166 -1 
            127 126 166 -1 
            166 126 167 -1 
            122 136 123 -1 
            136 167 123 -1 
            126 125 167 -1 
            125 124 167 -1 
            167 124 123 -1 
            137 121 138 -1 
            153 152 106 -1 
            107 106 152 -1 
            152 151 107 -1 
            107 151 108 -1 
            151 150 108 -1 
            150 149 108 -1 
            121 120 138 -1 
            138 120 139 -1 
            108 149 109 -1 
            149 148 109 -1 
            109 148 110 -1 
            148 147 110 -1 
            110 147 111 -1 
            147 146 111 -1 
            111 146 112 -1 
            146 145 112 -1 
            112 145 113 -1 
            145 144 113 -1 
            113 144 114 -1 
            144 143 114 -1 
            114 143 115 -1 
            143 142 115 -1 
            115 142 116 -1 
            142 141 116 -1 
            116 141 117 -1 
            141 140 117 -1 
            117 140 118 -1 
            140 139 118 -1 
            118 139 119 -1 
            139 120 119 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0008"
              point="
              -2.54909616356392255e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              -2.57570121158546383e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              -2.64553946264200923e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              -2.76193654773625230e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              -2.90826431185472967e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              -3.09449964800551869e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              -3.31731692518592691e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              -3.56673925038787587e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              -3.83278973060328876e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -5.37920814685537430e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -5.48230270793884644e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              -5.56544348300616387e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              -5.62530484105463136e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              -5.64193299606809417e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              -5.64193299606809417e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              -5.62530484105463136e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              -5.56544348300616387e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              -5.48230270793884644e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -5.37920814685537430e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -2.28637131435120267e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -2.18992801527311542e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -2.10346160920310610e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              -2.04360025115463861e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              -2.02364646513848223e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              -2.04360025115463861e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              -2.10346160920310610e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              -2.18992801527311542e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              -2.28637131435120267e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -5.11648329764265442e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -5.11648329764265442e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -3.83278973060328876e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -3.47029595130978863e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              -3.12775595803244488e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              -2.81514664377933488e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -2.54909616356392255e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.33293014838889978e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              -2.16664859825426659e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              -2.06022840616810143e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              -2.02364646513848223e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              -2.06022840616810143e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              -2.16664859825426659e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              -2.33293014838889978e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              -2.54909616356392255e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              -2.81514664377933488e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              -3.12775595803244488e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              -3.47029595130978863e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              -3.83278973060328876e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              -4.30170370198295349e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              -4.77726893536800368e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              -5.20294970371266374e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              -5.51888464896846620e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              -5.55879222100077786e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              -5.58872290002501160e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              -5.61532794804655344e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              -5.63528173406270927e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              -5.64858425807348019e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              -5.63860736506540228e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              -5.61532794804655344e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              -5.56876911400885577e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              -5.51555901796577319e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              -5.45569765991730571e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              -5.39916193287153012e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -5.34927746783114055e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -5.29939300279075098e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              -5.24618290674766841e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              -5.19962407270997073e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              -5.15971650067765908e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              -5.12646019065073233e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              -5.09320388062380558e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              -5.06659883260226485e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              -5.05329630859149392e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              -4.82715340040839325e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              -4.49791593114182042e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              -4.14207341385370575e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              -3.83278973060328876e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              -3.56673925038787587e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              -3.31731692518592691e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              -3.09449964800551869e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              -2.90826431185472967e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              -2.73533149971471101e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              9.47804835767407372e-03 -7.98151440646233112e-03 0.00000000000000000e+00,
              1.77921258644057056e-02 -1.99537860161558278e-03 0.00000000000000000e+00,
              2.84341450730222212e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              3.87436011813694359e-02 -1.99537860161558278e-03 0.00000000000000000e+00,
              4.73902417883703686e-02 -7.31638820592384054e-03 0.00000000000000000e+00,
              5.30438144929479272e-02 -1.52979026123862827e-02 0.00000000000000000e+00,
              5.50391930945635099e-02 -2.46096694199257060e-02 0.00000000000000000e+00,
              5.50391930945635099e-02 -4.86207252593666650e-01 0.00000000000000000e+00,
              5.30438144929479272e-02 -4.95851582501475319e-01 0.00000000000000000e+00,
              4.73902417883703686e-02 -5.03833096907937761e-01 0.00000000000000000e+00,
              3.87436011813694359e-02 -5.09154106512246019e-01 0.00000000000000000e+00,
              2.87667081732915220e-02 -5.10816922013592301e-01 0.00000000000000000e+00,
              1.84572520649441962e-02 -5.09154106512246019e-01 0.00000000000000000e+00,
              1.01431745582125643e-02 -5.03833096907937761e-01 0.00000000000000000e+00,
              4.15703875336581596e-03 -4.95851582501475319e-01 0.00000000000000000e+00,
              2.49422325201953399e-03 -4.86207252593666650e-01 0.00000000000000000e+00,
              2.49422325201953399e-03 -8.77966584710861420e-02 0.00000000000000000e+00,
              -9.89375223301066642e-02 -1.90891219554558567e-01 0.00000000000000000e+00,
              -1.07251599836838241e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.17228492844916155e-01 -1.98540170860751708e-01 0.00000000000000000e+00,
              -1.27537948953263425e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.36184589560264357e-01 -1.91556345755097113e-01 0.00000000000000000e+00,
              -1.42170725365111161e-01 -1.83907394448904027e-01 0.00000000000000000e+00,
              -1.44166103966726744e-01 -1.74263064541095303e-01 0.00000000000000000e+00,
              -1.42835851565649707e-01 -1.64951297733555879e-01 0.00000000000000000e+00,
              -1.37182278861072204e-01 -1.56637220226824192e-01 0.00000000000000000e+00,
              3.89265108865175735e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              3.18096605407552913e-01 -5.45403484441596698e-02 0.00000000000000000e+00,
              2.74198276172009758e-01 -6.28544259508912462e-02 0.00000000000000000e+00,
              2.45930412649122188e-01 -7.74872023627389273e-02 0.00000000000000000e+00,
              2.22650995630273574e-01 -9.87712407799719583e-02 0.00000000000000000e+00,
              2.04360025115463917e-01 -1.26706541202590339e-01 0.00000000000000000e+00,
              1.91722627305231819e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              1.84073675999038788e-01 -2.04858869765867757e-01 0.00000000000000000e+00,
              1.81745734297153905e-01 -2.55741024107065396e-01 0.00000000000000000e+00,
              1.84073675999038788e-01 -3.06290615347993844e-01 0.00000000000000000e+00,
              1.91722627305231819e-01 -3.49191255282729096e-01 0.00000000000000000e+00,
              2.04360025115463917e-01 -3.84442943911271318e-01 0.00000000000000000e+00,
              2.22650995630273574e-01 -4.12378244333889588e-01 0.00000000000000000e+00,
              2.45930412649122188e-01 -4.33662282751122619e-01 0.00000000000000000e+00,
              2.74198276172009758e-01 -4.47962496062701054e-01 0.00000000000000000e+00,
              3.07454586198936397e-01 -4.56609136669701987e-01 0.00000000000000000e+00,
              3.47029595130978974e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              3.86272040962752472e-01 -4.56609136669701987e-01 0.00000000000000000e+00,
              4.19860914089948190e-01 -4.47962496062701054e-01 0.00000000000000000e+00,
              4.47796214512566570e-01 -4.33662282751122619e-01 0.00000000000000000e+00,
              4.71075631531415184e-01 -4.12378244333889588e-01 0.00000000000000000e+00,
              4.89366602046224730e-01 -3.84442943911271318e-01 0.00000000000000000e+00,
              5.02003999856456828e-01 -3.49191255282729096e-01 0.00000000000000000e+00,
              5.09652951162650081e-01 -3.06290615347993844e-01 0.00000000000000000e+00,
              5.12313455964804043e-01 -2.55741024107065396e-01 0.00000000000000000e+00,
              5.09652951162650081e-01 -2.04858869765867757e-01 0.00000000000000000e+00,
              5.02003999856456828e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              4.89366602046224730e-01 -1.26706541202590339e-01 0.00000000000000000e+00,
              4.71075631531415184e-01 -9.87712407799719583e-02 0.00000000000000000e+00,
              4.40147263206373318e-01 -7.48266975605847984e-02 0.00000000000000000e+00,
              4.01902506675407722e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              3.08784838600013267e-01 -5.06493601710091834e-01 0.00000000000000000e+00,
              2.50253732952622543e-01 -4.93523640799590491e-01 0.00000000000000000e+00,
              2.12341539521926359e-01 -4.72239602382357460e-01 0.00000000000000000e+00,
              1.82078297397423206e-01 -4.42974049558662097e-01 0.00000000000000000e+00,
              1.58798880378574481e-01 -4.06724671629312085e-01 0.00000000000000000e+00,
              1.42503288465380518e-01 -3.63158905494038287e-01 0.00000000000000000e+00,
              1.32526395457302604e-01 -3.12609314253109893e-01 0.00000000000000000e+00,
              1.29200764454609818e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              1.32526395457302604e-01 -1.98540170860751708e-01 0.00000000000000000e+00,
              1.42503288465380518e-01 -1.47990579619823315e-01 0.00000000000000000e+00,
              1.58798880378574481e-01 -1.04424813484549517e-01 0.00000000000000000e+00,
              1.82078297397423206e-01 -6.78428724549302586e-02 0.00000000000000000e+00,
              2.12341539521926359e-01 -3.85773196312348965e-02 0.00000000000000000e+00,
              2.50253732952622543e-01 -1.72932812140018655e-02 0.00000000000000000e+00,
              2.95149751488973489e-01 -4.32332030350046637e-03 0.00000000000000000e+00,
              3.47029595130978974e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              3.88599982664637134e-01 -2.99306790242337417e-03 0.00000000000000000e+00,
              4.26512176095333539e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              4.59768486122260067e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              4.88036349645147749e-01 -4.38983292355431542e-02 0.00000000000000000e+00,
              5.11315766663996363e-01 -6.78428724549302586e-02 0.00000000000000000e+00,
              5.30936989579883112e-01 -9.64432990780871302e-02 0.00000000000000000e+00,
              5.45902329091999872e-01 -1.30032172205282959e-01 0.00000000000000000e+00,
              5.56544348300616387e-01 -1.67611802535710008e-01 0.00000000000000000e+00,
              5.62530484105463358e-01 -2.09514753169637469e-01 0.00000000000000000e+00,
              5.64858425807348019e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              5.61532794804655455e-01 -3.12609314253109893e-01 0.00000000000000000e+00,
              5.51555901796577541e-01 -3.63158905494038287e-01 0.00000000000000000e+00,
              5.34927746783114277e-01 -4.06724671629312085e-01 0.00000000000000000e+00,
              5.11648329764265442e-01 -4.42974049558662097e-01 0.00000000000000000e+00,
              4.70743068431145884e-01 -4.76562922685857926e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.14997228640831054e+02 4.99588575103220251e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            94 93 95 -1 
            93 92 95 -1 
            92 91 95 -1 
            91 90 95 -1 
            90 89 95 -1 
            89 88 95 -1 
            88 87 95 -1 
            95 87 96 -1 
            87 86 96 -1 
            85 84 86 -1 
            86 84 96 -1 
            104 103 105 -1 
            103 102 105 -1 
            102 101 105 -1 
            101 100 105 -1 
            100 99 105 -1 
            99 98 105 -1 
            105 98 80 -1 
            81 80 82 -1 
            82 80 83 -1 
            83 80 84 -1 
            84 80 96 -1 
            96 80 97 -1 
            80 98 97 -1 
            121 120 122 -1 
            122 120 123 -1 
            123 120 124 -1 
            118 117 119 -1 
            117 116 119 -1 
            116 115 119 -1 
            115 114 119 -1 
            130 129 131 -1 
            129 128 131 -1 
            128 127 131 -1 
            131 127 132 -1 
            127 126 132 -1 
            132 126 133 -1 
            126 125 133 -1 
            133 125 134 -1 
            125 124 134 -1 
            124 120 134 -1 
            120 119 134 -1 
            134 119 135 -1 
            119 114 135 -1 
            135 114 136 -1 
            136 114 137 -1 
            114 113 137 -1 
            137 113 138 -1 
            113 112 138 -1 
            138 112 139 -1 
            112 111 139 -1 
            139 111 140 -1 
            140 111 141 -1 
            141 111 142 -1 
            142 111 143 -1 
            161 160 162 -1 
            162 160 163 -1 
            163 160 164 -1 
            164 160 165 -1 
            160 159 165 -1 
            165 159 166 -1 
            166 159 167 -1 
            167 159 168 -1 
            168 159 169 -1 
            169 159 170 -1 
            170 159 171 -1 
            159 158 171 -1 
            158 157 171 -1 
            157 156 171 -1 
            156 155 171 -1 
            155 154 171 -1 
            154 153 171 -1 
            145 144 146 -1 
            144 143 146 -1 
            146 143 147 -1 
            147 143 148 -1 
            148 143 149 -1 
            149 143 150 -1 
            150 143 151 -1 
            152 151 153 -1 
            111 110 143 -1 
            110 109 143 -1 
            109 108 143 -1 
            108 107 143 -1 
            107 106 143 -1 
            106 185 143 -1 
            172 171 173 -1 
            143 185 151 -1 
            185 184 151 -1 
            184 183 151 -1 
            151 183 153 -1 
            183 182 153 -1 
            182 181 153 -1 
            181 180 153 -1 
            180 179 153 -1 
            179 178 153 -1 
            178 177 153 -1 
            177 176 153 -1 
            173 171 174 -1 
            171 153 174 -1 
            174 153 175 -1 
            176 175 153 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0009"
              point="
              -2.28637131435120267e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              -2.31297636237274395e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              -2.38281461342928935e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              -2.49921169852353242e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              -2.64553946264200979e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              -2.83177479879279881e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              -3.05459207597320703e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              -3.30401440117515599e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              -3.57006488139056888e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -5.11648329764265442e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -5.21957785872612656e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              -5.30271863379344399e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              -5.36257999184191148e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              -5.37920814685537430e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              -5.37920814685537430e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              -5.36257999184191148e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              -5.30271863379344399e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              -5.21957785872612656e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -5.11648329764265442e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -2.02364646513848279e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.92720316606039554e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -1.84073675999038622e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              -1.78087540194191873e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              -1.76092161592576235e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              -1.78087540194191873e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              -1.84073675999038622e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              -1.92720316606039554e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              -2.02364646513848279e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -4.85375844842993454e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -4.85375844842993454e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -3.57006488139056888e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -3.20757110209706875e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              -2.86503110881972500e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              -2.55242179456661500e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -2.28637131435120267e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.07020529917617990e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              -1.90392374904154671e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              -1.79750355695538155e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              -1.76092161592576235e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              -1.79750355695538155e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              -1.90392374904154671e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              -2.07020529917617990e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              -2.28637131435120267e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              -2.55242179456661500e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              -2.86503110881972500e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              -3.20757110209706875e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              -3.57006488139056888e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              -4.03897885277023361e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              -4.51454408615528380e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              -4.94022485449994386e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              -5.25615979975574632e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              -5.29606737178805798e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              -5.32599805081229172e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              -5.35260309883383356e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              -5.37255688484998939e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              -5.38585940886076031e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              -5.37588251585268240e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              -5.35260309883383356e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              -5.30604426479613589e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              -5.25283416875305331e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              -5.19297281070458583e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              -5.13643708365881024e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -5.08655261861842067e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -5.03666815357803110e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              -4.98345805753494853e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              -4.93689922349725085e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              -4.89699165146493920e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              -4.86373534143801245e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              -4.83047903141108625e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              -4.80387398338954497e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              -4.79057145937877404e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              -4.56442855119567337e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              -4.23519108192910054e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              -3.87934856464098587e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              -3.57006488139056888e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              -3.30401440117515599e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              -3.05459207597320703e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              -2.83177479879279881e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              -2.64553946264200979e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              -2.47260665050199113e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              3.57505332789460617e-02 -7.98151440646233112e-03 0.00000000000000000e+00,
              4.40646107856776936e-02 -1.99537860161558278e-03 0.00000000000000000e+00,
              5.47066299942942091e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              6.50160861026414238e-02 -1.99537860161558278e-03 0.00000000000000000e+00,
              7.36627267096423566e-02 -7.31638820592384054e-03 0.00000000000000000e+00,
              7.93162994142199151e-02 -1.52979026123862827e-02 0.00000000000000000e+00,
              8.13116780158354979e-02 -2.46096694199257060e-02 0.00000000000000000e+00,
              8.13116780158354979e-02 -4.86207252593666650e-01 0.00000000000000000e+00,
              7.93162994142199151e-02 -4.95851582501475319e-01 0.00000000000000000e+00,
              7.36627267096423566e-02 -5.03833096907937761e-01 0.00000000000000000e+00,
              6.50160861026414238e-02 -5.09154106512246019e-01 0.00000000000000000e+00,
              5.50391930945635099e-02 -5.10816922013592301e-01 0.00000000000000000e+00,
              4.47297369862161842e-02 -5.09154106512246019e-01 0.00000000000000000e+00,
              3.64156594794845523e-02 -5.03833096907937761e-01 0.00000000000000000e+00,
              3.04295236746378039e-02 -4.95851582501475319e-01 0.00000000000000000e+00,
              2.87667081732915220e-02 -4.86207252593666650e-01 0.00000000000000000e+00,
              2.87667081732915220e-02 -8.77966584710861420e-02 0.00000000000000000e+00,
              -7.26650374088346762e-02 -1.90891219554558567e-01 0.00000000000000000e+00,
              -8.09791149155662526e-02 -1.96877355359405370e-01 0.00000000000000000e+00,
              -9.09560079236441665e-02 -1.98540170860751708e-01 0.00000000000000000e+00,
              -1.01265464031991437e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.09912104638992369e-01 -1.91556345755097113e-01 0.00000000000000000e+00,
              -1.15898240443839173e-01 -1.83907394448904027e-01 0.00000000000000000e+00,
              -1.17893619045454756e-01 -1.74263064541095303e-01 0.00000000000000000e+00,
              -1.16563366644377719e-01 -1.64951297733555879e-01 0.00000000000000000e+00,
              -1.10909793939800216e-01 -1.56637220226824192e-01 0.00000000000000000e+00,
              4.86040971043532055e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              4.83380466241377871e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              4.76396641135723220e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              4.64756932626299246e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              4.50124156214451343e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              4.31500622599372496e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              4.09218894881331674e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              3.84276662361136778e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              3.57671614339595489e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              2.03029772714386936e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              1.92720316606039610e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              1.84406239099307978e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              1.78420103294461230e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              1.76757287793114837e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              1.76757287793114837e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              1.78420103294461230e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              1.84406239099307978e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              1.92720316606039610e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              2.03029772714386936e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              5.12313455964803932e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              5.21957785872612767e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              5.30604426479613700e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              5.36590562284460448e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              5.38585940886076031e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              5.36590562284460448e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              5.30604426479613700e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              5.21957785872612767e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              5.12313455964803932e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              2.29302257635658813e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              2.29302257635658813e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              3.57671614339595489e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              3.93920992268945391e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              4.28174991596679710e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              4.59435923021990655e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              4.86040971043532055e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              5.07657572561034387e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              5.24285727574497651e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              5.34927746783114166e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              5.38585940886076031e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              5.34927746783114166e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              5.24285727574497651e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              5.07657572561034387e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              4.86040971043532055e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              4.59435923021990655e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              4.28174991596679710e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              3.93920992268945391e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              3.57671614339595489e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              3.10780217201628961e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              2.63223693863123942e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              2.20655617028657991e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              1.89062122503077745e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              1.85071365299846469e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              1.82078297397423094e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              1.79417792595269021e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              1.77422413993653327e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              1.76092161592576346e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              1.77089850893384138e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              1.79417792595269021e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              1.84073675999038677e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              1.89394685603346935e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              1.95380821408193683e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              2.01034394112771242e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              2.06022840616810199e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              2.11011287120849156e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              2.16332296725157414e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              2.20988180128927292e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              2.24978937332158346e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              2.28304568334851021e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              2.31630199337543696e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              2.34290704139697881e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              2.35620956540774862e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              2.58235247359084985e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              2.91158994285742323e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              3.26743246014553734e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              3.57671614339595489e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              3.84276662361136778e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              4.09218894881331674e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              4.31500622599372496e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              4.50124156214451343e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              4.67417437428453209e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99588575103220251e+02 5.04997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            127 80 128 -1 
            80 109 128 -1 
            95 111 96 -1 
            111 110 96 -1 
            128 109 129 -1 
            129 109 130 -1 
            109 108 130 -1 
            130 108 131 -1 
            131 108 132 -1 
            108 107 132 -1 
            132 107 133 -1 
            107 106 133 -1 
            133 106 134 -1 
            106 105 134 -1 
            134 105 135 -1 
            105 104 135 -1 
            135 104 136 -1 
            136 104 137 -1 
            104 103 137 -1 
            137 103 138 -1 
            103 102 138 -1 
            138 102 139 -1 
            102 101 139 -1 
            139 101 140 -1 
            101 100 140 -1 
            140 100 141 -1 
            96 110 97 -1 
            110 141 97 -1 
            100 99 141 -1 
            99 98 141 -1 
            141 98 97 -1 
            111 95 112 -1 
            127 126 80 -1 
            81 80 126 -1 
            126 125 81 -1 
            81 125 82 -1 
            125 124 82 -1 
            124 123 82 -1 
            95 94 112 -1 
            112 94 113 -1 
            82 123 83 -1 
            123 122 83 -1 
            83 122 84 -1 
            122 121 84 -1 
            84 121 85 -1 
            121 120 85 -1 
            85 120 86 -1 
            120 119 86 -1 
            86 119 87 -1 
            119 118 87 -1 
            87 118 88 -1 
            118 117 88 -1 
            88 117 89 -1 
            117 116 89 -1 
            89 116 90 -1 
            116 115 90 -1 
            90 115 91 -1 
            115 114 91 -1 
            91 114 92 -1 
            114 113 92 -1 
            92 113 93 -1 
            113 94 93 -1 
            157 156 158 -1 
            158 156 159 -1 
            159 156 160 -1 
            154 153 155 -1 
            153 152 155 -1 
            152 151 155 -1 
            151 150 155 -1 
            166 165 167 -1 
            165 164 167 -1 
            164 163 167 -1 
            167 163 168 -1 
            163 162 168 -1 
            168 162 169 -1 
            162 161 169 -1 
            169 161 170 -1 
            161 160 170 -1 
            160 156 170 -1 
            156 155 170 -1 
            170 155 171 -1 
            155 150 171 -1 
            171 150 172 -1 
            172 150 173 -1 
            150 149 173 -1 
            173 149 174 -1 
            149 148 174 -1 
            174 148 175 -1 
            148 147 175 -1 
            175 147 176 -1 
            176 147 177 -1 
            177 147 178 -1 
            178 147 179 -1 
            197 196 198 -1 
            198 196 199 -1 
            199 196 200 -1 
            200 196 201 -1 
            196 195 201 -1 
            201 195 202 -1 
            202 195 203 -1 
            203 195 204 -1 
            204 195 205 -1 
            205 195 206 -1 
            206 195 207 -1 
            195 194 207 -1 
            194 193 207 -1 
            193 192 207 -1 
            192 191 207 -1 
            191 190 207 -1 
            190 189 207 -1 
            181 180 182 -1 
            180 179 182 -1 
            182 179 183 -1 
            183 179 184 -1 
            184 179 185 -1 
            185 179 186 -1 
            186 179 187 -1 
            188 187 189 -1 
            147 146 179 -1 
            146 145 179 -1 
            145 144 179 -1 
            144 143 179 -1 
            143 142 179 -1 
            142 221 179 -1 
            208 207 209 -1 
            179 221 187 -1 
            221 220 187 -1 
            220 219 187 -1 
            187 219 189 -1 
            219 218 189 -1 
            218 217 189 -1 
            217 216 189 -1 
            216 215 189 -1 
            215 214 189 -1 
            214 213 189 -1 
            213 212 189 -1 
            209 207 210 -1 
            207 189 210 -1 
            210 189 211 -1 
            212 211 189 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0010"
              point="
              -1.00367543661264436e+00 -1.25376288801513219e-01 0.00000000000000000e+00,
              -1.00633594141479854e+00 -9.27851049751251544e-02 0.00000000000000000e+00,
              -1.01331976652045297e+00 -6.98382510565458414e-02 0.00000000000000000e+00,
              -1.02495947502987717e+00 -5.32100960430825221e-02 0.00000000000000000e+00,
              -1.03959225144172507e+00 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.05821578505680391e+00 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.08049751277484463e+00 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.10543974529503952e+00 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.13204479331658092e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.28668663494178959e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.29699609105013680e+00 -7.31638820592386829e-03 0.00000000000000000e+00,
              -1.30531016855686843e+00 -1.66281550134633749e-03 0.00000000000000000e+00,
              -1.31129630436171518e+00 6.31869890511604915e-03 0.00000000000000000e+00,
              -1.31295911986306146e+00 1.59630288129247178e-02 0.00000000000000000e+00,
              -1.31295911986306146e+00 2.30466228486601199e-01 0.00000000000000000e+00,
              -1.31129630436171518e+00 2.39777995294140678e-01 0.00000000000000000e+00,
              -1.30531016855686843e+00 2.48092072800872310e-01 0.00000000000000000e+00,
              -1.29699609105013680e+00 2.53413082405180567e-01 0.00000000000000000e+00,
              -1.28668663494178959e+00 2.55408461006796150e-01 0.00000000000000000e+00,
              -9.77402951691372257e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -9.67758621783563644e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -9.59111981176562711e-01 2.47759509700603009e-01 0.00000000000000000e+00,
              -9.53125845371715963e-01 2.39112869093602132e-01 0.00000000000000000e+00,
              -9.51130466770100269e-01 2.29468539185793352e-01 0.00000000000000000e+00,
              -9.53125845371715963e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -9.59111981176562711e-01 2.11177568670983806e-01 0.00000000000000000e+00,
              -9.67758621783563644e-01 2.05523995966406303e-01 0.00000000000000000e+00,
              -9.77402951691372257e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.26041415002051749e+00 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.26041415002051749e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.13204479331658092e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.09579541538723091e+00 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.06154141605949648e+00 3.09283683250417551e-02 0.00000000000000000e+00,
              -1.03028048463418553e+00 1.72932812140018100e-02 0.00000000000000000e+00,
              -1.00367543661264436e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -9.82058835095142024e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -9.65430680081678760e-01 -4.75565233385050190e-02 0.00000000000000000e+00,
              -9.54788660873062245e-01 -7.61569499616618906e-02 0.00000000000000000e+00,
              -9.51130466770100269e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -9.54788660873062245e-01 -1.36683434210668253e-01 0.00000000000000000e+00,
              -9.65430680081678760e-01 -1.65283860833825125e-01 0.00000000000000000e+00,
              -9.82058835095142024e-01 -1.91223782654827867e-01 0.00000000000000000e+00,
              -1.00367543661264436e+00 -2.12840384172330144e-01 0.00000000000000000e+00,
              -1.03028048463418553e+00 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.06154141605949648e+00 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.09579541538723091e+00 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.13204479331658092e+00 -2.55408461006796150e-01 0.00000000000000000e+00,
              -1.17893619045454745e+00 -2.51750266903834230e-01 0.00000000000000000e+00,
              -1.22649271379305236e+00 -2.40110558394409923e-01 0.00000000000000000e+00,
              -1.26906079062751842e+00 -2.22817277180408113e-01 0.00000000000000000e+00,
              -1.30065428515309867e+00 -2.00535549462367291e-01 0.00000000000000000e+00,
              -1.30464504235632983e+00 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.30763811025875332e+00 -1.92886598156174177e-01 0.00000000000000000e+00,
              -1.31029861506090728e+00 -1.88230714752404465e-01 0.00000000000000000e+00,
              -1.31229399366252286e+00 -1.83242268248365481e-01 0.00000000000000000e+00,
              -1.31362424606360007e+00 -1.77588695543787950e-01 0.00000000000000000e+00,
              -1.31262655676279216e+00 -1.71602559738941174e-01 0.00000000000000000e+00,
              -1.31029861506090728e+00 -1.65948987034363643e-01 0.00000000000000000e+00,
              -1.30564273165713773e+00 -1.59962851229516867e-01 0.00000000000000000e+00,
              -1.30032172205282937e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -1.29433558624798262e+00 -1.52979026123862272e-01 0.00000000000000000e+00,
              -1.28868201354340517e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.28369356703936610e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.27870512053532703e+00 -1.53644152324400818e-01 0.00000000000000000e+00,
              -1.27338411093101889e+00 -1.55972094026285674e-01 0.00000000000000000e+00,
              -1.26872822752724912e+00 -1.58632598828439803e-01 0.00000000000000000e+00,
              -1.26473747032401795e+00 -1.61625666730863204e-01 0.00000000000000000e+00,
              -1.26141183932132539e+00 -1.64618734633286579e-01 0.00000000000000000e+00,
              -1.25808620831863260e+00 -1.67279239435440708e-01 0.00000000000000000e+00,
              -1.25542570351647842e+00 -1.69607181137325591e-01 0.00000000000000000e+00,
              -1.25409545111540144e+00 -1.70604870438133382e-01 0.00000000000000000e+00,
              -1.23148116029709143e+00 -1.82577142047826935e-01 0.00000000000000000e+00,
              -1.19855741337043398e+00 -1.93551724356712695e-01 0.00000000000000000e+00,
              -1.16297316164162257e+00 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.13204479331658092e+00 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.10543974529503952e+00 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.08049751277484463e+00 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.05821578505680391e+00 -1.86567899251058128e-01 0.00000000000000000e+00,
              -1.03959225144172507e+00 -1.74263064541095303e-01 0.00000000000000000e+00,
              -1.02229897022772320e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -6.35195521514297812e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.06364024971920634e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -7.50262354207463789e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -7.78530217730351359e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -8.01809634749199973e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -8.20100605264009519e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -8.32738003074241617e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -8.40386954380434759e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -8.42714896082319642e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -8.40386954380434759e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -8.32738003074241617e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -8.20100605264009519e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -8.01809634749199973e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.78530217730351359e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -7.50262354207463789e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -7.17006044180537150e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -6.77431035248494573e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -6.38188589416721186e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -6.04599716289525357e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -5.76664415866907087e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -5.53384998848058363e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -5.35094028333248817e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -5.22456630523016607e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.14807679216823577e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.12147174414669393e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.14807679216823577e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -5.22456630523016607e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -5.35094028333248817e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -5.53384998848058363e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -5.84313367173100118e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -6.22558123704065713e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.15675791779460058e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -7.74206897426850893e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -8.12119090857547188e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -8.42382332982050452e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -8.65661750000899066e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -8.81957341914092918e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -8.91934234922170943e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -8.95259865924863618e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -8.91934234922170943e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -8.81957341914092918e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -8.65661750000899066e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -8.42382332982050452e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -8.12119090857547188e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -7.74206897426850893e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -7.29310878890500058e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -6.77431035248494573e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -6.35860647714836302e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -5.97948454284140118e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -5.64692144257213369e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -5.36424280734325798e-01 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.13144863715477184e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -4.93523640799590546e-01 1.58965161928709020e-01 0.00000000000000000e+00,
              -4.78558301287473675e-01 1.25376288801513192e-01 0.00000000000000000e+00,
              -4.67916282078857160e-01 8.77966584710861420e-02 0.00000000000000000e+00,
              -4.61930146274010300e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -4.59602204572125417e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.62927835574818203e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.72904728582896117e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.89532883596359492e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.12812300615207994e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -5.53717561948327663e-01 -2.21154461679061776e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -1.25376288801513219e-01 0.00000000000000000e+00,
              -5.52054746446979383e-02 -9.27851049751251544e-02 0.00000000000000000e+00,
              -6.21892997503525891e-02 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.38290082597770070e-02 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.84617846716246881e-02 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.07085318286703535e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.29367046004744246e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.54309278524939142e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.80914326546480542e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.35556168171688984e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.45865624280036199e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -3.54179701786768053e-01 -1.66281550134633749e-03 0.00000000000000000e+00,
              -3.60165837591614690e-01 6.31869890511604915e-03 0.00000000000000000e+00,
              -3.61828653092961083e-01 1.59630288129247178e-02 0.00000000000000000e+00,
              -3.61828653092961083e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -3.60165837591614690e-01 2.39777995294140678e-01 0.00000000000000000e+00,
              -3.54179701786768053e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -3.45865624280036199e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -3.35556168171688984e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -2.62724849212718770e-02 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.66281550134630418e-02 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.98151440646233112e-03 2.47759509700603009e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.39112869093602132e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 2.29468539185793352e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.98151440646233112e-03 2.11177568670983806e-01 0.00000000000000000e+00,
              -1.66281550134630418e-02 2.05523995966406303e-01 0.00000000000000000e+00,
              -2.62724849212718770e-02 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417107e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417107e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.80914326546480542e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.44664948617130529e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.10410949289396099e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.91500178640851537e-02 1.72932812140018100e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.09283683250416441e-02 -2.19491646177715771e-02 0.00000000000000000e+00,
              -1.43002133115783803e-02 -4.75565233385050190e-02 0.00000000000000000e+00,
              -3.65819410296186476e-03 -7.61569499616618906e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -1.06420192086165072e-01 0.00000000000000000e+00,
              -3.65819410296186476e-03 -1.36683434210668253e-01 0.00000000000000000e+00,
              -1.43002133115783803e-02 -1.65283860833825125e-01 0.00000000000000000e+00,
              -3.09283683250416441e-02 -1.91223782654827867e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.91500178640851537e-02 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.10410949289396099e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.44664948617130529e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.80914326546480542e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.27805723684447070e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -2.75362247022951978e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -3.17930323857418040e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -3.49523818382998286e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -3.53514575586229451e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -3.56507643488652826e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -3.61163526892422482e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -3.62493779293499574e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -3.61496089992691783e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -3.54512264887037354e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -3.49191255282728985e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -3.43205119477882237e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -3.37551546773304789e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.32563100269265721e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.27574653765226653e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -3.22253644160918507e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -3.17597760757148739e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -3.13607003553917352e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -3.10281372551224788e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -3.06955741548532224e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -3.04295236746378039e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -3.02964984345301058e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -2.80350693526991046e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -2.47426946600333597e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -2.11842694871522186e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.80914326546480542e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.54309278524939142e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.29367046004744246e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.07085318286703535e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.84617846716246881e-02 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.11685034576228226e-02 -1.55639530926016401e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99588575103220251e+02 5.09997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            94 93 95 -1 
            93 92 95 -1 
            92 91 95 -1 
            91 90 95 -1 
            90 89 95 -1 
            89 88 95 -1 
            88 87 95 -1 
            95 87 96 -1 
            87 86 96 -1 
            85 84 86 -1 
            86 84 96 -1 
            104 103 105 -1 
            103 102 105 -1 
            102 101 105 -1 
            101 100 105 -1 
            100 99 105 -1 
            99 98 105 -1 
            105 98 80 -1 
            81 80 82 -1 
            82 80 83 -1 
            83 80 84 -1 
            84 80 96 -1 
            96 80 97 -1 
            80 98 97 -1 
            153 106 154 -1 
            106 135 154 -1 
            121 137 122 -1 
            137 136 122 -1 
            154 135 155 -1 
            155 135 156 -1 
            135 134 156 -1 
            156 134 157 -1 
            157 134 158 -1 
            134 133 158 -1 
            158 133 159 -1 
            133 132 159 -1 
            159 132 160 -1 
            132 131 160 -1 
            160 131 161 -1 
            131 130 161 -1 
            161 130 162 -1 
            162 130 163 -1 
            130 129 163 -1 
            163 129 164 -1 
            129 128 164 -1 
            164 128 165 -1 
            128 127 165 -1 
            165 127 166 -1 
            127 126 166 -1 
            166 126 167 -1 
            122 136 123 -1 
            136 167 123 -1 
            126 125 167 -1 
            125 124 167 -1 
            167 124 123 -1 
            137 121 138 -1 
            153 152 106 -1 
            107 106 152 -1 
            152 151 107 -1 
            107 151 108 -1 
            151 150 108 -1 
            150 149 108 -1 
            121 120 138 -1 
            138 120 139 -1 
            108 149 109 -1 
            149 148 109 -1 
            109 148 110 -1 
            148 147 110 -1 
            110 147 111 -1 
            147 146 111 -1 
            111 146 112 -1 
            146 145 112 -1 
            112 145 113 -1 
            145 144 113 -1 
            113 144 114 -1 
            144 143 114 -1 
            114 143 115 -1 
            143 142 115 -1 
            115 142 116 -1 
            142 141 116 -1 
            116 141 117 -1 
            141 140 117 -1 
            117 140 118 -1 
            140 139 118 -1 
            118 139 119 -1 
            139 120 119 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0011"
              point="
              -8.19768042163740329e-01 -1.25376288801513219e-01 0.00000000000000000e+00,
              -8.22428546965894403e-01 -9.27851049751251544e-02 0.00000000000000000e+00,
              -8.29412372071548942e-01 -6.98382510565458414e-02 0.00000000000000000e+00,
              -8.41052080580973249e-01 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.55684856992821041e-01 -3.85773196312348410e-02 0.00000000000000000e+00,
              -8.74308390607899888e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -8.96590118325940710e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -9.21532350846135606e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -9.48137398867676895e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.10277924049288556e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.11308869660123277e+00 -7.31638820592386829e-03 0.00000000000000000e+00,
              -1.12140277410796441e+00 -1.66281550134633749e-03 0.00000000000000000e+00,
              -1.12738890991281115e+00 6.31869890511604915e-03 0.00000000000000000e+00,
              -1.12905172541415744e+00 1.59630288129247178e-02 0.00000000000000000e+00,
              -1.12905172541415744e+00 2.30466228486601199e-01 0.00000000000000000e+00,
              -1.12738890991281115e+00 2.39777995294140678e-01 0.00000000000000000e+00,
              -1.12140277410796441e+00 2.48092072800872310e-01 0.00000000000000000e+00,
              -1.11308869660123277e+00 2.53413082405180567e-01 0.00000000000000000e+00,
              -1.10277924049288556e+00 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.93495557242468230e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.83851227334659617e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.75204586727658684e-01 2.47759509700603009e-01 0.00000000000000000e+00,
              -7.69218450922811936e-01 2.39112869093602132e-01 0.00000000000000000e+00,
              -7.67223072321196242e-01 2.29468539185793352e-01 0.00000000000000000e+00,
              -7.69218450922811936e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.75204586727658684e-01 2.11177568670983806e-01 0.00000000000000000e+00,
              -7.83851227334659617e-01 2.05523995966406303e-01 0.00000000000000000e+00,
              -7.93495557242468230e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.07650675557161346e+00 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.07650675557161346e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -9.48137398867676895e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -9.11888020938326882e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -8.77634021610592452e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -8.46373090185281507e-01 1.72932812140018100e-02 0.00000000000000000e+00,
              -8.19768042163740329e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -7.98151440646237997e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -7.81523285632774734e-01 -4.75565233385050190e-02 0.00000000000000000e+00,
              -7.70881266424158218e-01 -7.61569499616618906e-02 0.00000000000000000e+00,
              -7.67223072321196242e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -7.70881266424158218e-01 -1.36683434210668253e-01 0.00000000000000000e+00,
              -7.81523285632774734e-01 -1.65283860833825125e-01 0.00000000000000000e+00,
              -7.98151440646237997e-01 -1.91223782654827867e-01 0.00000000000000000e+00,
              -8.19768042163740329e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -8.46373090185281507e-01 -2.30466228486601199e-01 0.00000000000000000e+00,
              -8.77634021610592452e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -9.11888020938326882e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -9.48137398867676895e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -9.95028796005643423e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -1.04258531934414833e+00 -2.40110558394409923e-01 0.00000000000000000e+00,
              -1.08515339617861439e+00 -2.22817277180408113e-01 0.00000000000000000e+00,
              -1.11674689070419464e+00 -2.00535549462367291e-01 0.00000000000000000e+00,
              -1.12073764790742580e+00 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.12373071580984929e+00 -1.92886598156174177e-01 0.00000000000000000e+00,
              -1.12639122061200325e+00 -1.88230714752404465e-01 0.00000000000000000e+00,
              -1.12838659921361906e+00 -1.83242268248365481e-01 0.00000000000000000e+00,
              -1.12971685161469604e+00 -1.77588695543787950e-01 0.00000000000000000e+00,
              -1.12871916231388814e+00 -1.71602559738941174e-01 0.00000000000000000e+00,
              -1.12639122061200325e+00 -1.65948987034363643e-01 0.00000000000000000e+00,
              -1.12173533720823371e+00 -1.59962851229516867e-01 0.00000000000000000e+00,
              -1.11641432760392534e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -1.11042819179907859e+00 -1.52979026123862272e-01 0.00000000000000000e+00,
              -1.10477461909450114e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.09978617259046207e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.09479772608642301e+00 -1.53644152324400818e-01 0.00000000000000000e+00,
              -1.08947671648211486e+00 -1.55972094026285674e-01 0.00000000000000000e+00,
              -1.08482083307834509e+00 -1.58632598828439803e-01 0.00000000000000000e+00,
              -1.08083007587511393e+00 -1.61625666730863204e-01 0.00000000000000000e+00,
              -1.07750444487242136e+00 -1.64618734633286579e-01 0.00000000000000000e+00,
              -1.07417881386972858e+00 -1.67279239435440708e-01 0.00000000000000000e+00,
              -1.07151830906757439e+00 -1.69607181137325591e-01 0.00000000000000000e+00,
              -1.07018805666649741e+00 -1.70604870438133382e-01 0.00000000000000000e+00,
              -1.04757376584818740e+00 -1.82577142047826935e-01 0.00000000000000000e+00,
              -1.01465001892152995e+00 -1.93551724356712695e-01 0.00000000000000000e+00,
              -9.79065767192718539e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -9.48137398867676895e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -9.21532350846135606e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -8.96590118325940710e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -8.74308390607899888e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.55684856992821041e-01 -1.74263064541095303e-01 0.00000000000000000e+00,
              -8.38391575778819176e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -5.55380377449673945e-01 2.47426946600333819e-01 0.00000000000000000e+00,
              -5.47066299942942313e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -5.36424280734325798e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -5.26114824625978583e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -5.17468184018977650e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -5.11814611314400092e-01 2.40110558394409868e-01 0.00000000000000000e+00,
              -5.09819232712784509e-01 2.30798791586870444e-01 0.00000000000000000e+00,
              -5.09819232712784509e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -5.11814611314400092e-01 -2.40443121494679196e-01 0.00000000000000000e+00,
              -5.17468184018977650e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -5.26114824625978583e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -5.36091717634056497e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -5.46401173742403823e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -5.54715251249135455e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -5.60701387053982203e-01 -2.40443121494679196e-01 0.00000000000000000e+00,
              -5.62364202555328485e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -5.62364202555328485e-01 1.67611802535710008e-01 0.00000000000000000e+00,
              -6.63795948137454683e-01 6.45172414522375837e-02 0.00000000000000000e+00,
              -6.72110025644186315e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -6.82086918652264229e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -6.92396374760611444e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -7.01043015367612377e-01 6.38521152516990376e-02 0.00000000000000000e+00,
              -7.07029151172459125e-01 7.15010665578921234e-02 0.00000000000000000e+00,
              -7.09024529774074708e-01 8.11453964657008475e-02 0.00000000000000000e+00,
              -7.07694277372997727e-01 9.04571632732402708e-02 0.00000000000000000e+00,
              -7.02040704668420279e-01 9.87712407799719583e-02 0.00000000000000000e+00,
              -1.75593316942172284e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -2.46761820399795107e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -2.90660149635338261e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -3.18928013158225832e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -3.42207430177074445e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -3.60498400691884102e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -3.73135798502116200e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -3.80784749808309231e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -3.83112691510194114e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -3.80784749808309231e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -3.73135798502116200e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -3.60498400691884102e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -3.42207430177074445e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -3.18928013158225832e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -2.90660149635338261e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -2.57403839608411622e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -2.17828830676369045e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.78586384844595547e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -1.44997511717399830e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -1.17062211294781449e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -9.37827942759328348e-02 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.54918237611232890e-02 -1.29034482904475140e-01 0.00000000000000000e+00,
              -6.28544259508911907e-02 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.52054746446979383e-02 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.52054746446979383e-02 5.05495912409283932e-02 0.00000000000000000e+00,
              -6.28544259508911907e-02 9.34502311756637005e-02 0.00000000000000000e+00,
              -7.54918237611232890e-02 1.28701919804205811e-01 0.00000000000000000e+00,
              -9.37827942759328348e-02 1.56637220226824192e-01 0.00000000000000000e+00,
              -1.24711162600974701e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -1.62955919131940297e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.56073587207334752e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -3.14604692854725476e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.52516886285421660e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -3.82780128409924814e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -4.06059545428773538e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -4.22355137341967501e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.32332030350045415e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.35657661352738201e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.32332030350045415e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -4.22355137341967501e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -4.06059545428773538e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -3.82780128409924814e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.52516886285421660e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -3.14604692854725476e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -2.69708674318374531e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -2.17828830676369045e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.76258443142710886e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -1.38346249712014480e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -1.05089939685087952e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -7.68220761622002701e-02 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.35426591433516563e-02 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.39214362274649073e-02 1.58965161928709020e-01 0.00000000000000000e+00,
              -1.89560967153481474e-02 1.25376288801513192e-01 0.00000000000000000e+00,
              -8.31407750673163193e-03 8.77966584710861420e-02 0.00000000000000000e+00,
              -2.32794170188466154e-03 4.58937078371586815e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.32563100269256395e-03 -5.72008532463137154e-02 0.00000000000000000e+00,
              -1.33025240107704779e-02 -1.07750444487242136e-01 0.00000000000000000e+00,
              -2.99306790242337417e-02 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.32100960430825776e-02 -1.87565588551865947e-01 0.00000000000000000e+00,
              -9.41153573762021356e-02 -2.21154461679061776e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99588575103220251e+02 5.14997228640831054e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            94 93 95 -1 
            93 92 95 -1 
            92 91 95 -1 
            91 90 95 -1 
            90 89 95 -1 
            89 88 95 -1 
            88 87 95 -1 
            95 87 96 -1 
            87 86 96 -1 
            85 84 86 -1 
            86 84 96 -1 
            104 103 105 -1 
            103 102 105 -1 
            102 101 105 -1 
            101 100 105 -1 
            100 99 105 -1 
            99 98 105 -1 
            105 98 80 -1 
            81 80 82 -1 
            82 80 83 -1 
            83 80 84 -1 
            84 80 96 -1 
            96 80 97 -1 
            80 98 97 -1 
            121 120 122 -1 
            122 120 123 -1 
            123 120 124 -1 
            118 117 119 -1 
            117 116 119 -1 
            116 115 119 -1 
            115 114 119 -1 
            130 129 131 -1 
            129 128 131 -1 
            128 127 131 -1 
            131 127 132 -1 
            127 126 132 -1 
            132 126 133 -1 
            126 125 133 -1 
            133 125 134 -1 
            125 124 134 -1 
            124 120 134 -1 
            120 119 134 -1 
            134 119 135 -1 
            119 114 135 -1 
            135 114 136 -1 
            136 114 137 -1 
            114 113 137 -1 
            137 113 138 -1 
            113 112 138 -1 
            138 112 139 -1 
            112 111 139 -1 
            139 111 140 -1 
            140 111 141 -1 
            141 111 142 -1 
            142 111 143 -1 
            161 160 162 -1 
            162 160 163 -1 
            163 160 164 -1 
            164 160 165 -1 
            160 159 165 -1 
            165 159 166 -1 
            166 159 167 -1 
            167 159 168 -1 
            168 159 169 -1 
            169 159 170 -1 
            170 159 171 -1 
            159 158 171 -1 
            158 157 171 -1 
            157 156 171 -1 
            156 155 171 -1 
            155 154 171 -1 
            154 153 171 -1 
            145 144 146 -1 
            144 143 146 -1 
            146 143 147 -1 
            147 143 148 -1 
            148 143 149 -1 
            149 143 150 -1 
            150 143 151 -1 
            152 151 153 -1 
            111 110 143 -1 
            110 109 143 -1 
            109 108 143 -1 
            108 107 143 -1 
            107 106 143 -1 
            106 185 143 -1 
            172 171 173 -1 
            143 185 151 -1 
            185 184 151 -1 
            184 183 151 -1 
            151 183 153 -1 
            183 182 153 -1 
            182 181 153 -1 
            181 180 153 -1 
            180 179 153 -1 
            179 178 153 -1 
            178 177 153 -1 
            177 176 153 -1 
            173 171 174 -1 
            171 153 174 -1 
            174 153 175 -1 
            176 175 153 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0012"
              point="
              -7.67223072321196353e-01 -1.25376288801513219e-01 0.00000000000000000e+00,
              -7.69883577123350427e-01 -9.27851049751251544e-02 0.00000000000000000e+00,
              -7.76867402229004966e-01 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.88507110738429273e-01 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.03139887150277065e-01 -3.85773196312348410e-02 0.00000000000000000e+00,
              -8.21763420765355912e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -8.44045148483396734e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -8.68987381003591630e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -8.95592429025132919e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.05023427065034158e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.06054372675868880e+00 -7.31638820592386829e-03 0.00000000000000000e+00,
              -1.06885780426542043e+00 -1.66281550134633749e-03 0.00000000000000000e+00,
              -1.07484394007026718e+00 6.31869890511604915e-03 0.00000000000000000e+00,
              -1.07650675557161346e+00 1.59630288129247178e-02 0.00000000000000000e+00,
              -1.07650675557161346e+00 2.30466228486601199e-01 0.00000000000000000e+00,
              -1.07484394007026718e+00 2.39777995294140678e-01 0.00000000000000000e+00,
              -1.06885780426542043e+00 2.48092072800872310e-01 0.00000000000000000e+00,
              -1.06054372675868880e+00 2.53413082405180567e-01 0.00000000000000000e+00,
              -1.05023427065034158e+00 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.40950587399924254e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.31306257492115641e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.22659616885114708e-01 2.47759509700603009e-01 0.00000000000000000e+00,
              -7.16673481080267960e-01 2.39112869093602132e-01 0.00000000000000000e+00,
              -7.14678102478652266e-01 2.29468539185793352e-01 0.00000000000000000e+00,
              -7.16673481080267960e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.22659616885114708e-01 2.11177568670983806e-01 0.00000000000000000e+00,
              -7.31306257492115641e-01 2.05523995966406303e-01 0.00000000000000000e+00,
              -7.40950587399924254e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.02396178572906948e+00 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.02396178572906948e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -8.95592429025132919e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -8.59343051095782906e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -8.25089051768048476e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.93828120342737531e-01 1.72932812140018100e-02 0.00000000000000000e+00,
              -7.67223072321196353e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -7.45606470803694021e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -7.28978315790230758e-01 -4.75565233385050190e-02 0.00000000000000000e+00,
              -7.18336296581614242e-01 -7.61569499616618906e-02 0.00000000000000000e+00,
              -7.14678102478652266e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -7.18336296581614242e-01 -1.36683434210668253e-01 0.00000000000000000e+00,
              -7.28978315790230758e-01 -1.65283860833825125e-01 0.00000000000000000e+00,
              -7.45606470803694021e-01 -1.91223782654827867e-01 0.00000000000000000e+00,
              -7.67223072321196353e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.93828120342737531e-01 -2.30466228486601199e-01 0.00000000000000000e+00,
              -8.25089051768048476e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -8.59343051095782906e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -8.95592429025132919e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -9.42483826163099447e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -9.90040349501604355e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -1.03260842633607042e+00 -2.22817277180408113e-01 0.00000000000000000e+00,
              -1.06420192086165066e+00 -2.00535549462367291e-01 0.00000000000000000e+00,
              -1.06819267806488183e+00 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.07118574596730531e+00 -1.92886598156174177e-01 0.00000000000000000e+00,
              -1.07384625076945928e+00 -1.88230714752404465e-01 0.00000000000000000e+00,
              -1.07584162937107486e+00 -1.83242268248365481e-01 0.00000000000000000e+00,
              -1.07717188177215206e+00 -1.77588695543787950e-01 0.00000000000000000e+00,
              -1.07617419247134416e+00 -1.71602559738941174e-01 0.00000000000000000e+00,
              -1.07384625076945928e+00 -1.65948987034363643e-01 0.00000000000000000e+00,
              -1.06919036736568973e+00 -1.59962851229516867e-01 0.00000000000000000e+00,
              -1.06386935776138136e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -1.05788322195653461e+00 -1.52979026123862272e-01 0.00000000000000000e+00,
              -1.05222964925195717e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.04724120274791810e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.04225275624387903e+00 -1.53644152324400818e-01 0.00000000000000000e+00,
              -1.03693174663957088e+00 -1.55972094026285674e-01 0.00000000000000000e+00,
              -1.03227586323580112e+00 -1.58632598828439803e-01 0.00000000000000000e+00,
              -1.02828510603256995e+00 -1.61625666730863204e-01 0.00000000000000000e+00,
              -1.02495947502987739e+00 -1.64618734633286579e-01 0.00000000000000000e+00,
              -1.02163384402718460e+00 -1.67279239435440708e-01 0.00000000000000000e+00,
              -1.01897333922503042e+00 -1.69607181137325591e-01 0.00000000000000000e+00,
              -1.01764308682395344e+00 -1.70604870438133382e-01 0.00000000000000000e+00,
              -9.95028796005643423e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -9.62105049078986085e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -9.26520797350174563e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -8.95592429025132919e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -8.68987381003591630e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -8.44045148483396734e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -8.21763420765355912e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.03139887150277065e-01 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.85846605936275200e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -5.02835407607129969e-01 2.47426946600333819e-01 0.00000000000000000e+00,
              -4.94521330100398337e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -4.83879310891781822e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -4.73569854783434607e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -4.64923214176433675e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -4.59269641471856116e-01 2.40110558394409868e-01 0.00000000000000000e+00,
              -4.57274262870240533e-01 2.30798791586870444e-01 0.00000000000000000e+00,
              -4.57274262870240533e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -4.59269641471856116e-01 -2.40443121494679196e-01 0.00000000000000000e+00,
              -4.64923214176433675e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -4.73569854783434607e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -4.83546747791512521e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -4.93856203899859847e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -5.02170281406591479e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -5.08156417211438227e-01 -2.40443121494679196e-01 0.00000000000000000e+00,
              -5.09819232712784509e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -5.09819232712784509e-01 1.67611802535710008e-01 0.00000000000000000e+00,
              -6.11250978294910707e-01 6.45172414522375837e-02 0.00000000000000000e+00,
              -6.19565055801642339e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -6.29541948809720253e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -6.39851404918067468e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -6.48498045525068401e-01 6.38521152516990376e-02 0.00000000000000000e+00,
              -6.54484181329915149e-01 7.15010665578921234e-02 0.00000000000000000e+00,
              -6.56479559931530732e-01 8.11453964657008475e-02 0.00000000000000000e+00,
              -6.55149307530453751e-01 9.04571632732402708e-02 0.00000000000000000e+00,
              -6.49495734825876303e-01 9.87712407799719583e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 -1.25376288801513219e-01 0.00000000000000000e+00,
              -5.52054746446981603e-02 -9.27851049751251544e-02 0.00000000000000000e+00,
              -6.21892997503528111e-02 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.38290082597767849e-02 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.84617846716246881e-02 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.07085318286703535e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.29367046004744357e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.54309278524939253e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.80914326546480542e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.35556168171689095e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.45865624280036421e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -3.54179701786768053e-01 -1.66281550134633749e-03 0.00000000000000000e+00,
              -3.60165837591614801e-01 6.31869890511604915e-03 0.00000000000000000e+00,
              -3.61828653092961194e-01 1.59630288129247178e-02 0.00000000000000000e+00,
              -3.61828653092961194e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -3.60165837591614801e-01 2.39777995294140678e-01 0.00000000000000000e+00,
              -3.54179701786768053e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -3.45865624280036421e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -3.35556168171689095e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -2.62724849212720990e-02 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.98151440646233112e-03 2.47759509700603009e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.39112869093602132e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 2.29468539185793352e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.98151440646233112e-03 2.11177568670983806e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.05523995966406303e-01 0.00000000000000000e+00,
              -2.62724849212720990e-02 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.80914326546480542e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.44664948617130640e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.10410949289396321e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.91500178640853758e-02 1.72932812140018100e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.09283683250416441e-02 -2.19491646177715771e-02 0.00000000000000000e+00,
              -1.43002133115783803e-02 -4.75565233385050190e-02 0.00000000000000000e+00,
              -3.65819410296186476e-03 -7.61569499616618906e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -1.06420192086165072e-01 0.00000000000000000e+00,
              -3.65819410296186476e-03 -1.36683434210668253e-01 0.00000000000000000e+00,
              -1.43002133115783803e-02 -1.65283860833825125e-01 0.00000000000000000e+00,
              -3.09283683250416441e-02 -1.91223782654827867e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.91500178640853758e-02 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.10410949289396321e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.44664948617130640e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.80914326546480542e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.27805723684447070e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -2.75362247022952089e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -3.17930323857418040e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -3.49523818382998286e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -3.53514575586229562e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -3.56507643488652937e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -3.61163526892422704e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -3.62493779293499685e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -3.61496089992691894e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -3.54512264887037354e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -3.49191255282729096e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -3.43205119477882348e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -3.37551546773304789e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.32563100269265832e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.27574653765226875e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -3.22253644160918618e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -3.17597760757148739e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -3.13607003553917685e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -3.10281372551225010e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -3.06955741548532335e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -3.04295236746378150e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -3.02964984345301169e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -2.80350693526991046e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -2.47426946600333708e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -2.11842694871522297e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.80914326546480542e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.54309278524939253e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.29367046004744357e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.07085318286703535e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.84617846716246881e-02 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.11685034576228226e-02 -1.55639530926016401e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99588575103220251e+02 5.19997228640831054e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            83 82 84 -1 
            82 81 84 -1 
            81 80 84 -1 
            84 80 85 -1 
            85 80 86 -1 
            80 137 86 -1 
            110 109 111 -1 
            109 108 111 -1 
            111 108 112 -1 
            112 108 113 -1 
            134 133 135 -1 
            133 132 135 -1 
            132 131 135 -1 
            131 130 135 -1 
            130 129 135 -1 
            135 129 136 -1 
            136 129 137 -1 
            129 128 137 -1 
            106 105 107 -1 
            107 105 108 -1 
            108 105 113 -1 
            113 105 114 -1 
            114 105 115 -1 
            104 103 105 -1 
            115 105 116 -1 
            122 121 123 -1 
            121 120 123 -1 
            120 119 123 -1 
            119 118 123 -1 
            118 117 123 -1 
            117 116 123 -1 
            123 116 124 -1 
            137 128 86 -1 
            86 128 87 -1 
            128 127 87 -1 
            127 126 87 -1 
            126 125 87 -1 
            125 124 87 -1 
            87 124 88 -1 
            88 124 89 -1 
            89 124 90 -1 
            90 124 91 -1 
            91 124 92 -1 
            92 124 93 -1 
            103 102 105 -1 
            93 124 94 -1 
            124 116 94 -1 
            94 116 95 -1 
            95 116 96 -1 
            96 116 97 -1 
            97 116 98 -1 
            98 116 99 -1 
            99 116 100 -1 
            100 116 101 -1 
            101 116 102 -1 
            116 105 102 -1 
            185 138 186 -1 
            138 167 186 -1 
            153 169 154 -1 
            169 168 154 -1 
            186 167 187 -1 
            187 167 188 -1 
            167 166 188 -1 
            188 166 189 -1 
            189 166 190 -1 
            166 165 190 -1 
            190 165 191 -1 
            165 164 191 -1 
            191 164 192 -1 
            164 163 192 -1 
            192 163 193 -1 
            163 162 193 -1 
            193 162 194 -1 
            194 162 195 -1 
            162 161 195 -1 
            195 161 196 -1 
            161 160 196 -1 
            196 160 197 -1 
            160 159 197 -1 
            197 159 198 -1 
            159 158 198 -1 
            198 158 199 -1 
            154 168 155 -1 
            168 199 155 -1 
            158 157 199 -1 
            157 156 199 -1 
            199 156 155 -1 
            169 153 170 -1 
            185 184 138 -1 
            139 138 184 -1 
            184 183 139 -1 
            139 183 140 -1 
            183 182 140 -1 
            182 181 140 -1 
            153 152 170 -1 
            170 152 171 -1 
            140 181 141 -1 
            181 180 141 -1 
            141 180 142 -1 
            180 179 142 -1 
            142 179 143 -1 
            179 178 143 -1 
            143 178 144 -1 
            178 177 144 -1 
            144 177 145 -1 
            177 176 145 -1 
            145 176 146 -1 
            176 175 146 -1 
            146 175 147 -1 
            175 174 147 -1 
            147 174 148 -1 
            174 173 148 -1 
            148 173 149 -1 
            173 172 149 -1 
            149 172 150 -1 
            172 171 150 -1 
            150 171 151 -1 
            171 152 151 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0013"
              point="
              -9.82391398195411325e-01 -1.25376288801513219e-01 0.00000000000000000e+00,
              -9.85051902997565398e-01 -9.27851049751251544e-02 0.00000000000000000e+00,
              -9.92035728103219938e-01 -6.98382510565458414e-02 0.00000000000000000e+00,
              -1.00367543661264413e+00 -5.32100960430825221e-02 0.00000000000000000e+00,
              -1.01830821302449204e+00 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.03693174663957088e+00 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.05921347435761160e+00 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.08415570687780649e+00 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.11076075489934789e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.26540259652455656e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.27571205263290377e+00 -7.31638820592386829e-03 0.00000000000000000e+00,
              -1.28402613013963540e+00 -1.66281550134633749e-03 0.00000000000000000e+00,
              -1.29001226594448215e+00 6.31869890511604915e-03 0.00000000000000000e+00,
              -1.29167508144582843e+00 1.59630288129247178e-02 0.00000000000000000e+00,
              -1.29167508144582843e+00 2.30466228486601199e-01 0.00000000000000000e+00,
              -1.29001226594448215e+00 2.39777995294140678e-01 0.00000000000000000e+00,
              -1.28402613013963540e+00 2.48092072800872310e-01 0.00000000000000000e+00,
              -1.27571205263290377e+00 2.53413082405180567e-01 0.00000000000000000e+00,
              -1.26540259652455656e+00 2.55408461006796150e-01 0.00000000000000000e+00,
              -9.56118913274139226e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -9.46474583366330613e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -9.37827942759329680e-01 2.47759509700603009e-01 0.00000000000000000e+00,
              -9.31841806954482932e-01 2.39112869093602132e-01 0.00000000000000000e+00,
              -9.29846428352867238e-01 2.29468539185793352e-01 0.00000000000000000e+00,
              -9.31841806954482932e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -9.37827942759329680e-01 2.11177568670983806e-01 0.00000000000000000e+00,
              -9.46474583366330613e-01 2.05523995966406303e-01 0.00000000000000000e+00,
              -9.56118913274139226e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.23913011160328446e+00 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.23913011160328446e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.11076075489934789e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.07451137696999788e+00 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.04025737764226345e+00 3.09283683250417551e-02 0.00000000000000000e+00,
              -1.00899644621695250e+00 1.72932812140018100e-02 0.00000000000000000e+00,
              -9.82391398195411325e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -9.60774796677908993e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -9.44146641664445729e-01 -4.75565233385050190e-02 0.00000000000000000e+00,
              -9.33504622455829214e-01 -7.61569499616618906e-02 0.00000000000000000e+00,
              -9.29846428352867238e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -9.33504622455829214e-01 -1.36683434210668253e-01 0.00000000000000000e+00,
              -9.44146641664445729e-01 -1.65283860833825125e-01 0.00000000000000000e+00,
              -9.60774796677908993e-01 -1.91223782654827867e-01 0.00000000000000000e+00,
              -9.82391398195411325e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -1.00899644621695250e+00 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.04025737764226345e+00 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.07451137696999788e+00 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.11076075489934789e+00 -2.55408461006796150e-01 0.00000000000000000e+00,
              -1.15765215203731442e+00 -2.51750266903834230e-01 0.00000000000000000e+00,
              -1.20520867537581933e+00 -2.40110558394409923e-01 0.00000000000000000e+00,
              -1.24777675221028539e+00 -2.22817277180408113e-01 0.00000000000000000e+00,
              -1.27937024673586563e+00 -2.00535549462367291e-01 0.00000000000000000e+00,
              -1.28336100393909680e+00 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.28635407184152029e+00 -1.92886598156174177e-01 0.00000000000000000e+00,
              -1.28901457664367425e+00 -1.88230714752404465e-01 0.00000000000000000e+00,
              -1.29100995524528983e+00 -1.83242268248365481e-01 0.00000000000000000e+00,
              -1.29234020764636703e+00 -1.77588695543787950e-01 0.00000000000000000e+00,
              -1.29134251834555913e+00 -1.71602559738941174e-01 0.00000000000000000e+00,
              -1.28901457664367425e+00 -1.65948987034363643e-01 0.00000000000000000e+00,
              -1.28435869323990470e+00 -1.59962851229516867e-01 0.00000000000000000e+00,
              -1.27903768363559633e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -1.27305154783074959e+00 -1.52979026123862272e-01 0.00000000000000000e+00,
              -1.26739797512617214e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.26240952862213307e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.25742108211809400e+00 -1.53644152324400818e-01 0.00000000000000000e+00,
              -1.25210007251378586e+00 -1.55972094026285674e-01 0.00000000000000000e+00,
              -1.24744418911001609e+00 -1.58632598828439803e-01 0.00000000000000000e+00,
              -1.24345343190678492e+00 -1.61625666730863204e-01 0.00000000000000000e+00,
              -1.24012780090409236e+00 -1.64618734633286579e-01 0.00000000000000000e+00,
              -1.23680216990139957e+00 -1.67279239435440708e-01 0.00000000000000000e+00,
              -1.23414166509924539e+00 -1.69607181137325591e-01 0.00000000000000000e+00,
              -1.23281141269816841e+00 -1.70604870438133382e-01 0.00000000000000000e+00,
              -1.21019712187985840e+00 -1.82577142047826935e-01 0.00000000000000000e+00,
              -1.17727337495320095e+00 -1.93551724356712695e-01 0.00000000000000000e+00,
              -1.14168912322438953e+00 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.11076075489934789e+00 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.08415570687780649e+00 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.05921347435761160e+00 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.03693174663957088e+00 -1.86567899251058128e-01 0.00000000000000000e+00,
              -1.01830821302449204e+00 -1.74263064541095303e-01 0.00000000000000000e+00,
              -1.00101493181049017e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -8.47703342586358599e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -8.58345361794975115e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -8.66659439301706858e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -8.72313012006284305e-01 -2.40775684594948441e-01 0.00000000000000000e+00,
              -8.73975827507630587e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -8.72645575106553606e-01 -2.22152150979869567e-01 0.00000000000000000e+00,
              -8.67657128602514538e-01 -2.14835762773945727e-01 0.00000000000000000e+00,
              -5.82650551671753836e-01 7.98151440646237553e-02 0.00000000000000000e+00,
              -5.76664415866907087e-01 8.84617846716246881e-02 0.00000000000000000e+00,
              -5.70678280062060228e-01 1.00766619381587541e-01 0.00000000000000000e+00,
              -5.66354959758559762e-01 1.13071454091550394e-01 0.00000000000000000e+00,
              -5.64692144257213369e-01 1.22383220899089817e-01 0.00000000000000000e+00,
              -5.67020085959098363e-01 1.37015997310937498e-01 0.00000000000000000e+00,
              -5.74003911064752792e-01 1.51648773722785235e-01 0.00000000000000000e+00,
              -5.84978493373638608e-01 1.65283860833825069e-01 0.00000000000000000e+00,
              -5.99278706685217100e-01 1.77588695543787922e-01 0.00000000000000000e+00,
              -6.17569677200026756e-01 1.88230714752404382e-01 0.00000000000000000e+00,
              -6.39851404918067468e-01 1.96544792259136070e-01 0.00000000000000000e+00,
              -6.65458763638800965e-01 2.01865801863444327e-01 0.00000000000000000e+00,
              -6.92728937860880745e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.30308568191307739e-01 2.00535549462367291e-01 0.00000000000000000e+00,
              -7.70881266424158218e-01 1.91556345755097113e-01 0.00000000000000000e+00,
              -8.07463207453777532e-01 1.78586384844595714e-01 0.00000000000000000e+00,
              -8.31075187572895335e-01 1.64951297733555879e-01 0.00000000000000000e+00,
              -8.40054391280165458e-01 1.59962851229516811e-01 0.00000000000000000e+00,
              -8.50363847388512673e-01 1.59297725028978321e-01 0.00000000000000000e+00,
              -8.60008177296321508e-01 1.61958229831132450e-01 0.00000000000000000e+00,
              -8.67989691702783839e-01 1.68276928736248499e-01 0.00000000000000000e+00,
              -8.72978138206822907e-01 1.77256132443518677e-01 0.00000000000000000e+00,
              -8.73643264407361286e-01 1.87233025451596646e-01 0.00000000000000000e+00,
              -8.70982759605207213e-01 1.96877355359405370e-01 0.00000000000000000e+00,
              -8.64331497599821974e-01 2.04858869765867757e-01 0.00000000000000000e+00,
              -8.31407750673164636e-01 2.25145218882292941e-01 0.00000000000000000e+00,
              -7.88174547638159972e-01 2.41440810795486960e-01 0.00000000000000000e+00,
              -7.40285461199385653e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -6.92728937860880745e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -6.57477249232338523e-01 2.52747956204642077e-01 0.00000000000000000e+00,
              -6.23555813004873505e-01 2.45764131098987426e-01 0.00000000000000000e+00,
              -5.92960007780101050e-01 2.34124422589563175e-01 0.00000000000000000e+00,
              -5.66022396658290572e-01 2.19159083077446137e-01 0.00000000000000000e+00,
              -5.44073232040518939e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -5.26779950826517074e-01 1.77588695543787922e-01 0.00000000000000000e+00,
              -5.15805368517631369e-01 1.51981336823054480e-01 0.00000000000000000e+00,
              -5.12147174414669393e-01 1.24711162600974701e-01 0.00000000000000000e+00,
              -5.14475116116554387e-01 1.05089939685088007e-01 0.00000000000000000e+00,
              -5.20793815021670325e-01 8.51361536689320131e-02 0.00000000000000000e+00,
              -5.30438144929479161e-01 6.58474938533146203e-02 0.00000000000000000e+00,
              -5.42742979639441847e-01 4.98844650403898471e-02 0.00000000000000000e+00,
              -7.90502489340044856e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -5.38419659335941381e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -5.28442766327863467e-01 -2.05856559066675548e-01 0.00000000000000000e+00,
              -5.19796125720862645e-01 -2.11510131771253052e-01 0.00000000000000000e+00,
              -5.14142553016285087e-01 -2.20156772378253984e-01 0.00000000000000000e+00,
              -5.12147174414669393e-01 -2.29801102286062681e-01 0.00000000000000000e+00,
              -5.14142553016285087e-01 -2.39445432193871377e-01 0.00000000000000000e+00,
              -5.20128688821131835e-01 -2.47759509700603037e-01 0.00000000000000000e+00,
              -5.28775329428132768e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -5.38419659335941381e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -1.75593316942172395e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -2.46761820399795218e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -2.90660149635338483e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -3.18928013158226165e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -3.42207430177074667e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -3.60498400691884324e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -3.73135798502116312e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -3.80784749808309564e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -3.83112691510194336e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -3.80784749808309564e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -3.73135798502116312e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -3.60498400691884324e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -3.42207430177074667e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -3.18928013158226165e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -2.90660149635338483e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -2.57403839608411733e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -2.17828830676369156e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.78586384844595658e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -1.44997511717400052e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -1.17062211294781671e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -9.37827942759330568e-02 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.54918237611232890e-02 -1.29034482904475140e-01 0.00000000000000000e+00,
              -6.28544259508914127e-02 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.52054746446981603e-02 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.25449698425441980e-02 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.52054746446981603e-02 5.05495912409283932e-02 0.00000000000000000e+00,
              -6.28544259508914127e-02 9.34502311756637005e-02 0.00000000000000000e+00,
              -7.54918237611232890e-02 1.28701919804205811e-01 0.00000000000000000e+00,
              -9.37827942759330568e-02 1.56637220226824192e-01 0.00000000000000000e+00,
              -1.24711162600974701e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -1.62955919131940519e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.56073587207334752e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -3.14604692854725476e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.52516886285421882e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -3.82780128409925036e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -4.06059545428773760e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -4.22355137341967724e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.32332030350045748e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.35657661352738312e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.32332030350045748e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -4.22355137341967724e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -4.06059545428773760e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -3.82780128409925036e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.52516886285421882e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -3.14604692854725476e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -2.69708674318374753e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -2.17828830676369156e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.76258443142710997e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -1.38346249712014702e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -1.05089939685088174e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -7.68220761622004922e-02 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.35426591433518784e-02 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.39214362274651293e-02 1.58965161928709020e-01 0.00000000000000000e+00,
              -1.89560967153481474e-02 1.25376288801513192e-01 0.00000000000000000e+00,
              -8.31407750673185397e-03 8.77966584710861420e-02 0.00000000000000000e+00,
              -2.32794170188488359e-03 4.58937078371586815e-02 0.00000000000000000e+00,
              -2.22044604925031308e-16 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.32563100269278600e-03 -5.72008532463137154e-02 0.00000000000000000e+00,
              -1.33025240107706999e-02 -1.07750444487242136e-01 0.00000000000000000e+00,
              -2.99306790242339638e-02 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.32100960430827996e-02 -1.87565588551865947e-01 0.00000000000000000e+00,
              -9.41153573762023576e-02 -2.21154461679061776e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99792901872025652e+02 4.99792901872025652e+02 7.99972286408310964e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            40 0 41 -1 
            52 51 18 -1 
            41 0 42 -1 
            51 50 18 -1 
            42 0 43 -1 
            50 49 18 -1 
            43 0 44 -1 
            48 47 49 -1 
            47 46 49 -1 
            46 45 49 -1 
            45 44 49 -1 
            18 49 19 -1 
            19 49 20 -1 
            20 49 21 -1 
            21 49 22 -1 
            22 49 23 -1 
            23 49 24 -1 
            24 49 25 -1 
            49 44 25 -1 
            0 35 44 -1 
            35 34 44 -1 
            34 33 44 -1 
            33 32 44 -1 
            32 31 44 -1 
            31 30 44 -1 
            30 29 44 -1 
            29 28 44 -1 
            28 27 44 -1 
            27 26 44 -1 
            44 26 25 -1 
            85 84 65 -1 
            65 84 66 -1 
            1 0 40 -1 
            40 39 1 -1 
            39 38 1 -1 
            66 84 67 -1 
            12 11 72 -1 
            73 72 11 -1 
            38 37 1 -1 
            11 10 73 -1 
            10 9 73 -1 
            73 9 74 -1 
            9 8 74 -1 
            8 7 74 -1 
            74 7 75 -1 
            37 36 1 -1 
            7 6 75 -1 
            75 6 76 -1 
            6 5 76 -1 
            76 5 77 -1 
            5 4 77 -1 
            84 83 67 -1 
            67 83 68 -1 
            71 70 36 -1 
            70 69 36 -1 
            69 68 36 -1 
            77 4 78 -1 
            83 82 68 -1 
            82 81 68 -1 
            81 80 68 -1 
            80 79 68 -1 
            68 79 36 -1 
            79 78 36 -1 
            78 4 36 -1 
            4 3 36 -1 
            3 2 36 -1 
            2 1 36 -1 
            65 64 85 -1 
            72 97 12 -1 
            52 18 53 -1 
            85 64 86 -1 
            64 63 86 -1 
            53 18 54 -1 
            12 97 13 -1 
            86 63 87 -1 
            87 63 88 -1 
            63 62 88 -1 
            62 61 88 -1 
            54 18 55 -1 
            97 96 13 -1 
            13 96 14 -1 
            96 95 14 -1 
            14 95 15 -1 
            88 61 89 -1 
            61 60 89 -1 
            57 56 58 -1 
            58 56 59 -1 
            59 56 60 -1 
            18 17 55 -1 
            55 17 56 -1 
            95 94 15 -1 
            17 16 56 -1 
            16 15 56 -1 
            89 60 90 -1 
            90 60 91 -1 
            91 60 92 -1 
            92 60 93 -1 
            60 56 93 -1 
            93 56 94 -1 
            56 15 94 -1 
            145 98 146 -1 
            98 127 146 -1 
            113 129 114 -1 
            129 128 114 -1 
            146 127 147 -1 
            147 127 148 -1 
            127 126 148 -1 
            148 126 149 -1 
            149 126 150 -1 
            126 125 150 -1 
            150 125 151 -1 
            125 124 151 -1 
            151 124 152 -1 
            124 123 152 -1 
            152 123 153 -1 
            123 122 153 -1 
            153 122 154 -1 
            154 122 155 -1 
            122 121 155 -1 
            155 121 156 -1 
            121 120 156 -1 
            156 120 157 -1 
            120 119 157 -1 
            157 119 158 -1 
            119 118 158 -1 
            158 118 159 -1 
            114 128 115 -1 
            128 159 115 -1 
            118 117 159 -1 
            117 116 159 -1 
            159 116 115 -1 
            129 113 130 -1 
            145 144 98 -1 
            99 98 144 -1 
            144 143 99 -1 
            99 143 100 -1 
            143 142 100 -1 
            142 141 100 -1 
            113 112 130 -1 
            130 112 131 -1 
            100 141 101 -1 
            141 140 101 -1 
            101 140 102 -1 
            140 139 102 -1 
            102 139 103 -1 
            139 138 103 -1 
            103 138 104 -1 
            138 137 104 -1 
            104 137 105 -1 
            137 136 105 -1 
            105 136 106 -1 
            136 135 106 -1 
            106 135 107 -1 
            135 134 107 -1 
            107 134 108 -1 
            134 133 108 -1 
            108 133 109 -1 
            133 132 109 -1 
            109 132 110 -1 
            132 131 110 -1 
            110 131 111 -1 
            131 112 111 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0014"
              point="
              -8.21430857665086611e-01 1.39676502113091627e-01 0.00000000000000000e+00,
              -8.20765731464548121e-01 1.11408638590204057e-01 0.00000000000000000e+00,
              -8.17772663562124635e-01 9.37827942759329458e-02 0.00000000000000000e+00,
              -8.12784217058085678e-01 8.18105226662393936e-02 0.00000000000000000e+00,
              -8.04802702651623347e-01 7.15010665578921234e-02 0.00000000000000000e+00,
              -7.94493246543276133e-01 6.21892997503527001e-02 0.00000000000000000e+00,
              -7.81190722532505433e-01 5.52054746446981048e-02 0.00000000000000000e+00,
              -7.64562567519042169e-01 4.98844650403898471e-02 0.00000000000000000e+00,
              -7.44608781502886230e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -7.20664238283499126e-01 4.32332030350045526e-02 0.00000000000000000e+00,
              -6.92728937860880745e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -6.59140064733684916e-01 4.35657661352737979e-02 0.00000000000000000e+00,
              -6.31204764311066535e-01 4.75565233385050190e-02 0.00000000000000000e+00,
              -6.08923036593025713e-01 5.38752222436210682e-02 0.00000000000000000e+00,
              -5.91629755379023958e-01 6.28544259508912462e-02 0.00000000000000000e+00,
              -5.79324920669061161e-01 7.41615713600462523e-02 0.00000000000000000e+00,
              -5.71010843162329418e-01 8.74640953708168967e-02 0.00000000000000000e+00,
              -5.66354959758559762e-01 1.03427124183741670e-01 0.00000000000000000e+00,
              -5.64692144257213369e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -5.65689833558021160e-01 1.38346249712014591e-01 0.00000000000000000e+00,
              -5.68682901460444645e-01 1.51981336823054480e-01 0.00000000000000000e+00,
              -5.73671347964483491e-01 1.63953608432748033e-01 0.00000000000000000e+00,
              -5.81320299270676744e-01 1.74595627641364548e-01 0.00000000000000000e+00,
              -5.91962318479293259e-01 1.83574831348634726e-01 0.00000000000000000e+00,
              -6.05264842490063848e-01 1.90891219554558511e-01 0.00000000000000000e+00,
              -6.21892997503527112e-01 1.96212229158866769e-01 0.00000000000000000e+00,
              -6.41846783519683051e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -6.65458763638800965e-01 2.02863491164252174e-01 0.00000000000000000e+00,
              -6.93061500961150045e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.20996801383768315e-01 2.02863491164252174e-01 0.00000000000000000e+00,
              -7.44608781502886230e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.64562567519042169e-01 1.96212229158866769e-01 0.00000000000000000e+00,
              -7.81190722532505433e-01 1.90891219554558511e-01 0.00000000000000000e+00,
              -7.94493246543276133e-01 1.83574831348634726e-01 0.00000000000000000e+00,
              -8.04802702651623347e-01 1.74595627641364548e-01 0.00000000000000000e+00,
              -8.13449343258624280e-01 1.60627977430055358e-01 0.00000000000000000e+00,
              -8.12119090857547188e-01 1.56304657126554725e-02 0.00000000000000000e+00,
              -8.39056701979357666e-01 3.49191255282729207e-02 0.00000000000000000e+00,
              -8.58345361794975115e-01 5.95287949481985712e-02 0.00000000000000000e+00,
              -8.70317633404668722e-01 8.91269108721632342e-02 0.00000000000000000e+00,
              -8.73975827507630587e-01 1.24046036400436155e-01 0.00000000000000000e+00,
              -8.68654817903322329e-01 1.63621045332478787e-01 0.00000000000000000e+00,
              -8.52359225990128366e-01 1.96877355359405370e-01 0.00000000000000000e+00,
              -8.26086741068856378e-01 2.22484714080138812e-01 0.00000000000000000e+00,
              -7.90169926239775666e-01 2.40775684594948414e-01 0.00000000000000000e+00,
              -7.45939033903963322e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -6.93061500961150045e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -6.40183968018336769e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -5.95953075682524425e-01 2.40775684594948414e-01 0.00000000000000000e+00,
              -5.60368823953713013e-01 2.22484714080138812e-01 0.00000000000000000e+00,
              -5.34096339032440914e-01 1.96544792259136070e-01 0.00000000000000000e+00,
              -5.17800747119246951e-01 1.63288482232209486e-01 0.00000000000000000e+00,
              -5.12147174414669393e-01 1.23380910199897609e-01 0.00000000000000000e+00,
              -5.16137931617900669e-01 8.84617846716246881e-02 0.00000000000000000e+00,
              -5.28110203227594166e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -5.47731426143480804e-01 3.35888731271958840e-02 0.00000000000000000e+00,
              -5.74003911064752792e-01 1.46327764118476811e-02 0.00000000000000000e+00,
              -5.47398863043211614e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -5.27777640127324865e-01 -3.49191255282729207e-02 0.00000000000000000e+00,
              -5.16137931617900669e-01 -6.85079986554687770e-02 0.00000000000000000e+00,
              -5.12147174414669393e-01 -1.07085318286703590e-01 0.00000000000000000e+00,
              -5.24119446024363000e-01 -1.70937433538402628e-01 0.00000000000000000e+00,
              -5.59038571552635921e-01 -2.17496267576099855e-01 0.00000000000000000e+00,
              -6.15906861698680363e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -6.93061500961150045e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.70216140223619616e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -8.27084430369664170e-01 -2.17163704475830582e-01 0.00000000000000000e+00,
              -8.62336118998206280e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -8.73975827507630587e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -8.70317633404668722e-01 -6.75103093546609578e-02 0.00000000000000000e+00,
              -8.58345361794975115e-01 -3.39214362274651293e-02 0.00000000000000000e+00,
              -8.39056701979357666e-01 -5.98613580484680385e-03 0.00000000000000000e+00,
              -6.56812123031800033e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -7.17338607280806451e-01 -1.06420192086164878e-02 0.00000000000000000e+00,
              -7.53587985210156353e-01 -1.46327764118476811e-02 0.00000000000000000e+00,
              -7.75537149827927874e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -7.93162994142199040e-01 -3.25911838263880371e-02 0.00000000000000000e+00,
              -8.06132955052700328e-01 -4.62262709374279546e-02 0.00000000000000000e+00,
              -8.14779595659701261e-01 -6.25218628506219731e-02 0.00000000000000000e+00,
              -8.19768042163740329e-01 -8.24756488667779397e-02 0.00000000000000000e+00,
              -8.21430857665086611e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -8.18437789762663237e-01 -1.37681123511476045e-01 0.00000000000000000e+00,
              -8.09458586055393003e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -7.93162994142199040e-01 -1.80581763446211352e-01 0.00000000000000000e+00,
              -7.68885887822542635e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -7.35962140895885297e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.93061500961150045e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -6.50160861026414683e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.17237114099757456e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -5.92960007780100939e-01 -1.80581763446211352e-01 0.00000000000000000e+00,
              -5.76996978967176277e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -5.68017775259906044e-01 -1.37681123511476045e-01 0.00000000000000000e+00,
              -5.64692144257213369e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -5.66354959758559762e-01 -8.24756488667779397e-02 0.00000000000000000e+00,
              -5.71343406262598719e-01 -6.25218628506219731e-02 0.00000000000000000e+00,
              -5.79990046869599651e-01 -4.62262709374279546e-02 0.00000000000000000e+00,
              -5.92960007780100939e-01 -3.25911838263880371e-02 0.00000000000000000e+00,
              -6.16571987899218854e-01 -2.06189122166945127e-02 0.00000000000000000e+00,
              -1.75593316942172395e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -2.46761820399795329e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -2.90660149635338372e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -3.18928013158225943e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -3.42207430177074556e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -3.60498400691884213e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -3.73135798502116312e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -3.80784749808309453e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -3.83112691510194225e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -3.80784749808309453e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -3.73135798502116312e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -3.60498400691884213e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -3.42207430177074556e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -3.18928013158225943e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -2.90660149635338372e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -2.57403839608411844e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -2.17828830676369156e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.78586384844595880e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -1.44997511717399941e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -1.17062211294781671e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -9.37827942759329458e-02 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.54918237611235110e-02 -1.29034482904475140e-01 0.00000000000000000e+00,
              -6.28544259508913017e-02 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.52054746446982714e-02 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.25449698425440870e-02 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.52054746446982714e-02 5.05495912409283932e-02 0.00000000000000000e+00,
              -6.28544259508913017e-02 9.34502311756637005e-02 0.00000000000000000e+00,
              -7.54918237611235110e-02 1.28701919804205811e-01 0.00000000000000000e+00,
              -9.37827942759329458e-02 1.56637220226824192e-01 0.00000000000000000e+00,
              -1.24711162600974812e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -1.62955919131940297e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.56073587207334752e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -3.14604692854725587e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.52516886285421882e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -3.82780128409925036e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -4.06059545428773705e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -4.22355137341967668e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.32332030350045582e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.35657661352738312e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.32332030350045582e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -4.22355137341967668e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -4.06059545428773705e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -3.82780128409925036e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.52516886285421882e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -3.14604692854725587e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -2.69708674318374642e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -2.17828830676369156e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.76258443142710997e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -1.38346249712014702e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -1.05089939685088063e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -7.68220761622004922e-02 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.35426591433518784e-02 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.39214362274652403e-02 1.58965161928709020e-01 0.00000000000000000e+00,
              -1.89560967153482585e-02 1.25376288801513192e-01 0.00000000000000000e+00,
              -8.31407750673174295e-03 8.77966584710861420e-02 0.00000000000000000e+00,
              -2.32794170188499461e-03 4.58937078371586815e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.32563100269278600e-03 -5.72008532463137154e-02 0.00000000000000000e+00,
              -1.33025240107708109e-02 -1.07750444487242136e-01 0.00000000000000000e+00,
              -2.99306790242340748e-02 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.32100960430825776e-02 -1.87565588551865947e-01 0.00000000000000000e+00,
              -9.41153573762022466e-02 -2.21154461679061776e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99792901872025652e+02 4.99792901872025652e+02 8.49972286408310964e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            40 0 41 -1 
            52 51 18 -1 
            41 0 42 -1 
            51 50 18 -1 
            42 0 43 -1 
            50 49 18 -1 
            43 0 44 -1 
            48 47 49 -1 
            47 46 49 -1 
            46 45 49 -1 
            45 44 49 -1 
            18 49 19 -1 
            19 49 20 -1 
            20 49 21 -1 
            21 49 22 -1 
            22 49 23 -1 
            23 49 24 -1 
            24 49 25 -1 
            49 44 25 -1 
            0 35 44 -1 
            35 34 44 -1 
            34 33 44 -1 
            33 32 44 -1 
            32 31 44 -1 
            31 30 44 -1 
            30 29 44 -1 
            29 28 44 -1 
            28 27 44 -1 
            27 26 44 -1 
            44 26 25 -1 
            85 84 65 -1 
            65 84 66 -1 
            1 0 40 -1 
            40 39 1 -1 
            39 38 1 -1 
            66 84 67 -1 
            12 11 72 -1 
            73 72 11 -1 
            38 37 1 -1 
            11 10 73 -1 
            10 9 73 -1 
            73 9 74 -1 
            9 8 74 -1 
            8 7 74 -1 
            74 7 75 -1 
            37 36 1 -1 
            7 6 75 -1 
            75 6 76 -1 
            6 5 76 -1 
            76 5 77 -1 
            5 4 77 -1 
            84 83 67 -1 
            67 83 68 -1 
            71 70 36 -1 
            70 69 36 -1 
            69 68 36 -1 
            77 4 78 -1 
            83 82 68 -1 
            82 81 68 -1 
            81 80 68 -1 
            80 79 68 -1 
            68 79 36 -1 
            79 78 36 -1 
            78 4 36 -1 
            4 3 36 -1 
            3 2 36 -1 
            2 1 36 -1 
            65 64 85 -1 
            72 97 12 -1 
            52 18 53 -1 
            85 64 86 -1 
            64 63 86 -1 
            53 18 54 -1 
            12 97 13 -1 
            86 63 87 -1 
            87 63 88 -1 
            63 62 88 -1 
            62 61 88 -1 
            54 18 55 -1 
            97 96 13 -1 
            13 96 14 -1 
            96 95 14 -1 
            14 95 15 -1 
            88 61 89 -1 
            61 60 89 -1 
            57 56 58 -1 
            58 56 59 -1 
            59 56 60 -1 
            18 17 55 -1 
            55 17 56 -1 
            95 94 15 -1 
            17 16 56 -1 
            16 15 56 -1 
            89 60 90 -1 
            90 60 91 -1 
            91 60 92 -1 
            92 60 93 -1 
            60 56 93 -1 
            93 56 94 -1 
            56 15 94 -1 
            113 112 114 -1 
            114 112 115 -1 
            115 112 116 -1 
            110 109 111 -1 
            109 108 111 -1 
            108 107 111 -1 
            107 106 111 -1 
            122 121 123 -1 
            121 120 123 -1 
            120 119 123 -1 
            123 119 124 -1 
            119 118 124 -1 
            124 118 125 -1 
            118 117 125 -1 
            125 117 126 -1 
            117 116 126 -1 
            116 112 126 -1 
            112 111 126 -1 
            126 111 127 -1 
            111 106 127 -1 
            127 106 128 -1 
            128 106 129 -1 
            106 105 129 -1 
            129 105 130 -1 
            105 104 130 -1 
            130 104 131 -1 
            104 103 131 -1 
            131 103 132 -1 
            132 103 133 -1 
            133 103 134 -1 
            134 103 135 -1 
            153 152 154 -1 
            154 152 155 -1 
            155 152 156 -1 
            156 152 157 -1 
            152 151 157 -1 
            157 151 158 -1 
            158 151 159 -1 
            159 151 160 -1 
            160 151 161 -1 
            161 151 162 -1 
            162 151 163 -1 
            151 150 163 -1 
            150 149 163 -1 
            149 148 163 -1 
            148 147 163 -1 
            147 146 163 -1 
            146 145 163 -1 
            137 136 138 -1 
            136 135 138 -1 
            138 135 139 -1 
            139 135 140 -1 
            140 135 141 -1 
            141 135 142 -1 
            142 135 143 -1 
            144 143 145 -1 
            103 102 135 -1 
            102 101 135 -1 
            101 100 135 -1 
            100 99 135 -1 
            99 98 135 -1 
            98 177 135 -1 
            164 163 165 -1 
            135 177 143 -1 
            177 176 143 -1 
            176 175 143 -1 
            143 175 145 -1 
            175 174 145 -1 
            174 173 145 -1 
            173 172 145 -1 
            172 171 145 -1 
            171 170 145 -1 
            170 169 145 -1 
            169 168 145 -1 
            165 163 166 -1 
            163 145 166 -1 
            166 145 167 -1 
            168 167 145 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0015"
              point="
              -7.68885887822542524e-01 1.39676502113091627e-01 0.00000000000000000e+00,
              -7.68220761622004034e-01 1.11408638590204057e-01 0.00000000000000000e+00,
              -7.65227693719580548e-01 9.37827942759329458e-02 0.00000000000000000e+00,
              -7.60239247215541591e-01 8.18105226662393936e-02 0.00000000000000000e+00,
              -7.52257732809079260e-01 7.15010665578921234e-02 0.00000000000000000e+00,
              -7.41948276700731935e-01 6.21892997503527001e-02 0.00000000000000000e+00,
              -7.28645752689961346e-01 5.52054746446981048e-02 0.00000000000000000e+00,
              -7.12017597676498082e-01 4.98844650403898471e-02 0.00000000000000000e+00,
              -6.92063811660342143e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -6.68119268440954928e-01 4.32332030350045526e-02 0.00000000000000000e+00,
              -6.40183968018336658e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -6.06595094891140829e-01 4.35657661352737979e-02 0.00000000000000000e+00,
              -5.78659794468522448e-01 4.75565233385050190e-02 0.00000000000000000e+00,
              -5.56378066750481626e-01 5.38752222436210682e-02 0.00000000000000000e+00,
              -5.39084785536479871e-01 6.28544259508912462e-02 0.00000000000000000e+00,
              -5.26779950826516963e-01 7.41615713600462523e-02 0.00000000000000000e+00,
              -5.18465873319785331e-01 8.74640953708168967e-02 0.00000000000000000e+00,
              -5.13809989916015564e-01 1.03427124183741670e-01 0.00000000000000000e+00,
              -5.12147174414669282e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -5.13144863715477184e-01 1.38346249712014591e-01 0.00000000000000000e+00,
              -5.16137931617900447e-01 1.51981336823054480e-01 0.00000000000000000e+00,
              -5.21126378121939515e-01 1.63953608432748033e-01 0.00000000000000000e+00,
              -5.28775329428132546e-01 1.74595627641364548e-01 0.00000000000000000e+00,
              -5.39417348636749061e-01 1.83574831348634726e-01 0.00000000000000000e+00,
              -5.52719872647519761e-01 1.90891219554558511e-01 0.00000000000000000e+00,
              -5.69348027660983025e-01 1.96212229158866769e-01 0.00000000000000000e+00,
              -5.89301813677138964e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -6.12913793796256878e-01 2.02863491164252174e-01 0.00000000000000000e+00,
              -6.40516531118605847e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -6.68451831541224228e-01 2.02863491164252174e-01 0.00000000000000000e+00,
              -6.92063811660342143e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.12017597676498082e-01 1.96212229158866769e-01 0.00000000000000000e+00,
              -7.28645752689961346e-01 1.90891219554558511e-01 0.00000000000000000e+00,
              -7.41948276700731935e-01 1.83574831348634726e-01 0.00000000000000000e+00,
              -7.52257732809079260e-01 1.74595627641364548e-01 0.00000000000000000e+00,
              -7.60904373416080082e-01 1.60627977430055358e-01 0.00000000000000000e+00,
              -7.59574121015003101e-01 1.56304657126554725e-02 0.00000000000000000e+00,
              -7.86511732136813579e-01 3.49191255282729207e-02 0.00000000000000000e+00,
              -8.05800391952431028e-01 5.95287949481985712e-02 0.00000000000000000e+00,
              -8.17772663562124635e-01 8.91269108721632342e-02 0.00000000000000000e+00,
              -8.21430857665086500e-01 1.24046036400436155e-01 0.00000000000000000e+00,
              -8.16109848060778242e-01 1.63621045332478787e-01 0.00000000000000000e+00,
              -7.99814256147584279e-01 1.96877355359405370e-01 0.00000000000000000e+00,
              -7.73541771226312291e-01 2.22484714080138812e-01 0.00000000000000000e+00,
              -7.37624956397231468e-01 2.40775684594948414e-01 0.00000000000000000e+00,
              -6.93394064061419124e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -6.40516531118605847e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -5.87638998175792571e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -5.43408105839980227e-01 2.40775684594948414e-01 0.00000000000000000e+00,
              -5.07823854111168815e-01 2.22484714080138812e-01 0.00000000000000000e+00,
              -4.81551369189896883e-01 1.96544792259136070e-01 0.00000000000000000e+00,
              -4.65255777276702809e-01 1.63288482232209486e-01 0.00000000000000000e+00,
              -4.59602204572125306e-01 1.23380910199897609e-01 0.00000000000000000e+00,
              -4.63592961775356527e-01 8.84617846716246881e-02 0.00000000000000000e+00,
              -4.75565233385050079e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -4.95186456300936717e-01 3.35888731271958840e-02 0.00000000000000000e+00,
              -5.21458941222208816e-01 1.46327764118476811e-02 0.00000000000000000e+00,
              -4.94853893200667527e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -4.75232670284780834e-01 -3.49191255282729207e-02 0.00000000000000000e+00,
              -4.63592961775356527e-01 -6.85079986554687770e-02 0.00000000000000000e+00,
              -4.59602204572125306e-01 -1.07085318286703590e-01 0.00000000000000000e+00,
              -4.71574476181818913e-01 -1.70937433538402628e-01 0.00000000000000000e+00,
              -5.06493601710091834e-01 -2.17496267576099855e-01 0.00000000000000000e+00,
              -5.63361891856136277e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -6.40516531118605847e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.17671170381075640e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -7.74539460527120083e-01 -2.17163704475830582e-01 0.00000000000000000e+00,
              -8.09791149155662193e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -8.21430857665086500e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -8.17772663562124635e-01 -6.75103093546609578e-02 0.00000000000000000e+00,
              -8.05800391952431028e-01 -3.39214362274651293e-02 0.00000000000000000e+00,
              -7.86511732136813579e-01 -5.98613580484680385e-03 0.00000000000000000e+00,
              -6.04267153189255946e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -6.64793637438262364e-01 -1.06420192086164878e-02 0.00000000000000000e+00,
              -7.01043015367612266e-01 -1.46327764118476811e-02 0.00000000000000000e+00,
              -7.22992179985383787e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -7.40618024299654953e-01 -3.25911838263880371e-02 0.00000000000000000e+00,
              -7.53587985210156353e-01 -4.62262709374279546e-02 0.00000000000000000e+00,
              -7.62234625817157174e-01 -6.25218628506219731e-02 0.00000000000000000e+00,
              -7.67223072321196131e-01 -8.24756488667779397e-02 0.00000000000000000e+00,
              -7.68885887822542524e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -7.65892819920119150e-01 -1.37681123511476045e-01 0.00000000000000000e+00,
              -7.56913616212848916e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -7.40618024299654953e-01 -1.80581763446211352e-01 0.00000000000000000e+00,
              -7.16340917979998548e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -6.83417171053341210e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.40516531118605847e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -5.97615891183870707e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -5.64692144257213258e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -5.40415037937556963e-01 -1.80581763446211352e-01 0.00000000000000000e+00,
              -5.24452009124632079e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -5.15472805417361957e-01 -1.37681123511476045e-01 0.00000000000000000e+00,
              -5.12147174414669282e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -5.13809989916015564e-01 -8.24756488667779397e-02 0.00000000000000000e+00,
              -5.18798436420054632e-01 -6.25218628506219731e-02 0.00000000000000000e+00,
              -5.27445077027055564e-01 -4.62262709374279546e-02 0.00000000000000000e+00,
              -5.40415037937556963e-01 -3.25911838263880371e-02 0.00000000000000000e+00,
              -5.64027018056674767e-01 -2.06189122166945127e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 -1.25376288801513219e-01 0.00000000000000000e+00,
              -5.52054746446981603e-02 -9.27851049751251544e-02 0.00000000000000000e+00,
              -6.21892997503527001e-02 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.38290082597770070e-02 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.84617846716246881e-02 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.07085318286703646e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.29367046004744468e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.54309278524939364e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.80914326546480653e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.35556168171689206e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.45865624280036477e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -3.54179701786768053e-01 -1.66281550134633749e-03 0.00000000000000000e+00,
              -3.60165837591614857e-01 6.31869890511604915e-03 0.00000000000000000e+00,
              -3.61828653092961194e-01 1.59630288129247178e-02 0.00000000000000000e+00,
              -3.61828653092961194e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -3.60165837591614857e-01 2.39777995294140678e-01 0.00000000000000000e+00,
              -3.54179701786768053e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -3.45865624280036477e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -3.35556168171689206e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -2.62724849212719880e-02 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.98151440646244215e-03 2.47759509700603009e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.39112869093602132e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 2.29468539185793352e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.98151440646244215e-03 2.11177568670983806e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.05523995966406303e-01 0.00000000000000000e+00,
              -2.62724849212719880e-02 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.80914326546480653e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.44664948617130640e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.10410949289396210e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.91500178640852647e-02 1.72932812140018100e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.09283683250416441e-02 -2.19491646177715771e-02 0.00000000000000000e+00,
              -1.43002133115783803e-02 -4.75565233385050190e-02 0.00000000000000000e+00,
              -3.65819410296186476e-03 -7.61569499616618906e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -1.06420192086165072e-01 0.00000000000000000e+00,
              -3.65819410296186476e-03 -1.36683434210668253e-01 0.00000000000000000e+00,
              -1.43002133115783803e-02 -1.65283860833825125e-01 0.00000000000000000e+00,
              -3.09283683250416441e-02 -1.91223782654827867e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.91500178640852647e-02 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.10410949289396210e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.44664948617130640e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.80914326546480653e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.27805723684447070e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -2.75362247022952089e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -3.17930323857418040e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -3.49523818382998341e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -3.53514575586229618e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -3.56507643488652937e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -3.59168148290807066e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -3.61163526892422704e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -3.62493779293499740e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -3.61496089992692005e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -3.59168148290807066e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -3.54512264887037354e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -3.49191255282729096e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -3.43205119477882292e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -3.37551546773304789e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.32563100269265832e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.27574653765226764e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -3.22253644160918618e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -3.17597760757148850e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -3.13607003553917685e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -3.10281372551225010e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -3.06955741548532335e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -3.04295236746378261e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -3.02964984345301169e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -2.80350693526991157e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -2.47426946600333819e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -2.11842694871522297e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.80914326546480653e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.54309278524939364e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.29367046004744468e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.07085318286703646e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.84617846716246881e-02 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.11685034576228226e-02 -1.55639530926016401e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99792901872025652e+02 4.99792901872025652e+02 8.99972286408310964e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            77 76 84 -1 
            76 75 84 -1 
            75 74 84 -1 
            74 73 84 -1 
            97 96 8 -1 
            96 95 8 -1 
            8 95 9 -1 
            95 94 9 -1 
            9 94 10 -1 
            94 93 10 -1 
            10 93 11 -1 
            11 93 12 -1 
            93 92 12 -1 
            12 92 13 -1 
            13 92 14 -1 
            14 92 15 -1 
            92 91 15 -1 
            15 91 16 -1 
            73 72 84 -1 
            37 36 38 -1 
            38 36 39 -1 
            39 36 40 -1 
            36 35 40 -1 
            40 35 41 -1 
            41 35 42 -1 
            42 35 43 -1 
            43 35 44 -1 
            44 35 45 -1 
            45 35 46 -1 
            46 35 47 -1 
            35 34 47 -1 
            34 33 47 -1 
            33 32 47 -1 
            32 31 47 -1 
            31 30 47 -1 
            30 29 47 -1 
            69 68 70 -1 
            70 68 71 -1 
            71 68 72 -1 
            25 24 26 -1 
            26 24 27 -1 
            27 24 28 -1 
            28 24 29 -1 
            24 23 29 -1 
            23 22 29 -1 
            22 21 29 -1 
            21 20 29 -1 
            19 18 20 -1 
            18 17 20 -1 
            17 16 20 -1 
            48 47 49 -1 
            49 47 50 -1 
            47 29 50 -1 
            50 29 51 -1 
            51 29 52 -1 
            52 29 53 -1 
            53 29 54 -1 
            54 29 55 -1 
            55 29 56 -1 
            56 29 57 -1 
            57 29 58 -1 
            29 20 58 -1 
            58 20 59 -1 
            59 20 60 -1 
            60 20 61 -1 
            61 20 62 -1 
            62 20 63 -1 
            63 20 64 -1 
            20 16 64 -1 
            64 16 65 -1 
            65 16 66 -1 
            66 16 67 -1 
            67 16 68 -1 
            16 91 68 -1 
            91 90 68 -1 
            68 90 72 -1 
            90 89 72 -1 
            89 88 72 -1 
            88 87 72 -1 
            87 86 72 -1 
            86 85 72 -1 
            85 84 72 -1 
            77 84 78 -1 
            8 7 97 -1 
            7 6 97 -1 
            6 5 97 -1 
            97 5 98 -1 
            5 4 98 -1 
            4 3 98 -1 
            98 3 99 -1 
            3 2 99 -1 
            99 2 100 -1 
            2 1 100 -1 
            100 1 101 -1 
            1 0 101 -1 
            101 0 102 -1 
            0 83 102 -1 
            83 82 102 -1 
            82 81 102 -1 
            78 84 79 -1 
            81 80 102 -1 
            79 84 80 -1 
            84 109 80 -1 
            109 108 80 -1 
            108 107 80 -1 
            107 106 80 -1 
            106 105 80 -1 
            105 104 80 -1 
            104 103 80 -1 
            102 80 103 -1 
            157 110 158 -1 
            110 139 158 -1 
            125 141 126 -1 
            141 140 126 -1 
            158 139 159 -1 
            159 139 160 -1 
            139 138 160 -1 
            160 138 161 -1 
            161 138 162 -1 
            138 137 162 -1 
            162 137 163 -1 
            137 136 163 -1 
            163 136 164 -1 
            136 135 164 -1 
            164 135 165 -1 
            135 134 165 -1 
            165 134 166 -1 
            166 134 167 -1 
            134 133 167 -1 
            167 133 168 -1 
            133 132 168 -1 
            168 132 169 -1 
            132 131 169 -1 
            169 131 170 -1 
            131 130 170 -1 
            170 130 171 -1 
            126 140 127 -1 
            140 171 127 -1 
            130 129 171 -1 
            129 128 171 -1 
            171 128 127 -1 
            141 125 142 -1 
            157 156 110 -1 
            111 110 156 -1 
            156 155 111 -1 
            111 155 112 -1 
            155 154 112 -1 
            154 153 112 -1 
            125 124 142 -1 
            142 124 143 -1 
            112 153 113 -1 
            153 152 113 -1 
            113 152 114 -1 
            152 151 114 -1 
            114 151 115 -1 
            151 150 115 -1 
            115 150 116 -1 
            150 149 116 -1 
            116 149 117 -1 
            149 148 117 -1 
            117 148 118 -1 
            148 147 118 -1 
            118 147 119 -1 
            147 146 119 -1 
            119 146 120 -1 
            146 145 120 -1 
            120 145 121 -1 
            145 144 121 -1 
            121 144 122 -1 
            144 143 122 -1 
            122 143 123 -1 
            143 124 123 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0016"
              point="
              -8.86945788418131764e-01 1.91888908855366358e-01 0.00000000000000000e+00,
              -8.59675614196051985e-01 2.17496267576099800e-01 0.00000000000000000e+00,
              -8.21763420765355690e-01 2.37782616692525040e-01 0.00000000000000000e+00,
              -7.78197654630081836e-01 2.50752577603026383e-01 0.00000000000000000e+00,
              -7.34299325394538793e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.03370957069497038e-01 2.52747956204642077e-01 0.00000000000000000e+00,
              -6.73772841145532375e-01 2.45764131098987426e-01 0.00000000000000000e+00,
              -6.46170103823183295e-01 2.34456985689832420e-01 0.00000000000000000e+00,
              -6.21227871302988399e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -5.98613580484678387e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -5.78327231368253036e-01 1.78918947944865014e-01 0.00000000000000000e+00,
              -5.60368823953712791e-01 1.53976715424670063e-01 0.00000000000000000e+00,
              -5.45736047541865110e-01 1.27039104302859529e-01 0.00000000000000000e+00,
              -5.34096339032440803e-01 9.74409883788949216e-02 0.00000000000000000e+00,
              -5.25449698425439760e-01 6.61800569535839212e-02 0.00000000000000000e+00,
              -5.20128688821131613e-01 3.32563100269265832e-02 0.00000000000000000e+00,
              -5.18133310219516030e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -5.20128688821131613e-01 -3.32563100269265832e-02 0.00000000000000000e+00,
              -5.25449698425439760e-01 -6.58474938533146481e-02 0.00000000000000000e+00,
              -5.34096339032440803e-01 -9.71084252786256208e-02 0.00000000000000000e+00,
              -5.45736047541865110e-01 -1.26373978102321038e-01 0.00000000000000000e+00,
              -5.60701387053982092e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -5.78659794468522337e-01 -1.78586384844595741e-01 0.00000000000000000e+00,
              -5.99611269785486067e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -6.22225560603796191e-01 -2.19491646177715438e-01 0.00000000000000000e+00,
              -6.47500356224260387e-01 -2.34789548790101665e-01 0.00000000000000000e+00,
              -6.75435656646878657e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -7.05698898771381922e-01 -2.53080519304911267e-01 0.00000000000000000e+00,
              -7.36959830196692867e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.83518664234390094e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -8.30742624472625812e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -8.72978138206822574e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -9.04239069632133630e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -9.08229826835364795e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -9.11222894737788169e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -9.13883399539942354e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -9.15878778141557937e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -9.17209030542634918e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -9.16543904342096427e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -9.13883399539942354e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -9.09227516136172587e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -9.04239069632133630e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -8.98252933827286881e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -8.92599361122709323e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -8.87278351518401065e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -8.82289905014362108e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -8.76968895410053850e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -8.72313012006284083e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -8.68322254803052918e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -8.64996623800360243e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -8.61670992797667568e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -8.59343051095782684e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -8.58012798694705703e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -8.35398507876395580e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -8.02807324050007542e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -7.67555635421465321e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -7.36959830196692867e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -7.11685034576228670e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.86742802056033774e-01 -1.94216850557251242e-01 0.00000000000000000e+00,
              -6.62798258836646670e-01 -1.82244578947557689e-01 0.00000000000000000e+00,
              -6.41181657319144338e-01 -1.66281550134632916e-01 0.00000000000000000e+00,
              -6.21560434403257700e-01 -1.46660327218746223e-01 0.00000000000000000e+00,
              -6.04599716289525135e-01 -1.23380910199897609e-01 0.00000000000000000e+00,
              -5.90632066078215945e-01 -9.71084252786256208e-02 0.00000000000000000e+00,
              -5.79657483769330129e-01 -6.71777462543917125e-02 0.00000000000000000e+00,
              -5.73006221763944890e-01 -3.45865624280036477e-02 0.00000000000000000e+00,
              -5.70678280062060006e-01 -6.65126200538546097e-04 0.00000000000000000e+00,
              -5.71343406262598608e-01 1.79584074145403561e-02 0.00000000000000000e+00,
              -5.73006221763944890e-01 3.59168148290807121e-02 0.00000000000000000e+00,
              -6.07260221091679209e-01 1.69607181137325091e-02 0.00000000000000000e+00,
              -6.47500356224260387e-01 2.66050480215407337e-03 0.00000000000000000e+00,
              -6.91066122359534241e-01 -6.31869890511607690e-03 0.00000000000000000e+00,
              -7.34631888494807983e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -7.78530217730351137e-01 -4.65588340376971166e-03 0.00000000000000000e+00,
              -8.21763420765355690e-01 8.64664060700093273e-03 0.00000000000000000e+00,
              -8.59675614196051985e-01 2.89329897234261169e-02 0.00000000000000000e+00,
              -8.86945788418131764e-01 5.45403484441596142e-02 0.00000000000000000e+00,
              -8.96922681426209789e-01 6.98382510565458414e-02 0.00000000000000000e+00,
              -9.04571632732402819e-01 8.74640953708168967e-02 0.00000000000000000e+00,
              -9.09560079236441887e-01 1.05422502785357253e-01 0.00000000000000000e+00,
              -9.11222894737788169e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -9.09560079236441887e-01 1.40341628313630173e-01 0.00000000000000000e+00,
              -9.04571632732402819e-01 1.58632598828439775e-01 0.00000000000000000e+00,
              -8.96922681426209789e-01 1.76258443142710886e-01 0.00000000000000000e+00,
              -8.44710274683935003e-01 8.54687167692013139e-02 0.00000000000000000e+00,
              -8.27084430369663948e-01 7.01708141568150867e-02 0.00000000000000000e+00,
              -7.98151440646237775e-01 5.62031639455058962e-02 0.00000000000000000e+00,
              -7.65227693719580548e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -7.33966762294269492e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -6.91731248560072731e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -6.48830608625337479e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -6.11916104495448976e-01 7.21661927584306695e-02 0.00000000000000000e+00,
              -5.86308745774715478e-01 8.97920370727017247e-02 0.00000000000000000e+00,
              -6.02271774587640252e-01 1.22050657798820572e-01 0.00000000000000000e+00,
              -6.22890686804334681e-01 1.50318521321708143e-01 0.00000000000000000e+00,
              -6.47500356224260387e-01 1.72932812140018211e-01 0.00000000000000000e+00,
              -6.74770530446340167e-01 1.89893530253750775e-01 0.00000000000000000e+00,
              -7.04036083270035640e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.33966762294269492e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.65227693719580548e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.98151440646237775e-01 1.90226093354020076e-01 0.00000000000000000e+00,
              -8.27084430369663948e-01 1.75925880042441585e-01 0.00000000000000000e+00,
              -8.44710274683935003e-01 1.60627977430055358e-01 0.00000000000000000e+00,
              -8.49366158087704770e-01 1.52979026123862272e-01 0.00000000000000000e+00,
              -8.54022041491474426e-01 1.42004443814976455e-01 0.00000000000000000e+00,
              -8.57680235594436402e-01 1.30697298405821449e-01 0.00000000000000000e+00,
              -8.58677924895244193e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -8.57680235594436402e-01 1.15066832693165977e-01 0.00000000000000000e+00,
              -8.54022041491474426e-01 1.04092250384280161e-01 0.00000000000000000e+00,
              -8.49366158087704770e-01 9.27851049751251544e-02 0.00000000000000000e+00,
              -1.75593316942172173e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -2.46761820399795107e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -2.90660149635338150e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -3.18928013158225721e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -3.42207430177074334e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -3.60498400691883991e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -3.73135798502116089e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -3.80784749808309231e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -3.83112691510194003e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -3.80784749808309231e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -3.73135798502116089e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -3.60498400691883991e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -3.42207430177074334e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -3.18928013158225721e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -2.90660149635338150e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -2.57403839608411622e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -2.17828830676368934e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.78586384844595658e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -1.44997511717399719e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -1.17062211294781449e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -9.37827942759327238e-02 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.54918237611232890e-02 -1.29034482904475140e-01 0.00000000000000000e+00,
              -6.28544259508910796e-02 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.52054746446980493e-02 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.25449698425438649e-02 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.52054746446980493e-02 5.05495912409283932e-02 0.00000000000000000e+00,
              -6.28544259508910796e-02 9.34502311756637005e-02 0.00000000000000000e+00,
              -7.54918237611232890e-02 1.28701919804205811e-01 0.00000000000000000e+00,
              -9.37827942759327238e-02 1.56637220226824192e-01 0.00000000000000000e+00,
              -1.24711162600974590e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -1.62955919131940075e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.56073587207334530e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -3.14604692854725365e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.52516886285421660e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -3.82780128409924814e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -4.06059545428773427e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -4.22355137341967390e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.32332030350045415e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.35657661352738090e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.32332030350045415e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -4.22355137341967390e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -4.06059545428773427e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -3.82780128409924814e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.52516886285421660e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -3.14604692854725365e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -2.69708674318374420e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -2.17828830676368934e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.76258443142710775e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -1.38346249712014480e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -1.05089939685087841e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -7.68220761622002701e-02 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.35426591433516563e-02 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.39214362274650183e-02 1.58965161928709020e-01 0.00000000000000000e+00,
              -1.89560967153480364e-02 1.25376288801513192e-01 0.00000000000000000e+00,
              -8.31407750673152091e-03 8.77966584710861420e-02 0.00000000000000000e+00,
              -2.32794170188477256e-03 4.58937078371586815e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.32563100269234191e-03 -5.72008532463137154e-02 0.00000000000000000e+00,
              -1.33025240107705889e-02 -1.07750444487242136e-01 0.00000000000000000e+00,
              -2.99306790242338527e-02 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.32100960430823555e-02 -1.87565588551865947e-01 0.00000000000000000e+00,
              -9.41153573762020246e-02 -2.21154461679061776e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99792901872025652e+02 4.99792901872025652e+02 9.49972286408310964e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            77 76 84 -1 
            76 75 84 -1 
            75 74 84 -1 
            74 73 84 -1 
            97 96 8 -1 
            96 95 8 -1 
            8 95 9 -1 
            95 94 9 -1 
            9 94 10 -1 
            94 93 10 -1 
            10 93 11 -1 
            11 93 12 -1 
            93 92 12 -1 
            12 92 13 -1 
            13 92 14 -1 
            14 92 15 -1 
            92 91 15 -1 
            15 91 16 -1 
            73 72 84 -1 
            37 36 38 -1 
            38 36 39 -1 
            39 36 40 -1 
            36 35 40 -1 
            40 35 41 -1 
            41 35 42 -1 
            42 35 43 -1 
            43 35 44 -1 
            44 35 45 -1 
            45 35 46 -1 
            46 35 47 -1 
            35 34 47 -1 
            34 33 47 -1 
            33 32 47 -1 
            32 31 47 -1 
            31 30 47 -1 
            30 29 47 -1 
            69 68 70 -1 
            70 68 71 -1 
            71 68 72 -1 
            25 24 26 -1 
            26 24 27 -1 
            27 24 28 -1 
            28 24 29 -1 
            24 23 29 -1 
            23 22 29 -1 
            22 21 29 -1 
            21 20 29 -1 
            19 18 20 -1 
            18 17 20 -1 
            17 16 20 -1 
            48 47 49 -1 
            49 47 50 -1 
            47 29 50 -1 
            50 29 51 -1 
            51 29 52 -1 
            52 29 53 -1 
            53 29 54 -1 
            54 29 55 -1 
            55 29 56 -1 
            56 29 57 -1 
            57 29 58 -1 
            29 20 58 -1 
            58 20 59 -1 
            59 20 60 -1 
            60 20 61 -1 
            61 20 62 -1 
            62 20 63 -1 
            63 20 64 -1 
            20 16 64 -1 
            64 16 65 -1 
            65 16 66 -1 
            66 16 67 -1 
            67 16 68 -1 
            16 91 68 -1 
            91 90 68 -1 
            68 90 72 -1 
            90 89 72 -1 
            89 88 72 -1 
            88 87 72 -1 
            87 86 72 -1 
            86 85 72 -1 
            85 84 72 -1 
            77 84 78 -1 
            8 7 97 -1 
            7 6 97 -1 
            6 5 97 -1 
            97 5 98 -1 
            5 4 98 -1 
            4 3 98 -1 
            98 3 99 -1 
            3 2 99 -1 
            99 2 100 -1 
            2 1 100 -1 
            100 1 101 -1 
            1 0 101 -1 
            101 0 102 -1 
            0 83 102 -1 
            83 82 102 -1 
            82 81 102 -1 
            78 84 79 -1 
            81 80 102 -1 
            79 84 80 -1 
            84 109 80 -1 
            109 108 80 -1 
            108 107 80 -1 
            107 106 80 -1 
            106 105 80 -1 
            105 104 80 -1 
            104 103 80 -1 
            102 80 103 -1 
            125 124 126 -1 
            126 124 127 -1 
            127 124 128 -1 
            122 121 123 -1 
            121 120 123 -1 
            120 119 123 -1 
            119 118 123 -1 
            134 133 135 -1 
            133 132 135 -1 
            132 131 135 -1 
            135 131 136 -1 
            131 130 136 -1 
            136 130 137 -1 
            130 129 137 -1 
            137 129 138 -1 
            129 128 138 -1 
            128 124 138 -1 
            124 123 138 -1 
            138 123 139 -1 
            123 118 139 -1 
            139 118 140 -1 
            140 118 141 -1 
            118 117 141 -1 
            141 117 142 -1 
            117 116 142 -1 
            142 116 143 -1 
            116 115 143 -1 
            143 115 144 -1 
            144 115 145 -1 
            145 115 146 -1 
            146 115 147 -1 
            165 164 166 -1 
            166 164 167 -1 
            167 164 168 -1 
            168 164 169 -1 
            164 163 169 -1 
            169 163 170 -1 
            170 163 171 -1 
            171 163 172 -1 
            172 163 173 -1 
            173 163 174 -1 
            174 163 175 -1 
            163 162 175 -1 
            162 161 175 -1 
            161 160 175 -1 
            160 159 175 -1 
            159 158 175 -1 
            158 157 175 -1 
            149 148 150 -1 
            148 147 150 -1 
            150 147 151 -1 
            151 147 152 -1 
            152 147 153 -1 
            153 147 154 -1 
            154 147 155 -1 
            156 155 157 -1 
            115 114 147 -1 
            114 113 147 -1 
            113 112 147 -1 
            112 111 147 -1 
            111 110 147 -1 
            110 189 147 -1 
            176 175 177 -1 
            147 189 155 -1 
            189 188 155 -1 
            188 187 155 -1 
            155 187 157 -1 
            187 186 157 -1 
            186 185 157 -1 
            185 184 157 -1 
            184 183 157 -1 
            183 182 157 -1 
            182 181 157 -1 
            181 180 157 -1 
            177 175 178 -1 
            175 157 178 -1 
            178 157 179 -1 
            180 179 157 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0017"
              point="
              -8.34400818575587899e-01 1.91888908855366358e-01 0.00000000000000000e+00,
              -8.07130644353508120e-01 2.17496267576099800e-01 0.00000000000000000e+00,
              -7.69218450922811825e-01 2.37782616692525040e-01 0.00000000000000000e+00,
              -7.25652684787537972e-01 2.50752577603026383e-01 0.00000000000000000e+00,
              -6.81754355551994928e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -6.50825987226953173e-01 2.52747956204642077e-01 0.00000000000000000e+00,
              -6.21227871302988510e-01 2.45764131098987426e-01 0.00000000000000000e+00,
              -5.93625133980639430e-01 2.34456985689832420e-01 0.00000000000000000e+00,
              -5.68682901460444534e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -5.46068610642134411e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -5.25782261525709282e-01 1.78918947944865014e-01 0.00000000000000000e+00,
              -5.07823854111168815e-01 1.53976715424670063e-01 0.00000000000000000e+00,
              -4.93191077699321245e-01 1.27039104302859529e-01 0.00000000000000000e+00,
              -4.81551369189896938e-01 9.74409883788949216e-02 0.00000000000000000e+00,
              -4.72904728582895950e-01 6.61800569535839212e-02 0.00000000000000000e+00,
              -4.67583718978587692e-01 3.32563100269265832e-02 0.00000000000000000e+00,
              -4.65588340376972165e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.67583718978587692e-01 -3.32563100269265832e-02 0.00000000000000000e+00,
              -4.72904728582895950e-01 -6.58474938533146481e-02 0.00000000000000000e+00,
              -4.81551369189896938e-01 -9.71084252786256208e-02 0.00000000000000000e+00,
              -4.93191077699321245e-01 -1.26373978102321038e-01 0.00000000000000000e+00,
              -5.08156417211438116e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -5.26114824625978583e-01 -1.78586384844595741e-01 0.00000000000000000e+00,
              -5.47066299942942313e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -5.69680590761252326e-01 -2.19491646177715438e-01 0.00000000000000000e+00,
              -5.94955386381716522e-01 -2.34789548790101665e-01 0.00000000000000000e+00,
              -6.22890686804334903e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -6.53153928928838057e-01 -2.53080519304911267e-01 0.00000000000000000e+00,
              -6.84414860354149002e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.30973694391846229e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -7.78197654630081948e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -8.20433168364278709e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -8.51694099789589765e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -8.55684856992820930e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -8.58677924895244304e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -8.61338429697398489e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -8.63333808299014072e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -8.64664060700091053e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -8.63998934499552562e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -8.61338429697398489e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -8.56682546293628722e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -8.51694099789589765e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -8.45707963984742905e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -8.40054391280165458e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -8.34733381675857200e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -8.29744935171818243e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -8.24423925567509985e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -8.19768042163740218e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -8.15777284960509053e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -8.12451653957816378e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -8.09126022955123703e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -8.06798081253238819e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -8.05467828852161838e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -7.82853538033851715e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -7.50262354207463678e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -7.15010665578921456e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.84414860354149002e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -6.59140064733684805e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.34197832213489909e-01 -1.94216850557251242e-01 0.00000000000000000e+00,
              -6.10253288994102805e-01 -1.82244578947557689e-01 0.00000000000000000e+00,
              -5.88636687476600473e-01 -1.66281550134632916e-01 0.00000000000000000e+00,
              -5.69015464560713724e-01 -1.46660327218746223e-01 0.00000000000000000e+00,
              -5.52054746446981159e-01 -1.23380910199897609e-01 0.00000000000000000e+00,
              -5.38087096235672080e-01 -9.71084252786256208e-02 0.00000000000000000e+00,
              -5.27112513926786264e-01 -6.71777462543917125e-02 0.00000000000000000e+00,
              -5.20461251921401025e-01 -3.45865624280036477e-02 0.00000000000000000e+00,
              -5.18133310219516141e-01 -6.65126200538546097e-04 0.00000000000000000e+00,
              -5.18798436420054632e-01 1.79584074145403561e-02 0.00000000000000000e+00,
              -5.20461251921401025e-01 3.59168148290807121e-02 0.00000000000000000e+00,
              -5.54715251249135344e-01 1.69607181137325091e-02 0.00000000000000000e+00,
              -5.94955386381716522e-01 2.66050480215407337e-03 0.00000000000000000e+00,
              -6.38521152516990376e-01 -6.31869890511607690e-03 0.00000000000000000e+00,
              -6.82086918652264229e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -7.25985247887807272e-01 -4.65588340376971166e-03 0.00000000000000000e+00,
              -7.69218450922811825e-01 8.64664060700093273e-03 0.00000000000000000e+00,
              -8.07130644353508120e-01 2.89329897234261169e-02 0.00000000000000000e+00,
              -8.34400818575587899e-01 5.45403484441596142e-02 0.00000000000000000e+00,
              -8.44377711583665924e-01 6.98382510565458414e-02 0.00000000000000000e+00,
              -8.52026662889858954e-01 8.74640953708168967e-02 0.00000000000000000e+00,
              -8.57015109393898022e-01 1.05422502785357253e-01 0.00000000000000000e+00,
              -8.58677924895244304e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -8.57015109393898022e-01 1.40341628313630173e-01 0.00000000000000000e+00,
              -8.52026662889858954e-01 1.58632598828439775e-01 0.00000000000000000e+00,
              -8.44377711583665924e-01 1.76258443142710886e-01 0.00000000000000000e+00,
              -7.92165304841391138e-01 8.54687167692013139e-02 0.00000000000000000e+00,
              -7.74539460527120083e-01 7.01708141568150867e-02 0.00000000000000000e+00,
              -7.45606470803694021e-01 5.62031639455058962e-02 0.00000000000000000e+00,
              -7.12682723877036572e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -6.81421792451725628e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -6.39186278717528866e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -5.96285638782793503e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -5.59371134652905111e-01 7.21661927584306695e-02 0.00000000000000000e+00,
              -5.33763775932171614e-01 8.97920370727017247e-02 0.00000000000000000e+00,
              -5.49726804745096387e-01 1.22050657798820572e-01 0.00000000000000000e+00,
              -5.70345716961790927e-01 1.50318521321708143e-01 0.00000000000000000e+00,
              -5.94955386381716522e-01 1.72932812140018211e-01 0.00000000000000000e+00,
              -6.22225560603796302e-01 1.89893530253750775e-01 0.00000000000000000e+00,
              -6.51491113427491775e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -6.81421792451725628e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.12682723877036572e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.45606470803694021e-01 1.90226093354020076e-01 0.00000000000000000e+00,
              -7.74539460527120083e-01 1.75925880042441585e-01 0.00000000000000000e+00,
              -7.92165304841391138e-01 1.60627977430055358e-01 0.00000000000000000e+00,
              -7.96821188245160905e-01 1.52979026123862272e-01 0.00000000000000000e+00,
              -8.01477071648930561e-01 1.42004443814976455e-01 0.00000000000000000e+00,
              -8.05135265751892537e-01 1.30697298405821449e-01 0.00000000000000000e+00,
              -8.06132955052700328e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -8.05135265751892537e-01 1.15066832693165977e-01 0.00000000000000000e+00,
              -8.01477071648930561e-01 1.04092250384280161e-01 0.00000000000000000e+00,
              -7.96821188245160905e-01 9.27851049751251544e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 -1.25376288801513219e-01 0.00000000000000000e+00,
              -5.52054746446981603e-02 -9.27851049751251544e-02 0.00000000000000000e+00,
              -6.21892997503527001e-02 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.38290082597770070e-02 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.84617846716246881e-02 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.07085318286703646e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.29367046004744468e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.54309278524939364e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.80914326546480653e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.35556168171689206e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.45865624280036532e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -3.54179701786768053e-01 -1.66281550134633749e-03 0.00000000000000000e+00,
              -3.60165837591614912e-01 6.31869890511604915e-03 0.00000000000000000e+00,
              -3.61828653092961194e-01 1.59630288129247178e-02 0.00000000000000000e+00,
              -3.61828653092961194e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -3.60165837591614912e-01 2.39777995294140678e-01 0.00000000000000000e+00,
              -3.54179701786768053e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -3.45865624280036532e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -3.35556168171689206e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -2.62724849212719880e-02 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.98151440646244215e-03 2.47759509700603009e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.39112869093602132e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 2.29468539185793352e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.98151440646244215e-03 2.11177568670983806e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.05523995966406303e-01 0.00000000000000000e+00,
              -2.62724849212719880e-02 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.80914326546480653e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.44664948617130640e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.10410949289396210e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.91500178640852647e-02 1.72932812140018100e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.09283683250416441e-02 -2.19491646177715771e-02 0.00000000000000000e+00,
              -1.43002133115783803e-02 -4.75565233385050190e-02 0.00000000000000000e+00,
              -3.65819410296186476e-03 -7.61569499616618906e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -1.06420192086165072e-01 0.00000000000000000e+00,
              -3.65819410296186476e-03 -1.36683434210668253e-01 0.00000000000000000e+00,
              -1.43002133115783803e-02 -1.65283860833825125e-01 0.00000000000000000e+00,
              -3.09283683250416441e-02 -1.91223782654827867e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.91500178640852647e-02 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.10410949289396210e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.44664948617130640e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.80914326546480653e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.27805723684447070e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -2.75362247022952089e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -3.17930323857418040e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -3.49523818382998397e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -3.53514575586229562e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -3.56507643488652937e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -3.61163526892422704e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -3.62493779293499685e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -3.61496089992692005e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -3.54512264887037354e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -3.49191255282729096e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -3.43205119477882237e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -3.37551546773304789e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.32563100269265832e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.27574653765226764e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -3.22253644160918618e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -3.17597760757148850e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -3.13607003553917685e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -3.10281372551225010e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -3.06955741548532335e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -3.04295236746378261e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -3.02964984345301169e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -2.80350693526991157e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -2.47426946600333819e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -2.11842694871522297e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.80914326546480653e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.54309278524939364e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.29367046004744468e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.07085318286703646e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.84617846716246881e-02 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.11685034576228226e-02 -1.55639530926016401e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.19997228640831054e+02 4.99997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            12 11 10 9 8 7 6 5 4 3 2 1 -1 
            0 1 2 -1 
            0 2 3 -1 
            0 3 4 -1 
            0 4 5 -1 
            0 5 6 -1 
            0 6 7 -1 
            0 7 8 -1 
            0 8 9 -1 
            0 9 10 -1 
            0 10 11 -1 
            0 11 12 -1 
            0 12 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0018"
              point="
              1.38567954301834106e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.38567954301834106e-01 1.38567954301834106e-01 0.00000000000000000e+00,
              -1.38567954301834106e-01 1.20003372430801392e-01 6.92839771509170532e-02,
              -1.38567954301834106e-01 6.92839771509170532e-02 1.20003372430801392e-01,
              -1.38567954301834106e-01 8.48484070202902116e-18 1.38567954301834106e-01,
              -1.38567954301834106e-01 -6.92839771509170532e-02 1.20003372430801392e-01,
              -1.38567954301834106e-01 -1.20003372430801392e-01 6.92839771509170532e-02,
              -1.38567954301834106e-01 -1.38567954301834106e-01 1.69696814040580423e-17,
              -1.38567954301834106e-01 -1.20003372430801392e-01 -6.92839771509170532e-02,
              -1.38567954301834106e-01 -6.92839771509170532e-02 -1.20003372430801392e-01,
              -1.38567954301834106e-01 -2.54545212789064509e-17 -1.38567954301834106e-01,
              -1.38567954301834106e-01 6.92839771509170532e-02 -1.20003372430801392e-01,
              -1.38567954301834106e-01 1.20003372430801392e-01 -6.92839771509170532e-02,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 5.20997228640831054e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            12 11 10 9 8 7 6 5 4 3 2 1 -1 
            0 1 2 -1 
            0 2 3 -1 
            0 3 4 -1 
            0 4 5 -1 
            0 5 6 -1 
            0 6 7 -1 
            0 7 8 -1 
            0 8 9 -1 
            0 9 10 -1 
            0 10 11 -1 
            0 11 12 -1 
            0 12 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0019"
              point="
              0.00000000000000000e+00 1.38567954301834106e-01 -1.19993756148116758e-17,
              1.38567954301834106e-01 -1.38567954301834106e-01 2.39987512296233516e-17,
              1.20003372430801392e-01 -1.38567954301834106e-01 -6.92839771509170532e-02,
              6.92839771509170532e-02 -1.38567954301834106e-01 -1.20003372430801392e-01,
              2.04842163168406969e-17 -1.38567954301834106e-01 -1.38567954301834106e-01,
              -6.92839771509170532e-02 -1.38567954301834106e-01 -1.20003372430801392e-01,
              -1.20003372430801392e-01 -1.38567954301834106e-01 -6.92839771509170532e-02,
              -1.38567954301834106e-01 -1.38567954301834106e-01 -1.69696814040580423e-17,
              -1.20003372430801392e-01 -1.38567954301834106e-01 6.92839771509170532e-02,
              -6.92839771509170532e-02 -1.38567954301834106e-01 1.20003372430801392e-01,
              -3.74538977208987392e-17 -1.38567954301834106e-01 1.38567954301834106e-01,
              6.92839771509170532e-02 -1.38567954301834106e-01 1.20003372430801392e-01,
              1.20003372430801392e-01 -1.38567954301834106e-01 6.92839771509170532e-02,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 4.99997228640831111e+02 9.99972286408310964e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            12 11 10 9 8 7 6 5 4 3 2 1 -1 
            0 1 2 -1 
            0 2 3 -1 
            0 3 4 -1 
            0 4 5 -1 
            0 5 6 -1 
            0 6 7 -1 
            0 7 8 -1 
            0 8 9 -1 
            0 9 10 -1 
            0 10 11 -1 
            0 11 12 -1 
            0 12 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0020"
              point="
              0.00000000000000000e+00 1.19993756148116758e-17 1.38567954301834106e-01,
              -1.19993756148116758e-17 -1.38567954301834106e-01 -1.38567954301834106e-01,
              6.92839771509170532e-02 -1.20003372430801392e-01 -1.38567954301834106e-01,
              1.20003372430801392e-01 -6.92839771509170532e-02 -1.38567954301834106e-01,
              1.38567954301834106e-01 -3.24835911044717602e-17 -1.38567954301834106e-01,
              1.20003372430801392e-01 6.92839771509170532e-02 -1.38567954301834106e-01,
              6.92839771509170532e-02 1.20003372430801392e-01 -1.38567954301834106e-01,
              2.89690561916891055e-17 1.38567954301834106e-01 -1.38567954301834106e-01,
              -6.92839771509170532e-02 1.20003372430801392e-01 -1.38567954301834106e-01,
              -1.20003372430801392e-01 6.92839771509170532e-02 -1.38567954301834106e-01,
              -1.38567954301834106e-01 2.54545212789064509e-17 -1.38567954301834106e-01,
              -1.20003372430801392e-01 -6.92839771509170532e-02 -1.38567954301834106e-01,
              -6.92839771509170532e-02 -1.20003372430801392e-01 -1.38567954301834106e-01,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="0.00000000000000000e+00"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              specularColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              shininess="7.81250000000000000e-03"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="true"
            normalPerVertex="true"
            coordIndex="
            0 2 3 -1 
            2 0 5 -1 
            1 0 3 -1 
            7 1 3 -1 
            9 5 0 -1 
            1 9 0 -1 
            1 7 10 -1 
            9 1 10 -1 
            4 12 15 -1 
            4 3 2 -1 
            4 2 12 -1 
            12 2 13 -1 
            5 6 14 -1 
            5 14 2 -1 
            2 14 13 -1 
            14 6 17 -1 
            8 4 15 -1 
            19 8 15 -1 
            3 8 7 -1 
            4 8 3 -1 
            6 9 11 -1 
            5 9 6 -1 
            23 17 6 -1 
            11 23 6 -1 
            8 19 20 -1 
            7 8 20 -1 
            7 20 10 -1 
            10 20 22 -1 
            11 9 10 -1 
            11 10 24 -1 
            24 10 22 -1 
            23 11 24 -1 
            12 16 15 -1 
            27 16 12 -1 
            27 12 26 -1 
            26 12 13 -1 
            18 28 26 -1 
            18 26 14 -1 
            14 26 13 -1 
            14 17 18 -1 
            16 21 19 -1 
            15 16 19 -1 
            30 16 27 -1 
            21 16 30 -1 
            25 28 18 -1 
            31 28 25 -1 
            17 23 25 -1 
            18 17 25 -1 
            19 21 20 -1 
            21 30 32 -1 
            21 32 20 -1 
            20 32 22 -1 
            31 25 24 -1 
            31 24 32 -1 
            32 24 22 -1 
            23 24 25 -1 
            26 29 27 -1 
            26 28 29 -1 
            29 33 30 -1 
            27 29 30 -1 
            28 31 33 -1 
            29 28 33 -1 
            30 33 32 -1 
            31 32 33 -1 
            34 38 40 -1 
            38 34 43 -1 
            35 41 47 -1 
            40 44 34 -1 
            41 35 49 -1 
            45 51 36 -1 
            43 34 44 -1 
            45 36 54 -1 
            35 47 50 -1 
            52 57 37 -1 
            49 35 50 -1 
            51 55 36 -1 
            52 37 59 -1 
            54 36 55 -1 
            37 57 60 -1 
            59 37 60 -1 
            39 62 63 -1 
            62 39 66 -1 
            42 64 69 -1 
            63 64 42 -1 
            39 63 42 -1 
            39 42 40 -1 
            39 40 38 -1 
            43 46 67 -1 
            38 43 67 -1 
            38 67 66 -1 
            38 66 39 -1 
            67 46 72 -1 
            48 70 75 -1 
            69 70 48 -1 
            42 69 48 -1 
            42 48 47 -1 
            42 47 41 -1 
            49 42 41 -1 
            49 53 42 -1 
            40 42 44 -1 
            44 42 53 -1 
            44 46 43 -1 
            44 53 46 -1 
            45 46 51 -1 
            51 46 53 -1 
            54 56 73 -1 
            45 54 73 -1 
            45 73 72 -1 
            45 72 46 -1 
            73 56 77 -1 
            48 75 76 -1 
            58 76 79 -1 
            50 76 58 -1 
            50 48 76 -1 
            50 47 48 -1 
            50 53 49 -1 
            50 58 53 -1 
            52 53 57 -1 
            57 53 58 -1 
            59 53 52 -1 
            59 61 53 -1 
            51 53 55 -1 
            55 53 61 -1 
            55 61 83 -1 
            54 55 83 -1 
            54 83 78 -1 
            54 78 56 -1 
            77 56 78 -1 
            58 79 81 -1 
            57 58 81 -1 
            57 81 60 -1 
            60 81 82 -1 
            61 59 60 -1 
            61 60 84 -1 
            84 60 82 -1 
            83 61 84 -1 
            65 87 90 -1 
            65 63 62 -1 
            65 62 87 -1 
            87 62 88 -1 
            66 68 89 -1 
            66 89 62 -1 
            62 89 88 -1 
            89 68 92 -1 
            71 91 94 -1 
            90 91 71 -1 
            65 90 71 -1 
            65 71 69 -1 
            65 69 64 -1 
            63 65 64 -1 
            66 67 68 -1 
            72 74 93 -1 
            67 72 93 -1 
            67 93 92 -1 
            67 92 68 -1 
            93 74 95 -1 
            70 71 94 -1 
            70 94 75 -1 
            75 94 96 -1 
            69 71 70 -1 
            72 73 74 -1 
            74 73 77 -1 
            74 77 95 -1 
            95 77 97 -1 
            80 76 75 -1 
            80 75 98 -1 
            98 75 96 -1 
            76 80 79 -1 
            78 83 85 -1 
            78 85 102 -1 
            78 102 77 -1 
            77 102 97 -1 
            80 98 99 -1 
            79 80 99 -1 
            79 99 81 -1 
            81 99 100 -1 
            86 100 104 -1 
            86 82 100 -1 
            82 81 100 -1 
            105 82 86 -1 
            105 101 82 -1 
            101 84 82 -1 
            85 83 84 -1 
            85 84 103 -1 
            103 84 101 -1 
            102 85 103 -1 
            86 104 106 -1 
            105 86 106 -1 
            87 107 110 -1 
            90 87 110 -1 
            88 108 87 -1 
            87 108 107 -1 
            89 109 88 -1 
            88 109 108 -1 
            109 89 92 -1 
            112 109 92 -1 
            91 111 114 -1 
            94 91 114 -1 
            110 91 90 -1 
            111 91 110 -1 
            92 113 112 -1 
            93 113 92 -1 
            113 93 95 -1 
            115 113 95 -1 
            94 114 96 -1 
            114 116 96 -1 
            95 97 115 -1 
            115 97 117 -1 
            96 116 98 -1 
            116 118 98 -1 
            97 102 117 -1 
            117 102 123 -1 
            98 118 119 -1 
            99 98 119 -1 
            100 99 121 -1 
            99 119 121 -1 
            121 104 100 -1 
            126 104 121 -1 
            101 127 122 -1 
            105 127 101 -1 
            103 101 124 -1 
            101 122 124 -1 
            123 102 103 -1 
            124 123 103 -1 
            104 126 128 -1 
            106 104 128 -1 
            127 105 106 -1 
            128 127 106 -1 
            107 129 132 -1 
            110 107 132 -1 
            108 130 107 -1 
            107 130 129 -1 
            109 131 108 -1 
            108 131 130 -1 
            131 109 112 -1 
            136 131 112 -1 
            111 133 139 -1 
            114 111 139 -1 
            132 111 110 -1 
            133 111 132 -1 
            112 138 136 -1 
            113 138 112 -1 
            138 113 115 -1 
            141 138 115 -1 
            114 139 116 -1 
            139 143 116 -1 
            115 117 141 -1 
            141 117 146 -1 
            144 120 118 -1 
            144 118 143 -1 
            143 118 116 -1 
            144 149 120 -1 
            125 151 147 -1 
            125 147 146 -1 
            125 146 123 -1 
            123 146 117 -1 
            118 120 119 -1 
            120 149 150 -1 
            120 150 119 -1 
            119 150 121 -1 
            150 126 121 -1 
            153 126 150 -1 
            122 154 152 -1 
            127 154 122 -1 
            151 125 124 -1 
            151 124 152 -1 
            152 124 122 -1 
            123 124 125 -1 
            126 153 155 -1 
            128 126 155 -1 
            154 127 128 -1 
            155 154 128 -1 
            129 134 132 -1 
            129 130 135 -1 
            134 129 135 -1 
            130 131 137 -1 
            135 130 137 -1 
            131 136 137 -1 
            133 140 139 -1 
            133 160 140 -1 
            132 160 133 -1 
            132 157 160 -1 
            132 134 157 -1 
            135 157 134 -1 
            158 157 135 -1 
            137 158 135 -1 
            159 158 137 -1 
            142 163 159 -1 
            138 142 159 -1 
            138 159 137 -1 
            138 137 136 -1 
            138 141 142 -1 
            140 145 143 -1 
            139 140 143 -1 
            165 140 160 -1 
            145 140 165 -1 
            148 163 142 -1 
            168 163 148 -1 
            141 146 148 -1 
            142 141 148 -1 
            143 145 144 -1 
            145 149 144 -1 
            145 165 149 -1 
            165 170 149 -1 
            147 168 148 -1 
            147 151 168 -1 
            151 175 168 -1 
            146 147 148 -1 
            149 170 171 -1 
            150 149 171 -1 
            153 173 156 -1 
            153 150 173 -1 
            150 171 173 -1 
            156 152 154 -1 
            156 173 152 -1 
            173 176 152 -1 
            175 151 152 -1 
            176 175 152 -1 
            153 156 155 -1 
            154 155 156 -1 
            157 161 160 -1 
            157 158 162 -1 
            161 157 162 -1 
            158 159 164 -1 
            162 158 164 -1 
            159 163 164 -1 
            161 166 165 -1 
            160 161 165 -1 
            162 167 161 -1 
            167 166 161 -1 
            164 169 162 -1 
            169 167 162 -1 
            163 168 169 -1 
            164 163 169 -1 
            166 172 170 -1 
            165 166 170 -1 
            167 174 166 -1 
            174 172 166 -1 
            169 177 167 -1 
            177 174 167 -1 
            168 175 177 -1 
            169 168 177 -1 
            170 172 171 -1 
            173 171 172 -1 
            174 173 172 -1 
            176 173 174 -1 
            177 176 174 -1 
            175 176 177 -1 
            178 182 185 -1 
            178 179 183 -1 
            182 178 183 -1 
            179 180 184 -1 
            183 179 184 -1 
            184 180 187 -1 
            178 185 186 -1 
            186 189 181 -1 
            186 181 178 -1 
            178 181 179 -1 
            190 188 180 -1 
            190 180 181 -1 
            181 180 179 -1 
            187 180 188 -1 
            181 189 191 -1 
            190 181 191 -1 
            182 192 195 -1 
            185 182 195 -1 
            183 193 182 -1 
            182 193 192 -1 
            184 194 183 -1 
            183 194 193 -1 
            194 184 187 -1 
            199 194 187 -1 
            185 195 196 -1 
            186 185 196 -1 
            196 189 186 -1 
            202 189 196 -1 
            188 203 200 -1 
            190 203 188 -1 
            199 187 188 -1 
            200 199 188 -1 
            189 202 204 -1 
            191 189 204 -1 
            203 190 191 -1 
            204 203 191 -1 
            192 197 195 -1 
            192 193 198 -1 
            197 192 198 -1 
            193 194 201 -1 
            198 193 201 -1 
            194 199 201 -1 
            195 197 196 -1 
            202 196 197 -1 
            202 197 205 -1 
            205 197 198 -1 
            200 203 205 -1 
            200 205 201 -1 
            201 205 198 -1 
            199 200 201 -1 
            202 205 204 -1 
            203 204 205 -1 
            206 208 209 -1 
            208 206 210 -1 
            207 206 209 -1 
            211 207 209 -1 
            212 210 206 -1 
            207 212 206 -1 
            207 211 213 -1 
            212 207 213 -1 
            208 214 215 -1 
            209 208 215 -1 
            214 208 210 -1 
            216 214 210 -1 
            209 215 211 -1 
            215 218 211 -1 
            210 212 216 -1 
            216 212 219 -1 
            211 218 220 -1 
            213 211 220 -1 
            219 212 213 -1 
            220 219 213 -1 
            214 217 215 -1 
            214 216 217 -1 
            217 221 218 -1 
            215 217 218 -1 
            216 219 221 -1 
            217 216 221 -1 
            218 221 220 -1 
            219 220 221 -1 
            222 230 233 -1 
            222 223 231 -1 
            230 222 231 -1 
            223 224 232 -1 
            231 223 232 -1 
            232 224 236 -1 
            222 233 234 -1 
            234 239 225 -1 
            234 225 222 -1 
            222 225 223 -1 
            240 237 224 -1 
            240 224 225 -1 
            225 224 223 -1 
            236 224 237 -1 
            225 239 241 -1 
            240 225 241 -1 
            226 242 245 -1 
            226 227 243 -1 
            242 226 243 -1 
            227 228 244 -1 
            243 227 244 -1 
            244 228 247 -1 
            226 245 246 -1 
            246 249 229 -1 
            246 229 226 -1 
            226 229 227 -1 
            250 248 228 -1 
            250 228 229 -1 
            229 228 227 -1 
            247 228 248 -1 
            229 249 251 -1 
            250 229 251 -1 
            230 235 233 -1 
            253 235 230 -1 
            253 230 252 -1 
            252 230 231 -1 
            238 254 252 -1 
            238 252 232 -1 
            232 252 231 -1 
            232 236 238 -1 
            233 235 234 -1 
            235 239 234 -1 
            235 253 239 -1 
            253 256 239 -1 
            237 254 238 -1 
            237 240 254 -1 
            240 257 254 -1 
            236 237 238 -1 
            239 256 258 -1 
            241 239 258 -1 
            257 240 241 -1 
            258 257 241 -1 
            242 260 263 -1 
            245 242 263 -1 
            243 261 242 -1 
            242 261 260 -1 
            244 262 243 -1 
            243 262 261 -1 
            262 244 247 -1 
            267 262 247 -1 
            245 263 264 -1 
            246 245 264 -1 
            264 249 246 -1 
            270 249 264 -1 
            248 271 268 -1 
            250 271 248 -1 
            267 247 248 -1 
            268 267 248 -1 
            249 270 272 -1 
            251 249 272 -1 
            271 250 251 -1 
            272 271 251 -1 
            252 255 253 -1 
            252 254 255 -1 
            255 259 256 -1 
            253 255 256 -1 
            254 257 259 -1 
            255 254 259 -1 
            256 259 258 -1 
            257 258 259 -1 
            260 265 263 -1 
            260 261 266 -1 
            265 260 266 -1 
            261 262 269 -1 
            266 261 269 -1 
            262 267 269 -1 
            263 265 264 -1 
            270 264 265 -1 
            270 265 273 -1 
            273 265 266 -1 
            268 271 273 -1 
            268 273 269 -1 
            269 273 266 -1 
            267 268 269 -1 
            270 273 272 -1 
            271 272 273 -1 
            274 275 277 -1 
            275 274 279 -1 
            274 277 280 -1 
            279 274 280 -1 
            276 286 287 -1 
            286 276 290 -1 
            278 288 293 -1 
            287 288 278 -1 
            276 287 278 -1 
            276 278 277 -1 
            276 277 275 -1 
            279 281 291 -1 
            275 279 291 -1 
            275 291 290 -1 
            275 290 276 -1 
            291 281 294 -1 
            282 278 293 -1 
            295 282 293 -1 
            277 283 280 -1 
            277 278 283 -1 
            278 282 283 -1 
            280 281 279 -1 
            280 283 281 -1 
            283 284 281 -1 
            297 294 281 -1 
            284 297 281 -1 
            282 295 296 -1 
            296 299 285 -1 
            296 285 282 -1 
            282 285 283 -1 
            301 298 284 -1 
            301 284 285 -1 
            285 284 283 -1 
            297 284 298 -1 
            285 299 302 -1 
            301 285 302 -1 
            289 304 307 -1 
            289 287 286 -1 
            289 286 304 -1 
            304 286 305 -1 
            290 292 306 -1 
            290 306 286 -1 
            286 306 305 -1 
            306 292 308 -1 
            288 289 307 -1 
            288 307 293 -1 
            293 307 309 -1 
            287 289 288 -1 
            290 291 292 -1 
            292 291 294 -1 
            292 294 308 -1 
            308 294 310 -1 
            293 309 295 -1 
            309 311 295 -1 
            294 297 310 -1 
            310 297 312 -1 
            300 296 295 -1 
            300 295 313 -1 
            313 295 311 -1 
            296 300 299 -1 
            298 301 303 -1 
            298 303 316 -1 
            298 316 297 -1 
            297 316 312 -1 
            300 313 314 -1 
            299 300 314 -1 
            299 314 302 -1 
            302 314 315 -1 
            303 301 302 -1 
            303 302 317 -1 
            317 302 315 -1 
            316 303 317 -1 
            304 318 321 -1 
            307 304 321 -1 
            305 319 304 -1 
            304 319 318 -1 
            306 320 305 -1 
            305 320 319 -1 
            320 306 308 -1 
            323 320 308 -1 
            307 321 309 -1 
            321 325 309 -1 
            308 310 323 -1 
            323 310 326 -1 
            309 325 311 -1 
            325 327 311 -1 
            310 312 326 -1 
            326 312 328 -1 
            311 327 313 -1 
            327 329 313 -1 
            312 316 328 -1 
            328 316 333 -1 
            313 329 330 -1 
            314 313 330 -1 
            315 314 332 -1 
            314 330 332 -1 
            317 315 334 -1 
            315 332 334 -1 
            333 316 317 -1 
            334 333 317 -1 
            318 322 321 -1 
            337 322 318 -1 
            337 318 336 -1 
            336 318 319 -1 
            324 339 336 -1 
            324 336 320 -1 
            320 336 319 -1 
            320 323 324 -1 
            322 338 342 -1 
            322 342 321 -1 
            321 342 325 -1 
            337 338 322 -1 
            324 341 339 -1 
            341 324 323 -1 
            341 323 345 -1 
            345 323 326 -1 
            325 342 327 -1 
            342 347 327 -1 
            326 328 345 -1 
            345 328 351 -1 
            348 331 329 -1 
            348 329 347 -1 
            347 329 327 -1 
            348 354 331 -1 
            335 355 352 -1 
            335 352 351 -1 
            335 351 333 -1 
            333 351 328 -1 
            329 331 330 -1 
            331 354 356 -1 
            331 356 330 -1 
            330 356 332 -1 
            355 335 334 -1 
            355 334 356 -1 
            356 334 332 -1 
            333 334 335 -1 
            336 340 337 -1 
            336 339 340 -1 
            338 343 342 -1 
            338 337 340 -1 
            338 340 343 -1 
            343 340 344 -1 
            339 341 346 -1 
            339 346 340 -1 
            340 346 344 -1 
            341 345 346 -1 
            343 349 347 -1 
            342 343 347 -1 
            344 350 343 -1 
            350 349 343 -1 
            346 353 344 -1 
            353 350 344 -1 
            345 351 353 -1 
            346 345 353 -1 
            347 349 348 -1 
            354 348 349 -1 
            354 349 357 -1 
            357 349 350 -1 
            352 355 357 -1 
            352 357 353 -1 
            353 357 350 -1 
            351 352 353 -1 
            354 357 356 -1 
            355 356 357 -1 
            358 360 361 -1 
            360 358 363 -1 
            359 358 361 -1 
            365 359 361 -1 
            367 363 358 -1 
            359 367 358 -1 
            359 365 368 -1 
            367 359 368 -1 
            362 370 373 -1 
            362 361 360 -1 
            362 360 370 -1 
            370 360 371 -1 
            363 364 372 -1 
            363 372 360 -1 
            360 372 371 -1 
            372 364 374 -1 
            366 362 373 -1 
            375 366 373 -1 
            361 366 365 -1 
            362 366 361 -1 
            364 367 369 -1 
            363 367 364 -1 
            378 374 364 -1 
            369 378 364 -1 
            366 375 376 -1 
            365 366 376 -1 
            365 376 368 -1 
            368 376 377 -1 
            369 367 368 -1 
            369 368 379 -1 
            379 368 377 -1 
            378 369 379 -1 
            370 380 383 -1 
            373 370 383 -1 
            371 381 370 -1 
            370 381 380 -1 
            372 382 371 -1 
            371 382 381 -1 
            382 372 374 -1 
            386 382 374 -1 
            373 383 375 -1 
            383 388 375 -1 
            374 378 386 -1 
            386 378 393 -1 
            375 388 389 -1 
            376 375 389 -1 
            377 376 391 -1 
            376 389 391 -1 
            379 377 394 -1 
            377 391 394 -1 
            393 378 379 -1 
            394 393 379 -1 
            380 384 383 -1 
            380 381 385 -1 
            384 380 385 -1 
            381 382 387 -1 
            385 381 387 -1 
            382 386 387 -1 
            384 390 388 -1 
            383 384 388 -1 
            385 392 384 -1 
            392 390 384 -1 
            387 395 385 -1 
            395 392 385 -1 
            386 393 395 -1 
            387 386 395 -1 
            388 390 389 -1 
            391 389 390 -1 
            392 391 390 -1 
            394 391 392 -1 
            395 394 392 -1 
            393 394 395 -1 
            396 397 398 -1 
            397 396 400 -1 
            396 398 401 -1 
            400 396 401 -1 
            399 409 413 -1 
            399 398 397 -1 
            399 397 409 -1 
            409 397 410 -1 
            400 402 412 -1 
            400 412 397 -1 
            397 412 410 -1 
            412 402 414 -1 
            403 399 413 -1 
            415 403 413 -1 
            398 404 401 -1 
            398 399 404 -1 
            399 403 404 -1 
            401 402 400 -1 
            401 404 402 -1 
            404 405 402 -1 
            417 414 402 -1 
            405 417 402 -1 
            406 403 415 -1 
            419 406 415 -1 
            404 403 407 -1 
            407 403 406 -1 
            405 404 408 -1 
            408 404 407 -1 
            422 417 405 -1 
            408 422 405 -1 
            406 419 420 -1 
            407 406 420 -1 
            421 407 420 -1 
            408 407 421 -1 
            423 408 421 -1 
            422 408 423 -1 
            411 424 425 -1 
            424 411 427 -1 
            409 426 429 -1 
            413 409 429 -1 
            425 410 411 -1 
            425 426 410 -1 
            426 409 410 -1 
            411 428 427 -1 
            411 410 428 -1 
            410 412 428 -1 
            428 412 414 -1 
            431 428 414 -1 
            416 430 433 -1 
            416 429 430 -1 
            416 415 429 -1 
            415 413 429 -1 
            432 417 418 -1 
            432 431 417 -1 
            431 414 417 -1 
            432 418 435 -1 
            416 433 434 -1 
            434 415 416 -1 
            434 437 415 -1 
            437 419 415 -1 
            418 440 436 -1 
            418 417 440 -1 
            417 422 440 -1 
            435 418 436 -1 
            419 437 438 -1 
            420 419 438 -1 
            421 420 439 -1 
            420 438 439 -1 
            423 421 441 -1 
            421 439 441 -1 
            440 422 423 -1 
            441 440 423 -1 
            424 442 443 -1 
            425 424 443 -1 
            442 424 427 -1 
            445 442 427 -1 
            426 444 448 -1 
            429 426 448 -1 
            443 426 425 -1 
            444 426 443 -1 
            427 447 445 -1 
            428 447 427 -1 
            447 428 431 -1 
            450 447 431 -1 
            430 449 452 -1 
            433 430 452 -1 
            448 430 429 -1 
            449 430 448 -1 
            431 451 450 -1 
            432 451 431 -1 
            451 432 435 -1 
            455 451 435 -1 
            433 452 453 -1 
            434 433 453 -1 
            453 437 434 -1 
            458 437 453 -1 
            436 461 456 -1 
            440 461 436 -1 
            455 435 436 -1 
            456 455 436 -1 
            437 458 459 -1 
            438 437 459 -1 
            439 438 460 -1 
            438 459 460 -1 
            441 439 462 -1 
            439 460 462 -1 
            461 440 441 -1 
            462 461 441 -1 
            442 446 443 -1 
            442 445 446 -1 
            444 469 472 -1 
            448 444 472 -1 
            446 444 443 -1 
            446 470 444 -1 
            470 469 444 -1 
            445 470 446 -1 
            445 447 470 -1 
            447 471 470 -1 
            471 447 450 -1 
            475 471 450 -1 
            449 454 452 -1 
            449 477 454 -1 
            449 448 477 -1 
            448 472 477 -1 
            457 450 451 -1 
            457 480 450 -1 
            480 475 450 -1 
            451 455 457 -1 
            452 454 453 -1 
            454 458 453 -1 
            454 477 458 -1 
            477 482 458 -1 
            456 480 457 -1 
            456 461 480 -1 
            461 487 480 -1 
            455 456 457 -1 
            458 482 483 -1 
            459 458 483 -1 
            460 459 485 -1 
            459 483 485 -1 
            462 460 488 -1 
            460 485 488 -1 
            487 461 462 -1 
            488 487 462 -1 
            463 490 493 -1 
            463 464 491 -1 
            490 463 491 -1 
            464 465 492 -1 
            491 464 492 -1 
            492 465 495 -1 
            466 463 493 -1 
            497 466 493 -1 
            464 463 467 -1 
            467 463 466 -1 
            465 464 468 -1 
            468 464 467 -1 
            500 495 465 -1 
            468 500 465 -1 
            466 497 498 -1 
            467 466 498 -1 
            499 467 498 -1 
            468 467 499 -1 
            501 468 499 -1 
            500 468 501 -1 
            469 473 472 -1 
            469 470 474 -1 
            473 469 474 -1 
            470 471 476 -1 
            474 470 476 -1 
            471 475 476 -1 
            473 478 477 -1 
            472 473 477 -1 
            474 479 473 -1 
            479 478 473 -1 
            476 481 474 -1 
            481 479 474 -1 
            475 480 481 -1 
            476 475 481 -1 
            478 484 482 -1 
            477 478 482 -1 
            479 486 478 -1 
            486 484 478 -1 
            481 489 479 -1 
            489 486 479 -1 
            480 487 489 -1 
            481 480 489 -1 
            482 484 483 -1 
            485 483 484 -1 
            486 485 484 -1 
            488 485 486 -1 
            489 488 486 -1 
            487 488 489 -1 
            490 494 493 -1 
            503 494 490 -1 
            503 490 502 -1 
            502 490 491 -1 
            496 505 502 -1 
            496 502 492 -1 
            492 502 491 -1 
            492 495 496 -1 
            494 504 508 -1 
            494 508 493 -1 
            493 508 497 -1 
            503 504 494 -1 
            496 507 505 -1 
            507 496 495 -1 
            507 495 513 -1 
            513 495 500 -1 
            497 508 509 -1 
            498 497 509 -1 
            499 498 511 -1 
            498 509 511 -1 
            501 499 514 -1 
            499 511 514 -1 
            513 500 501 -1 
            514 513 501 -1 
            502 506 503 -1 
            502 505 506 -1 
            504 510 508 -1 
            504 503 506 -1 
            504 506 510 -1 
            510 506 512 -1 
            505 507 515 -1 
            505 515 506 -1 
            506 515 512 -1 
            507 513 515 -1 
            508 510 509 -1 
            511 509 510 -1 
            512 511 510 -1 
            514 511 512 -1 
            515 514 512 -1 
            513 514 515 -1 
            516 519 520 -1 
            519 516 521 -1 
            517 516 520 -1 
            523 517 520 -1 
            524 521 516 -1 
            517 524 516 -1 
            517 523 525 -1 
            524 517 525 -1 
            518 527 528 -1 
            527 518 529 -1 
            518 528 530 -1 
            529 518 530 -1 
            519 522 520 -1 
            519 521 522 -1 
            522 526 523 -1 
            520 522 523 -1 
            521 524 526 -1 
            522 521 526 -1 
            523 526 525 -1 
            524 525 526 -1 
            527 531 528 -1 
            527 529 531 -1 
            528 531 530 -1 
            529 530 531 -1 
            532 536 537 -1 
            536 532 539 -1 
            533 532 537 -1 
            541 533 537 -1 
            543 539 532 -1 
            533 543 532 -1 
            533 541 544 -1 
            543 533 544 -1 
            534 546 547 -1 
            546 534 549 -1 
            535 534 547 -1 
            551 535 547 -1 
            552 549 534 -1 
            535 552 534 -1 
            535 551 553 -1 
            552 535 553 -1 
            538 554 557 -1 
            538 537 536 -1 
            538 536 554 -1 
            554 536 555 -1 
            539 540 556 -1 
            539 556 536 -1 
            536 556 555 -1 
            556 540 560 -1 
            542 538 557 -1 
            562 542 557 -1 
            537 542 541 -1 
            538 542 537 -1 
            540 543 545 -1 
            539 543 540 -1 
            567 560 540 -1 
            545 567 540 -1 
            542 562 563 -1 
            541 542 563 -1 
            541 563 544 -1 
            544 563 565 -1 
            545 543 544 -1 
            545 544 568 -1 
            568 544 565 -1 
            567 545 568 -1 
            548 570 573 -1 
            548 547 546 -1 
            548 546 570 -1 
            570 546 571 -1 
            549 550 572 -1 
            549 572 546 -1 
            546 572 571 -1 
            572 550 577 -1 
            548 573 574 -1 
            574 547 548 -1 
            574 580 547 -1 
            580 551 547 -1 
            550 581 578 -1 
            550 549 581 -1 
            549 552 581 -1 
            577 550 578 -1 
            551 580 582 -1 
            553 551 582 -1 
            581 552 553 -1 
            582 581 553 -1 
            554 558 557 -1 
            554 555 559 -1 
            558 554 559 -1 
            555 556 561 -1 
            559 555 561 -1 
            556 560 561 -1 
            558 564 562 -1 
            557 558 562 -1 
            559 566 558 -1 
            566 564 558 -1 
            561 569 559 -1 
            569 566 559 -1 
            560 567 569 -1 
            561 560 569 -1 
            562 564 563 -1 
            565 563 564 -1 
            566 565 564 -1 
            568 565 566 -1 
            569 568 566 -1 
            567 568 569 -1 
            570 575 573 -1 
            570 571 576 -1 
            575 570 576 -1 
            571 572 579 -1 
            576 571 579 -1 
            572 577 579 -1 
            573 575 574 -1 
            580 574 575 -1 
            580 575 583 -1 
            583 575 576 -1 
            578 581 583 -1 
            578 583 579 -1 
            579 583 576 -1 
            577 578 579 -1 
            580 583 582 -1 
            581 582 583 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0021"
              point="
              5.10000000000000000e+02 5.09799987792968750e+02 7.90308303833007813e+01,
              5.10000000000000000e+02 5.10149993896484375e+02 7.90039215087890625e+01,
              5.10000000000000000e+02 5.09646453857421875e+02 7.91610031127929688e+01,
              5.09765899658203125e+02 5.09799987792968750e+02 7.91610031127929688e+01,
              5.09666656494140625e+02 5.09799987792968750e+02 7.93450622558593750e+01,
              5.10231353759765625e+02 5.09799987792968750e+02 7.91610031127929688e+01,
              5.10333343505859375e+02 5.09799987792968750e+02 7.93516540527343750e+01,
              5.09720153808593750e+02 5.10149993896484375e+02 7.91610031127929688e+01,
              5.09666656494140625e+02 5.10149993896484375e+02 7.92609634399414063e+01,
              5.10276947021484375e+02 5.10149993896484375e+02 7.91610031127929688e+01,
              5.10000000000000000e+02 5.10350830078125000e+02 7.91610031127929688e+01,
              5.10333343505859375e+02 5.10149993896484375e+02 7.92670669555664063e+01,
              5.09666656494140625e+02 5.09777770996093750e+02 7.93899993896484375e+01,
              5.10000000000000000e+02 5.09544311523437500e+02 7.93899993896484375e+01,
              5.10333343505859375e+02 5.09780975341796875e+02 7.93899993896484375e+01,
              5.09647674560546875e+02 5.09799987792968750e+02 7.93899993896484375e+01,
              5.09666656494140625e+02 5.09799987792968750e+02 7.94222946166992188e+01,
              5.10349426269531250e+02 5.09799987792968750e+02 7.93899993896484375e+01,
              5.10333343505859375e+02 5.09799987792968750e+02 7.94177780151367188e+01,
              5.09609100341796875e+02 5.10149993896484375e+02 7.93899993896484375e+01,
              5.09666656494140625e+02 5.10222015380859375e+02 7.93899993896484375e+01,
              5.09666656494140625e+02 5.10149993896484375e+02 7.94873962402343750e+01,
              5.10000000000000000e+02 5.10444427490234375e+02 7.93899993896484375e+01,
              5.10387847900390625e+02 5.10149993896484375e+02 7.93899993896484375e+01,
              5.10333343505859375e+02 5.10218780517578125e+02 7.93899993896484375e+01,
              5.10333343505859375e+02 5.10149993896484375e+02 7.94835128784179688e+01,
              5.10000000000000000e+02 5.09649841308593750e+02 7.96190032958984375e+01,
              5.09787811279296875e+02 5.09799987792968750e+02 7.96190032958984375e+01,
              5.10210723876953125e+02 5.09799987792968750e+02 7.96190032958984375e+01,
              5.10000000000000000e+02 5.09799987792968750e+02 7.96979904174804688e+01,
              5.09734924316406250e+02 5.10149993896484375e+02 7.96190032958984375e+01,
              5.10263702392578125e+02 5.10149993896484375e+02 7.96190032958984375e+01,
              5.10000000000000000e+02 5.10357666015625000e+02 7.96190032958984375e+01,
              5.10000000000000000e+02 5.10149993896484375e+02 7.97196731567382813e+01,
              5.04000000000000000e+02 5.07700012207031250e+02 8.09520950317382813e+01,
              5.03666656494140625e+02 5.08049987792968750e+02 8.09592361450195313e+01,
              5.04333343505859375e+02 5.08049987792968750e+02 8.09596557617187500e+01,
              5.04000000000000000e+02 5.08399993896484375e+02 8.09790802001953125e+01,
              5.04000000000000000e+02 5.07562591552734375e+02 8.09929962158203125e+01,
              5.04000000000000000e+02 5.07350006103515625e+02 8.11448211669921875e+01,
              5.03676055908203125e+02 5.07700012207031250e+02 8.09929962158203125e+01,
              5.03666656494140625e+02 5.07713043212890625e+02 8.09929962158203125e+01,
              5.03666656494140625e+02 5.07700012207031250e+02 8.09946060180664063e+01,
              5.04317810058593750e+02 5.07700012207031250e+02 8.09929962158203125e+01,
              5.04000000000000000e+02 5.07764160156250000e+02 8.09929962158203125e+01,
              5.04333343505859375e+02 5.07721710205078125e+02 8.09929962158203125e+01,
              5.04333343505859375e+02 5.07700012207031250e+02 8.09957122802734375e+01,
              5.03567230224609375e+02 5.08049987792968750e+02 8.09929962158203125e+01,
              5.03333343505859375e+02 5.08049987792968750e+02 8.11764755249023438e+01,
              5.03714660644531250e+02 5.08049987792968750e+02 8.09929962158203125e+01,
              5.03666656494140625e+02 5.08232940673828125e+02 8.09929962158203125e+01,
              5.04285919189453125e+02 5.08049987792968750e+02 8.09929962158203125e+01,
              5.04000000000000000e+02 5.08380584716796875e+02 8.09929962158203125e+01,
              5.04000000000000000e+02 5.08049987792968750e+02 8.10325088500976563e+01,
              5.04430908203125000e+02 5.08049987792968750e+02 8.09929962158203125e+01,
              5.04333343505859375e+02 5.08231719970703125e+02 8.09929962158203125e+01,
              5.04666656494140625e+02 5.08049987792968750e+02 8.11796798706054688e+01,
              5.03907775878906250e+02 5.08399993896484375e+02 8.09929962158203125e+01,
              5.03666656494140625e+02 5.08399993896484375e+02 8.10405883789062500e+01,
              5.04092254638671875e+02 5.08399993896484375e+02 8.09929962158203125e+01,
              5.04000000000000000e+02 5.08433990478515625e+02 8.09929962158203125e+01,
              5.04333343505859375e+02 5.08399993896484375e+02 8.10406036376953125e+01,
              5.04000000000000000e+02 5.07272094726562500e+02 8.12220001220703125e+01,
              5.03737426757812500e+02 5.07350006103515625e+02 8.12220001220703125e+01,
              5.03666656494140625e+02 5.07367187500000000e+02 8.12220001220703125e+01,
              5.03666656494140625e+02 5.07350006103515625e+02 8.12607269287109375e+01,
              5.04253356933593750e+02 5.07350006103515625e+02 8.12220001220703125e+01,
              5.04333343505859375e+02 5.07370086669921875e+02 8.12220001220703125e+01,
              5.04333343505859375e+02 5.07350006103515625e+02 8.12675552368164063e+01,
              5.03353729248046875e+02 5.07700012207031250e+02 8.12220001220703125e+01,
              5.03333343505859375e+02 5.07832885742187500e+02 8.12220001220703125e+01,
              5.03333343505859375e+02 5.07700012207031250e+02 8.12712936401367188e+01,
              5.04642150878906250e+02 5.07700012207031250e+02 8.12220001220703125e+01,
              5.04666656494140625e+02 5.07855468750000000e+02 8.12220001220703125e+01,
              5.04666656494140625e+02 5.07700012207031250e+02 8.12819671630859375e+01,
              5.03289855957031250e+02 5.08049987792968750e+02 8.12220001220703125e+01,
              5.03333343505859375e+02 5.08161010742187500e+02 8.12220001220703125e+01,
              5.04706970214843750e+02 5.08049987792968750e+02 8.12220001220703125e+01,
              5.04666656494140625e+02 5.08155731201171875e+02 8.12220001220703125e+01,
              5.03421722412109375e+02 5.08399993896484375e+02 8.12220001220703125e+01,
              5.03333343505859375e+02 5.08399993896484375e+02 8.14213104248046875e+01,
              5.03666656494140625e+02 5.08624877929687500e+02 8.12220001220703125e+01,
              5.04000000000000000e+02 5.08708587646484375e+02 8.12220001220703125e+01,
              5.04578674316406250e+02 5.08399993896484375e+02 8.12220001220703125e+01,
              5.04333343505859375e+02 5.08626220703125000e+02 8.12220001220703125e+01,
              5.04666656494140625e+02 5.08399993896484375e+02 8.14195175170898438e+01,
              5.04000000000000000e+02 5.08750000000000000e+02 8.13563079833984375e+01,
              5.03666656494140625e+02 5.07248901367187500e+02 8.14509963989257813e+01,
              5.04000000000000000e+02 5.07166351318359375e+02 8.14509963989257813e+01,
              5.04333343505859375e+02 5.07252838134765625e+02 8.14509963989257813e+01,
              5.03527374267578125e+02 5.07350006103515625e+02 8.14509963989257813e+01,
              5.03333343505859375e+02 5.07559265136718750e+02 8.14509963989257813e+01,
              5.04465484619140625e+02 5.07350006103515625e+02 8.14509963989257813e+01,
              5.04666656494140625e+02 5.07568878173828125e+02 8.14509963989257813e+01,
              5.03242004394531250e+02 5.07700012207031250e+02 8.14509963989257813e+01,
              5.04751892089843750e+02 5.07700012207031250e+02 8.14509963989257813e+01,
              5.03182983398437500e+02 5.08049987792968750e+02 8.14509963989257813e+01,
              5.04814270019531250e+02 5.08049987792968750e+02 8.14509963989257813e+01,
              5.03318664550781250e+02 5.08399993896484375e+02 8.14509963989257813e+01,
              5.03333343505859375e+02 5.08418731689453125e+02 8.14509963989257813e+01,
              5.03666656494140625e+02 5.08716857910156250e+02 8.14509963989257813e+01,
              5.04333343505859375e+02 5.08718688964843750e+02 8.14509963989257813e+01,
              5.04682373046875000e+02 5.08399993896484375e+02 8.14509963989257813e+01,
              5.04666656494140625e+02 5.08420349121093750e+02 8.14509963989257813e+01,
              5.03835662841796875e+02 5.08750000000000000e+02 8.14509963989257813e+01,
              5.04169586181640625e+02 5.08750000000000000e+02 8.14509963989257813e+01,
              5.04000000000000000e+02 5.08794281005859375e+02 8.14509963989257813e+01,
              5.03666656494140625e+02 5.07225769042968750e+02 8.16800003051757813e+01,
              5.04000000000000000e+02 5.07147369384765625e+02 8.16800003051757813e+01,
              5.04333343505859375e+02 5.07229522705078125e+02 8.16800003051757813e+01,
              5.03495910644531250e+02 5.07350006103515625e+02 8.16800003051757813e+01,
              5.03333343505859375e+02 5.07525451660156250e+02 8.16800003051757813e+01,
              5.04496887207031250e+02 5.07350006103515625e+02 8.16800003051757813e+01,
              5.04666656494140625e+02 5.07534698486328125e+02 8.16800003051757813e+01,
              5.03219909667968750e+02 5.07700012207031250e+02 8.16800003051757813e+01,
              5.04774322509765625e+02 5.07700012207031250e+02 8.16800003051757813e+01,
              5.03164123535156250e+02 5.08049987792968750e+02 8.16800003051757813e+01,
              5.04833557128906250e+02 5.08049987792968750e+02 8.16800003051757813e+01,
              5.03294311523437500e+02 5.08399993896484375e+02 8.16800003051757813e+01,
              5.03333343505859375e+02 5.08449737548828125e+02 8.16800003051757813e+01,
              5.03333343505859375e+02 5.08399993896484375e+02 8.18229904174804688e+01,
              5.03666656494140625e+02 5.08732513427734375e+02 8.16800003051757813e+01,
              5.04333343505859375e+02 5.08734588623046875e+02 8.16800003051757813e+01,
              5.04707366943359375e+02 5.08399993896484375e+02 8.16800003051757813e+01,
              5.04666656494140625e+02 5.08452636718750000e+02 8.16800003051757813e+01,
              5.04666656494140625e+02 5.08399993896484375e+02 8.18295898437500000e+01,
              5.03755737304687500e+02 5.08750000000000000e+02 8.16800003051757813e+01,
              5.04252685546875000e+02 5.08750000000000000e+02 8.16800003051757813e+01,
              5.04000000000000000e+02 5.08816223144531250e+02 8.16800003051757813e+01,
              5.03666656494140625e+02 5.07284240722656250e+02 8.19089965820312500e+01,
              5.04000000000000000e+02 5.07195037841796875e+02 8.19089965820312500e+01,
              5.04333343505859375e+02 5.07288024902343750e+02 8.19089965820312500e+01,
              5.03576141357421875e+02 5.07350006103515625e+02 8.19089965820312500e+01,
              5.03333343505859375e+02 5.07612670898437500e+02 8.19089965820312500e+01,
              5.03666656494140625e+02 5.07350006103515625e+02 8.20041961669921875e+01,
              5.04000000000000000e+02 5.07350006103515625e+02 8.21359786987304688e+01,
              5.04417602539062500e+02 5.07350006103515625e+02 8.19089965820312500e+01,
              5.04333343505859375e+02 5.07350006103515625e+02 8.19990615844726563e+01,
              5.04666656494140625e+02 5.07621398925781250e+02 8.19089965820312500e+01,
              5.03276885986328125e+02 5.07700012207031250e+02 8.19089965820312500e+01,
              5.03333343505859375e+02 5.07700012207031250e+02 8.19942321777343750e+01,
              5.04717651367187500e+02 5.07700012207031250e+02 8.19089965820312500e+01,
              5.04666656494140625e+02 5.07700012207031250e+02 8.19867782592773438e+01,
              5.03213409423828125e+02 5.08049987792968750e+02 8.19089965820312500e+01,
              5.03333343505859375e+02 5.08350799560546875e+02 8.19089965820312500e+01,
              5.03333343505859375e+02 5.08049987792968750e+02 8.20925064086914063e+01,
              5.04784606933593750e+02 5.08049987792968750e+02 8.19089965820312500e+01,
              5.04666656494140625e+02 5.08353729248046875e+02 8.19089965820312500e+01,
              5.04666656494140625e+02 5.08049987792968750e+02 8.20902786254882813e+01,
              5.03351287841796875e+02 5.08399993896484375e+02 8.19089965820312500e+01,
              5.03666656494140625e+02 5.08690765380859375e+02 8.19089965820312500e+01,
              5.04650207519531250e+02 5.08399993896484375e+02 8.19089965820312500e+01,
              5.04333343505859375e+02 5.08692993164062500e+02 8.19089965820312500e+01,
              5.03967895507812500e+02 5.08750000000000000e+02 8.19089965820312500e+01,
              5.04033416748046875e+02 5.08750000000000000e+02 8.19089965820312500e+01,
              5.04000000000000000e+02 5.08758666992187500e+02 8.19089965820312500e+01,
              5.04000000000000000e+02 5.08750000000000000e+02 8.19230957031250000e+01,
              5.03666656494140625e+02 5.07434020996093750e+02 8.21380004882812500e+01,
              5.04000000000000000e+02 5.07350952148437500e+02 8.21380004882812500e+01,
              5.04333343505859375e+02 5.07436614990234375e+02 8.21380004882812500e+01,
              5.03417510986328125e+02 5.07700012207031250e+02 8.21380004882812500e+01,
              5.03666656494140625e+02 5.07700012207031250e+02 8.22924652099609375e+01,
              5.04000000000000000e+02 5.07700012207031250e+02 8.23350753784179688e+01,
              5.04579345703125000e+02 5.07700012207031250e+02 8.21380004882812500e+01,
              5.04333343505859375e+02 5.07700012207031250e+02 8.22916336059570313e+01,
              5.03355041503906250e+02 5.08049987792968750e+02 8.21380004882812500e+01,
              5.03666656494140625e+02 5.08049987792968750e+02 8.23281707763671875e+01,
              5.04000000000000000e+02 5.08049987792968750e+02 8.23072586059570313e+01,
              5.04644073486328125e+02 5.08049987792968750e+02 8.21380004882812500e+01,
              5.04333343505859375e+02 5.08049987792968750e+02 8.23280334472656250e+01,
              5.03502777099609375e+02 5.08399993896484375e+02 8.21380004882812500e+01,
              5.03666656494140625e+02 5.08549987792968750e+02 8.21380004882812500e+01,
              5.03666656494140625e+02 5.08399993896484375e+02 8.22420425415039063e+01,
              5.04000000000000000e+02 5.08655212402343750e+02 8.21380004882812500e+01,
              5.04000000000000000e+02 5.08399993896484375e+02 8.23084640502929688e+01,
              5.04499114990234375e+02 5.08399993896484375e+02 8.21380004882812500e+01,
              5.04333343505859375e+02 5.08552032470703125e+02 8.21380004882812500e+01,
              5.04333343505859375e+02 5.08399993896484375e+02 8.22427215576171875e+01,
              5.10666656494140625e+02 5.03850006103515625e+02 8.39513320922851563e+01,
              5.11000000000000000e+02 5.03850006103515625e+02 8.38013839721679688e+01,
              5.11333343505859375e+02 5.03850006103515625e+02 8.39514465332031250e+01,
              5.11000000000000000e+02 5.04200012207031250e+02 8.38129348754882813e+01,
              5.10666656494140625e+02 5.03823791503906250e+02 8.39700012207031250e+01,
              5.11000000000000000e+02 5.03570709228515625e+02 8.39700012207031250e+01,
              5.11333343505859375e+02 5.03823944091796875e+02 8.39700012207031250e+01,
              5.10646148681640625e+02 5.03850006103515625e+02 8.39700012207031250e+01,
              5.10666656494140625e+02 5.04026489257812500e+02 8.39700012207031250e+01,
              5.11353698730468750e+02 5.03850006103515625e+02 8.39700012207031250e+01,
              5.11333343505859375e+02 5.04025207519531250e+02 8.39700012207031250e+01,
              5.10681671142578125e+02 5.04200012207031250e+02 8.39700012207031250e+01,
              5.11318206787109375e+02 5.04200012207031250e+02 8.39700012207031250e+01,
              5.11000000000000000e+02 5.04432250976562500e+02 8.39700012207031250e+01,
              5.10666656494140625e+02 5.03801361083984375e+02 8.41989974975585938e+01,
              5.11000000000000000e+02 5.03562774658203125e+02 8.41989974975585938e+01,
              5.11333343505859375e+02 5.03801422119140625e+02 8.41989974975585938e+01,
              5.10628448486328125e+02 5.03850006103515625e+02 8.41989974975585938e+01,
              5.10666656494140625e+02 5.04175445556640625e+02 8.41989974975585938e+01,
              5.10666656494140625e+02 5.03850006103515625e+02 8.42376708984375000e+01,
              5.11000000000000000e+02 5.03850006103515625e+02 8.43916397094726563e+01,
              5.11371429443359375e+02 5.03850006103515625e+02 8.41989974975585938e+01,
              5.11333343505859375e+02 5.04174560546875000e+02 8.41989974975585938e+01,
              5.11333343505859375e+02 5.03850006103515625e+02 8.42376327514648438e+01,
              5.10668762207031250e+02 5.04200012207031250e+02 8.41989974975585938e+01,
              5.11331146240234375e+02 5.04200012207031250e+02 8.41989974975585938e+01,
              5.11000000000000000e+02 5.04442901611328125e+02 8.41989974975585938e+01,
              5.11000000000000000e+02 5.04200012207031250e+02 8.43821411132812500e+01,
              5.15000000000000000e+02 5.11899993896484375e+02 8.63726882934570313e+01,
              5.15000000000000000e+02 5.12250000000000000e+02 8.64712753295898438e+01,
              5.15000000000000000e+02 5.11666625976562500e+02 8.64889984130859375e+01,
              5.14736511230468750e+02 5.11899993896484375e+02 8.64889984130859375e+01,
              5.15263305664062500e+02 5.11899993896484375e+02 8.64889984130859375e+01,
              5.14957946777343750e+02 5.12250000000000000e+02 8.64889984130859375e+01,
              5.15042053222656250e+02 5.12250000000000000e+02 8.64889984130859375e+01,
              5.15000000000000000e+02 5.12277954101562500e+02 8.64889984130859375e+01,
              5.15000000000000000e+02 5.11622894287109375e+02 8.67180023193359375e+01,
              5.14692382812500000e+02 5.11899993896484375e+02 8.67180023193359375e+01,
              5.15307495117187500e+02 5.11899993896484375e+02 8.67180023193359375e+01,
              5.15000000000000000e+02 5.11899993896484375e+02 8.68867034912109375e+01,
              5.14840515136718750e+02 5.12250000000000000e+02 8.67180023193359375e+01,
              5.15159545898437500e+02 5.12250000000000000e+02 8.67180023193359375e+01,
              5.15000000000000000e+02 5.12360473632812500e+02 8.67180023193359375e+01,
              5.15000000000000000e+02 5.12250000000000000e+02 8.68117599487304688e+01,
              5.10666656494140625e+02 5.03850006103515625e+02 8.98942871093750000e+01,
              5.11000000000000000e+02 5.03850006103515625e+02 8.97113265991210938e+01,
              5.11333343505859375e+02 5.03850006103515625e+02 8.98927383422851563e+01,
              5.11000000000000000e+02 5.04200012207031250e+02 8.97220306396484375e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 8.98677902221679688e+01,
              5.15000000000000000e+02 5.11899993896484375e+02 8.97166290283203125e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 8.98689804077148438e+01,
              5.15000000000000000e+02 5.12250000000000000e+02 8.97496795654296875e+01,
              5.10666656494140625e+02 5.03817382812500000e+02 8.99240036010742188e+01,
              5.11000000000000000e+02 5.03568237304687500e+02 8.99240036010742188e+01,
              5.11333343505859375e+02 5.03815551757812500e+02 8.99240036010742188e+01,
              5.10640655517578125e+02 5.03850006103515625e+02 8.99240036010742188e+01,
              5.10666656494140625e+02 5.04085205078125000e+02 8.99240036010742188e+01,
              5.10666656494140625e+02 5.03850006103515625e+02 9.00494155883789063e+01,
              5.11360809326171875e+02 5.03850006103515625e+02 8.99240036010742188e+01,
              5.11333343505859375e+02 5.04095306396484375e+02 8.99240036010742188e+01,
              5.11333343505859375e+02 5.03850006103515625e+02 9.00559463500976563e+01,
              5.10675720214843750e+02 5.04200012207031250e+02 8.99240036010742188e+01,
              5.11324981689453125e+02 5.04200012207031250e+02 8.99240036010742188e+01,
              5.11000000000000000e+02 5.04440429687500000e+02 8.99240036010742188e+01,
              5.14666687011718750e+02 5.11832519531250000e+02 8.99240036010742188e+01,
              5.15000000000000000e+02 5.11587310791015625e+02 8.99240036010742188e+01,
              5.15333312988281250e+02 5.11834411621093750e+02 8.99240036010742188e+01,
              5.14616394042968750e+02 5.11899993896484375e+02 8.99240036010742188e+01,
              5.14666687011718750e+02 5.12070556640625000e+02 8.99240036010742188e+01,
              5.15382141113281250e+02 5.11899993896484375e+02 8.99240036010742188e+01,
              5.15333312988281250e+02 5.12068176269531250e+02 8.99240036010742188e+01,
              5.14720764160156250e+02 5.12250000000000000e+02 8.99240036010742188e+01,
              5.15278930664062500e+02 5.12250000000000000e+02 8.99240036010742188e+01,
              5.15000000000000000e+02 5.12439514160156250e+02 8.99240036010742188e+01,
              5.11000000000000000e+02 5.03591125488281250e+02 9.01529998779296875e+01,
              5.10679779052734375e+02 5.03850006103515625e+02 9.01529998779296875e+01,
              5.11320983886718750e+02 5.03850006103515625e+02 9.01529998779296875e+01,
              5.11000000000000000e+02 5.03850006103515625e+02 9.03027572631835938e+01,
              5.10712585449218750e+02 5.04200012207031250e+02 9.01529998779296875e+01,
              5.11288085937500000e+02 5.04200012207031250e+02 9.01529998779296875e+01,
              5.11000000000000000e+02 5.04410614013671875e+02 9.01529998779296875e+01,
              5.11000000000000000e+02 5.04200012207031250e+02 9.02872009277343750e+01,
              5.14666687011718750e+02 5.11897033691406250e+02 9.01529998779296875e+01,
              5.15000000000000000e+02 5.11603515625000000e+02 9.01529998779296875e+01,
              5.15333312988281250e+02 5.11898803710937500e+02 9.01529998779296875e+01,
              5.14664489746093750e+02 5.11899993896484375e+02 9.01529998779296875e+01,
              5.14666687011718750e+02 5.11907684326171875e+02 9.01529998779296875e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 9.01548538208007813e+01,
              5.15000000000000000e+02 5.11899993896484375e+02 9.03094177246093750e+01,
              5.15334228515625000e+02 5.11899993896484375e+02 9.01529998779296875e+01,
              5.15333312988281250e+02 5.11903106689453125e+02 9.01529998779296875e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 9.01537475585937500e+01,
              5.14770996093750000e+02 5.12250000000000000e+02 9.01529998779296875e+01,
              5.15228576660156250e+02 5.12250000000000000e+02 9.01529998779296875e+01,
              5.15000000000000000e+02 5.12402832031250000e+02 9.01529998779296875e+01,
              5.15000000000000000e+02 5.12250000000000000e+02 9.02593460083007813e+01,
              5.10000000000000000e+02 5.09799987792968750e+02 9.19791717529296875e+01,
              5.10000000000000000e+02 5.09775848388671875e+02 9.19850006103515625e+01,
              5.10000000000000000e+02 5.09450012207031250e+02 9.21798477172851563e+01,
              5.09947448730468750e+02 5.09799987792968750e+02 9.19850006103515625e+01,
              5.09666656494140625e+02 5.09799987792968750e+02 9.20297241210937500e+01,
              5.10051757812500000e+02 5.09799987792968750e+02 9.19850006103515625e+01,
              5.10000000000000000e+02 5.09992004394531250e+02 9.19850006103515625e+01,
              5.10333343505859375e+02 5.09799987792968750e+02 9.20306777954101563e+01,
              5.09666656494140625e+02 5.10149993896484375e+02 9.20169143676757813e+01,
              5.10000000000000000e+02 5.10149993896484375e+02 9.19873733520507813e+01,
              5.10333343505859375e+02 5.10149993896484375e+02 9.20177917480468750e+01,
              5.10000000000000000e+02 5.10500000000000000e+02 9.21188201904296875e+01,
              5.10000000000000000e+02 5.09415527343750000e+02 9.22139968872070313e+01,
              5.09903137207031250e+02 5.09450012207031250e+02 9.22139968872070313e+01,
              5.09666656494140625e+02 5.09522674560546875e+02 9.22139968872070313e+01,
              5.09666656494140625e+02 5.09450012207031250e+02 9.23973999023437500e+01,
              5.10095306396484375e+02 5.09450012207031250e+02 9.22139968872070313e+01,
              5.10333343505859375e+02 5.09524505615234375e+02 9.22139968872070313e+01,
              5.10333343505859375e+02 5.09450012207031250e+02 9.24026107788085938e+01,
              5.09434814453125000e+02 5.09799987792968750e+02 9.22139968872070313e+01,
              5.10562713623046875e+02 5.09799987792968750e+02 9.22139968872070313e+01,
              5.09417419433593750e+02 5.10149993896484375e+02 9.22139968872070313e+01,
              5.09666656494140625e+02 5.10473815917968750e+02 9.22139968872070313e+01,
              5.10580078125000000e+02 5.10149993896484375e+02 9.22139968872070313e+01,
              5.10333343505859375e+02 5.10471710205078125e+02 9.22139968872070313e+01,
              5.09737091064453125e+02 5.10500000000000000e+02 9.22139968872070313e+01,
              5.09666656494140625e+02 5.10500000000000000e+02 9.22723236083984375e+01,
              5.10258605957031250e+02 5.10500000000000000e+02 9.22139968872070313e+01,
              5.10000000000000000e+02 5.10601806640625000e+02 9.22139968872070313e+01,
              5.10333343505859375e+02 5.10500000000000000e+02 9.22772750854492188e+01,
              5.09666656494140625e+02 5.09427764892578125e+02 9.24430007934570313e+01,
              5.10000000000000000e+02 5.09311981201171875e+02 9.24430007934570313e+01,
              5.10333343505859375e+02 5.09430328369140625e+02 9.24430007934570313e+01,
              5.09638916015625000e+02 5.09450012207031250e+02 9.24430007934570313e+01,
              5.10357696533203125e+02 5.09450012207031250e+02 9.24430007934570313e+01,
              5.09361572265625000e+02 5.09799987792968750e+02 9.24430007934570313e+01,
              5.10635772705078125e+02 5.09799987792968750e+02 9.24430007934570313e+01,
              5.09350158691406250e+02 5.10149993896484375e+02 9.24430007934570313e+01,
              5.10647186279296875e+02 5.10149993896484375e+02 9.24430007934570313e+01,
              5.09564147949218750e+02 5.10500000000000000e+02 9.24430007934570313e+01,
              5.09666656494140625e+02 5.10586791992187500e+02 9.24430007934570313e+01,
              5.10000000000000000e+02 5.10695129394531250e+02 9.24430007934570313e+01,
              5.10432067871093750e+02 5.10500000000000000e+02 9.24430007934570313e+01,
              5.10333343505859375e+02 5.10584197998046875e+02 9.24430007934570313e+01,
              5.09666656494140625e+02 5.09426879882812500e+02 9.26719970703125000e+01,
              5.10000000000000000e+02 5.09311401367187500e+02 9.26719970703125000e+01,
              5.10333343505859375e+02 5.09429565429687500e+02 9.26719970703125000e+01,
              5.09637817382812500e+02 5.09450012207031250e+02 9.26719970703125000e+01,
              5.09666656494140625e+02 5.09450012207031250e+02 9.27199020385742188e+01,
              5.10358673095703125e+02 5.09450012207031250e+02 9.26719970703125000e+01,
              5.10333343505859375e+02 5.09450012207031250e+02 9.27144088745117188e+01,
              5.09361053466796875e+02 5.09799987792968750e+02 9.26719970703125000e+01,
              5.10636230468750000e+02 5.09799987792968750e+02 9.26719970703125000e+01,
              5.09349670410156250e+02 5.10149993896484375e+02 9.26719970703125000e+01,
              5.10647613525390625e+02 5.10149993896484375e+02 9.26719970703125000e+01,
              5.09563171386718750e+02 5.10500000000000000e+02 9.26719970703125000e+01,
              5.09666656494140625e+02 5.10587615966796875e+02 9.26719970703125000e+01,
              5.09666656494140625e+02 5.10500000000000000e+02 9.28441619873046875e+01,
              5.10000000000000000e+02 5.10695678710937500e+02 9.26719970703125000e+01,
              5.10432983398437500e+02 5.10500000000000000e+02 9.26719970703125000e+01,
              5.10333343505859375e+02 5.10584960937500000e+02 9.26719970703125000e+01,
              5.10333343505859375e+02 5.10500000000000000e+02 9.28390579223632813e+01,
              5.10000000000000000e+02 5.09415954589843750e+02 9.29010009765625000e+01,
              5.09903808593750000e+02 5.09450012207031250e+02 9.29010009765625000e+01,
              5.09666656494140625e+02 5.09523040771484375e+02 9.29010009765625000e+01,
              5.10094421386718750e+02 5.09450012207031250e+02 9.29010009765625000e+01,
              5.10000000000000000e+02 5.09450012207031250e+02 9.29342193603515625e+01,
              5.10333343505859375e+02 5.09525085449218750e+02 9.29010009765625000e+01,
              5.09435180664062500e+02 5.09799987792968750e+02 9.29010009765625000e+01,
              5.09666656494140625e+02 5.09799987792968750e+02 9.30782089233398438e+01,
              5.10000000000000000e+02 5.09799987792968750e+02 9.31209335327148438e+01,
              5.10562042236328125e+02 5.09799987792968750e+02 9.29010009765625000e+01,
              5.10333343505859375e+02 5.09799987792968750e+02 9.30771636962890625e+01,
              5.09417755126953125e+02 5.10149993896484375e+02 9.29010009765625000e+01,
              5.09666656494140625e+02 5.10473693847656250e+02 9.29010009765625000e+01,
              5.09666656494140625e+02 5.10149993896484375e+02 9.30901107788085938e+01,
              5.10000000000000000e+02 5.10149993896484375e+02 9.31145248413085938e+01,
              5.10579528808593750e+02 5.10149993896484375e+02 9.29010009765625000e+01,
              5.10333343505859375e+02 5.10471466064453125e+02 9.29010009765625000e+01,
              5.10333343505859375e+02 5.10149993896484375e+02 9.30891723632812500e+01,
              5.09737243652343750e+02 5.10500000000000000e+02 9.29010009765625000e+01,
              5.10258178710937500e+02 5.10500000000000000e+02 9.29010009765625000e+01,
              5.10000000000000000e+02 5.10601318359375000e+02 9.29010009765625000e+01,
              5.10000000000000000e+02 5.10500000000000000e+02 9.29935913085937500e+01,
              5.15000000000000000e+02 5.11899993896484375e+02 9.39672241210937500e+01,
              5.15000000000000000e+02 5.12250000000000000e+02 9.40076293945312500e+01,
              5.15000000000000000e+02 5.11671600341796875e+02 9.40459976196289063e+01,
              5.14712280273437500e+02 5.11899993896484375e+02 9.40459976196289063e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 9.40885391235351563e+01,
              5.15285583496093750e+02 5.11899993896484375e+02 9.40459976196289063e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 9.40908584594726563e+01,
              5.14865478515625000e+02 5.12250000000000000e+02 9.40459976196289063e+01,
              5.14666687011718750e+02 5.12250000000000000e+02 9.41891403198242188e+01,
              5.15133300781250000e+02 5.12250000000000000e+02 9.40459976196289063e+01,
              5.15000000000000000e+02 5.12330444335937500e+02 9.40459976196289063e+01,
              5.15333312988281250e+02 5.12250000000000000e+02 9.41910705566406250e+01,
              5.14666687011718750e+02 5.11695251464843750e+02 9.42750015258789063e+01,
              5.15000000000000000e+02 5.11550201416015625e+02 9.42750015258789063e+01,
              5.15333312988281250e+02 5.11699584960937500e+02 9.42750015258789063e+01,
              5.14515136718750000e+02 5.11899993896484375e+02 9.42750015258789063e+01,
              5.15481140136718750e+02 5.11899993896484375e+02 9.42750015258789063e+01,
              5.14598937988281250e+02 5.12250000000000000e+02 9.42750015258789063e+01,
              5.14666687011718750e+02 5.12325805664062500e+02 9.42750015258789063e+01,
              5.15000000000000000e+02 5.12507568359375000e+02 9.42750015258789063e+01,
              5.15398803710937500e+02 5.12250000000000000e+02 9.42750015258789063e+01,
              5.15333312988281250e+02 5.12324157714843750e+02 9.42750015258789063e+01,
              5.14666687011718750e+02 5.11732849121093750e+02 9.45039978027343750e+01,
              5.15000000000000000e+02 5.11560607910156250e+02 9.45039978027343750e+01,
              5.15333312988281250e+02 5.11737274169921875e+02 9.45039978027343750e+01,
              5.14543579101562500e+02 5.11899993896484375e+02 9.45039978027343750e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 9.46152648925781250e+01,
              5.15000000000000000e+02 5.11899993896484375e+02 9.46940383911132813e+01,
              5.15452697753906250e+02 5.11899993896484375e+02 9.45039978027343750e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 9.46133956909179688e+01,
              5.14634704589843750e+02 5.12250000000000000e+02 9.45039978027343750e+01,
              5.14666687011718750e+02 5.12285827636718750e+02 9.45039978027343750e+01,
              5.14666687011718750e+02 5.12250000000000000e+02 9.45340957641601563e+01,
              5.15000000000000000e+02 5.12487304687500000e+02 9.45039978027343750e+01,
              5.15000000000000000e+02 5.12250000000000000e+02 9.46832885742187500e+01,
              5.15363220214843750e+02 5.12250000000000000e+02 9.45039978027343750e+01,
              5.15333312988281250e+02 5.12283996582031250e+02 9.45039978027343750e+01,
              5.15333312988281250e+02 5.12250000000000000e+02 9.45325317382812500e+01,
              5.04000000000000000e+02 5.07700012207031250e+02 9.51782379150390625e+01,
              5.04000000000000000e+02 5.07662597656250000e+02 9.51910018920898438e+01,
              5.03916992187500000e+02 5.07700012207031250e+02 9.51910018920898438e+01,
              5.03666656494140625e+02 5.07700012207031250e+02 9.52442779541015625e+01,
              5.04083618164062500e+02 5.07700012207031250e+02 9.51910018920898438e+01,
              5.04000000000000000e+02 5.07720977783203125e+02 9.51910018920898438e+01,
              5.04333343505859375e+02 5.07700012207031250e+02 9.52439041137695313e+01,
              5.03666656494140625e+02 5.08049987792968750e+02 9.51917877197265625e+01,
              5.04000000000000000e+02 5.08049987792968750e+02 9.52323684692382813e+01,
              5.04333343505859375e+02 5.08049987792968750e+02 9.51911239624023438e+01,
              5.03666656494140625e+02 5.08399993896484375e+02 9.53139266967773438e+01,
              5.04000000000000000e+02 5.08399993896484375e+02 9.52199020385742188e+01,
              5.04333343505859375e+02 5.08399993896484375e+02 9.53106079101562500e+01,
              5.03666656494140625e+02 5.07461730957031250e+02 9.54199981689453125e+01,
              5.04000000000000000e+02 5.07368713378906250e+02 9.54199981689453125e+01,
              5.04000000000000000e+02 5.07350006103515625e+02 9.54914398193359375e+01,
              5.04333343505859375e+02 5.07461822509765625e+02 9.54199981689453125e+01,
              5.03443969726562500e+02 5.07700012207031250e+02 9.54199981689453125e+01,
              5.04557373046875000e+02 5.07700012207031250e+02 9.54199981689453125e+01,
              5.03374176025390625e+02 5.08049987792968750e+02 9.54199981689453125e+01,
              5.03333343505859375e+02 5.08049987792968750e+02 9.55746917724609375e+01,
              5.04628540039062500e+02 5.08049987792968750e+02 9.54199981689453125e+01,
              5.04666656494140625e+02 5.08049987792968750e+02 9.55640640258789063e+01,
              5.03535247802734375e+02 5.08399993896484375e+02 9.54199981689453125e+01,
              5.03666656494140625e+02 5.08521759033203125e+02 9.54199981689453125e+01,
              5.04000000000000000e+02 5.08637939453125000e+02 9.54199981689453125e+01,
              5.04471679687500000e+02 5.08399993896484375e+02 9.54199981689453125e+01,
              5.04333343505859375e+02 5.08526916503906250e+02 9.54199981689453125e+01,
              5.04000000000000000e+02 5.07277191162109375e+02 9.56490020751953125e+01,
              5.03769775390625000e+02 5.07350006103515625e+02 9.56490020751953125e+01,
              5.03666656494140625e+02 5.07374450683593750e+02 9.56490020751953125e+01,
              5.04229095458984375e+02 5.07350006103515625e+02 9.56490020751953125e+01,
              5.04333343505859375e+02 5.07374847412109375e+02 9.56490020751953125e+01,
              5.03361572265625000e+02 5.07700012207031250e+02 9.56490020751953125e+01,
              5.03333343505859375e+02 5.07894134521484375e+02 9.56490020751953125e+01,
              5.04639404296875000e+02 5.07700012207031250e+02 9.56490020751953125e+01,
              5.04666656494140625e+02 5.07879394531250000e+02 9.56490020751953125e+01,
              5.03300872802734375e+02 5.08049987792968750e+02 9.56490020751953125e+01,
              5.03333343505859375e+02 5.08129791259765625e+02 9.56490020751953125e+01,
              5.04704376220703125e+02 5.08049987792968750e+02 9.56490020751953125e+01,
              5.04666656494140625e+02 5.08144317626953125e+02 9.56490020751953125e+01,
              5.03430023193359375e+02 5.08399993896484375e+02 9.56490020751953125e+01,
              5.03666656494140625e+02 5.08620056152343750e+02 9.56490020751953125e+01,
              5.04000000000000000e+02 5.08705383300781250e+02 9.56490020751953125e+01,
              5.04577178955078125e+02 5.08399993896484375e+02 9.56490020751953125e+01,
              5.04333343505859375e+02 5.08625122070312500e+02 9.56490020751953125e+01,
              5.04000000000000000e+02 5.07278564453125000e+02 9.58779983520507813e+01,
              5.03773773193359375e+02 5.07350006103515625e+02 9.58779983520507813e+01,
              5.03666656494140625e+02 5.07375396728515625e+02 9.58779983520507813e+01,
              5.04225067138671875e+02 5.07350006103515625e+02 9.58779983520507813e+01,
              5.04000000000000000e+02 5.07350006103515625e+02 9.60258865356445313e+01,
              5.04333343505859375e+02 5.07375823974609375e+02 9.58779983520507813e+01,
              5.03362518310546875e+02 5.07700012207031250e+02 9.58779983520507813e+01,
              5.03333343505859375e+02 5.07900543212890625e+02 9.58779983520507813e+01,
              5.04638458251953125e+02 5.07700012207031250e+02 9.58779983520507813e+01,
              5.04666656494140625e+02 5.07885650634765625e+02 9.58779983520507813e+01,
              5.03302276611328125e+02 5.08049987792968750e+02 9.58779983520507813e+01,
              5.03333343505859375e+02 5.08126373291015625e+02 9.58779983520507813e+01,
              5.03333343505859375e+02 5.08049987792968750e+02 9.59463653564453125e+01,
              5.04702911376953125e+02 5.08049987792968750e+02 9.58779983520507813e+01,
              5.04666656494140625e+02 5.08140777587890625e+02 9.58779983520507813e+01,
              5.04666656494140625e+02 5.08049987792968750e+02 9.59564208984375000e+01,
              5.03431365966796875e+02 5.08399993896484375e+02 9.58779983520507813e+01,
              5.03666656494140625e+02 5.08618743896484375e+02 9.58779983520507813e+01,
              5.04000000000000000e+02 5.08704528808593750e+02 9.58779983520507813e+01,
              5.04575836181640625e+02 5.08399993896484375e+02 9.58779983520507813e+01,
              5.04333343505859375e+02 5.08623809814453125e+02 9.58779983520507813e+01,
              5.09666656494140625e+02 5.09799987792968750e+02 9.60841445922851563e+01,
              5.10000000000000000e+02 5.09799987792968750e+02 9.59134521484375000e+01,
              5.10333343505859375e+02 5.09799987792968750e+02 9.60857696533203125e+01,
              5.09666656494140625e+02 5.10149993896484375e+02 9.60467300415039063e+01,
              5.10000000000000000e+02 5.10149993896484375e+02 9.59065322875976563e+01,
              5.10333343505859375e+02 5.10149993896484375e+02 9.60480957031250000e+01,
              5.03666656494140625e+02 5.07469909667968750e+02 9.61070022583007813e+01,
              5.04000000000000000e+02 5.07373321533203125e+02 9.61070022583007813e+01,
              5.04333343505859375e+02 5.07470031738281250e+02 9.61070022583007813e+01,
              5.03451751708984375e+02 5.07700012207031250e+02 9.61070022583007813e+01,
              5.03666656494140625e+02 5.07700012207031250e+02 9.62655792236328125e+01,
              5.04000000000000000e+02 5.07700012207031250e+02 9.63232879638671875e+01,
              5.04549499511718750e+02 5.07700012207031250e+02 9.61070022583007813e+01,
              5.04333343505859375e+02 5.07700012207031250e+02 9.62658843994140625e+01,
              5.03379577636718750e+02 5.08049987792968750e+02 9.61070022583007813e+01,
              5.03666656494140625e+02 5.08049987792968750e+02 9.63134994506835938e+01,
              5.04000000000000000e+02 5.08049987792968750e+02 9.62878494262695313e+01,
              5.04623138427734375e+02 5.08049987792968750e+02 9.61070022583007813e+01,
              5.04333343505859375e+02 5.08049987792968750e+02 9.63141021728515625e+01,
              5.03545043945312500e+02 5.08399993896484375e+02 9.61070022583007813e+01,
              5.03666656494140625e+02 5.08512451171875000e+02 9.61070022583007813e+01,
              5.03666656494140625e+02 5.08399993896484375e+02 9.61997528076171875e+01,
              5.04000000000000000e+02 5.08630950927734375e+02 9.61070022583007813e+01,
              5.04000000000000000e+02 5.08399993896484375e+02 9.62879409790039063e+01,
              5.04461761474609375e+02 5.08399993896484375e+02 9.61070022583007813e+01,
              5.04333343505859375e+02 5.08517578125000000e+02 9.61070022583007813e+01,
              5.04333343505859375e+02 5.08399993896484375e+02 9.62028961181640625e+01,
              5.09666656494140625e+02 5.09773773193359375e+02 9.61070022583007813e+01,
              5.10000000000000000e+02 5.09542938232421875e+02 9.61070022583007813e+01,
              5.10333343505859375e+02 5.09775695800781250e+02 9.61070022583007813e+01,
              5.09644378662109375e+02 5.09799987792968750e+02 9.61070022583007813e+01,
              5.09666656494140625e+02 5.09799987792968750e+02 9.62940521240234375e+01,
              5.10353942871093750e+02 5.09799987792968750e+02 9.61070022583007813e+01,
              5.10333343505859375e+02 5.09799987792968750e+02 9.62809219360351563e+01,
              5.09606811523437500e+02 5.10149993896484375e+02 9.61070022583007813e+01,
              5.09666656494140625e+02 5.10225433349609375e+02 9.61070022583007813e+01,
              5.10000000000000000e+02 5.10446380615234375e+02 9.61070022583007813e+01,
              5.10391632080078125e+02 5.10149993896484375e+02 9.61070022583007813e+01,
              5.10333343505859375e+02 5.10223663330078125e+02 9.61070022583007813e+01,
              5.10000000000000000e+02 5.09557800292968750e+02 9.63359985351562500e+01,
              5.09670196533203125e+02 5.09799987792968750e+02 9.63359985351562500e+01,
              5.09666656494140625e+02 5.09843322753906250e+02 9.63359985351562500e+01,
              5.10328735351562500e+02 5.09799987792968750e+02 9.63359985351562500e+01,
              5.10000000000000000e+02 5.09799987792968750e+02 9.64921569824218750e+01,
              5.10333343505859375e+02 5.09856719970703125e+02 9.63359985351562500e+01,
              5.09632568359375000e+02 5.10149993896484375e+02 9.63359985351562500e+01,
              5.09666656494140625e+02 5.10193145751953125e+02 9.63359985351562500e+01,
              5.09666656494140625e+02 5.10149993896484375e+02 9.63652420043945313e+01,
              5.10000000000000000e+02 5.10435150146484375e+02 9.63359985351562500e+01,
              5.10000000000000000e+02 5.10149993896484375e+02 9.65022811889648438e+01,
              5.10365905761718750e+02 5.10149993896484375e+02 9.63359985351562500e+01,
              5.10333343505859375e+02 5.10191314697265625e+02 9.63359985351562500e+01,
              5.10333343505859375e+02 5.10149993896484375e+02 9.63640213012695313e+01,
              5.11000000000000000e+02 5.03850006103515625e+02 9.73789520263671875e+01,
              5.11000000000000000e+02 5.04200012207031250e+02 9.74355392456054688e+01,
              5.15000000000000000e+02 5.11899993896484375e+02 9.73447570800781250e+01,
              5.11000000000000000e+02 5.03685791015625000e+02 9.74810028076171875e+01,
              5.10809600830078125e+02 5.03850006103515625e+02 9.74810028076171875e+01,
              5.11190643310546875e+02 5.03850006103515625e+02 9.74810028076171875e+01,
              5.11000000000000000e+02 5.03850006103515625e+02 9.75970840454101563e+01,
              5.10912628173828125e+02 5.04200012207031250e+02 9.74810028076171875e+01,
              5.11087463378906250e+02 5.04200012207031250e+02 9.74810028076171875e+01,
              5.11000000000000000e+02 5.04267761230468750e+02 9.74810028076171875e+01,
              5.11000000000000000e+02 5.04200012207031250e+02 9.75486679077148438e+01,
              5.15000000000000000e+02 5.11660827636718750e+02 9.74810028076171875e+01,
              5.14745605468750000e+02 5.11899993896484375e+02 9.74810028076171875e+01,
              5.15253723144531250e+02 5.11899993896484375e+02 9.74810028076171875e+01,
              5.15000000000000000e+02 5.12227416992187500e+02 9.74810028076171875e+01,
              5.15000000000000000e+02 5.11899993896484375e+02 9.76180572509765625e+01,
              5.11000000000000000e+02 5.03850006103515625e+02 9.78302993774414063e+01,
              5.11000000000000000e+02 5.04200012207031250e+02 9.78282775878906250e+01,
              5.15000000000000000e+02 5.11899993896484375e+02 9.78447647094726563e+01,
              5.15000000000000000e+02 5.12250000000000000e+02 9.78434295654296875e+01,
              5.11000000000000000e+02 5.03639892578125000e+02 9.79390029907226563e+01,
              5.10696838378906250e+02 5.03850006103515625e+02 9.79390029907226563e+01,
              5.10666656494140625e+02 5.03850006103515625e+02 9.79991989135742188e+01,
              5.11304107666015625e+02 5.03850006103515625e+02 9.79390029907226563e+01,
              5.11333343505859375e+02 5.03850006103515625e+02 9.79971237182617188e+01,
              5.10738250732421875e+02 5.04200012207031250e+02 9.79390029907226563e+01,
              5.10666656494140625e+02 5.04200012207031250e+02 9.80923995971679688e+01,
              5.11262298583984375e+02 5.04200012207031250e+02 9.79390029907226563e+01,
              5.11000000000000000e+02 5.04365020751953125e+02 9.79390029907226563e+01,
              5.11333343505859375e+02 5.04200012207031250e+02 9.80907974243164063e+01,
              5.15000000000000000e+02 5.11656402587890625e+02 9.79390029907226563e+01,
              5.14676696777343750e+02 5.11899993896484375e+02 9.79390029907226563e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 9.79582366943359375e+01,
              5.15321350097656250e+02 5.11899993896484375e+02 9.79390029907226563e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 9.79620513916015625e+01,
              5.14803771972656250e+02 5.12250000000000000e+02 9.79390029907226563e+01,
              5.15195312500000000e+02 5.12250000000000000e+02 9.79390029907226563e+01,
              5.15000000000000000e+02 5.12363891601562500e+02 9.79390029907226563e+01,
              5.10666656494140625e+02 5.03772796630859375e+02 9.81679992675781250e+01,
              5.11000000000000000e+02 5.03550292968750000e+02 9.81679992675781250e+01,
              5.11333343505859375e+02 5.03771697998046875e+02 9.81679992675781250e+01,
              5.10605468750000000e+02 5.03850006103515625e+02 9.81679992675781250e+01,
              5.10666656494140625e+02 5.03850006103515625e+02 9.82362365722656250e+01,
              5.11000000000000000e+02 5.03850006103515625e+02 9.83948440551757813e+01,
              5.11395355224609375e+02 5.03850006103515625e+02 9.81679992675781250e+01,
              5.11333343505859375e+02 5.03850006103515625e+02 9.82370071411132813e+01,
              5.10642242431640625e+02 5.04200012207031250e+02 9.81679992675781250e+01,
              5.10666656494140625e+02 5.04228912353515625e+02 9.81679992675781250e+01,
              5.10666656494140625e+02 5.04200012207031250e+02 9.81955718994140625e+01,
              5.11000000000000000e+02 5.04461639404296875e+02 9.81679992675781250e+01,
              5.11000000000000000e+02 5.04200012207031250e+02 9.83877868652343750e+01,
              5.11358276367187500e+02 5.04200012207031250e+02 9.81679992675781250e+01,
              5.11333343505859375e+02 5.04229492187500000e+02 9.81679992675781250e+01,
              5.11333343505859375e+02 5.04200012207031250e+02 9.81961364746093750e+01,
              5.14666687011718750e+02 5.11790191650390625e+02 9.81679992675781250e+01,
              5.15000000000000000e+02 5.11574310302734375e+02 9.81679992675781250e+01,
              5.15333312988281250e+02 5.11792907714843750e+02 9.81679992675781250e+01,
              5.14585205078125000e+02 5.11899993896484375e+02 9.81679992675781250e+01,
              5.14666687011718750e+02 5.12176086425781250e+02 9.81679992675781250e+01,
              5.14666687011718750e+02 5.11899993896484375e+02 9.82572631835937500e+01,
              5.15000000000000000e+02 5.11899993896484375e+02 9.83857269287109375e+01,
              5.15412658691406250e+02 5.11899993896484375e+02 9.81679992675781250e+01,
              5.15333312988281250e+02 5.12173522949218750e+02 9.81679992675781250e+01,
              5.15333312988281250e+02 5.11899993896484375e+02 9.82558288574218750e+01,
              5.14688598632812500e+02 5.12250000000000000e+02 9.81679992675781250e+01,
              5.15310852050781250e+02 5.12250000000000000e+02 9.81679992675781250e+01,
              5.15000000000000000e+02 5.12463012695312500e+02 9.81679992675781250e+01,
              5.15000000000000000e+02 5.12250000000000000e+02 9.83632125854492188e+01,
              "
            />
            <Normal
              DEF="VTKnormals0021"
              vector="
              3.62560502253472805e-03 -3.45004290342330933e-01 -9.38594102859497070e-01,
              3.36194410920143127e-03 2.12724924087524414e-01 -9.77106332778930664e-01,
              3.94577160477638245e-03 -6.30665421485900879e-01 -7.76044785976409912e-01,
              -4.90575700998306274e-01 -4.20344442129135132e-01 -7.63312518596649170e-01,
              -9.30942356586456299e-01 -3.63711655139923096e-01 -3.25610674917697906e-02,
              4.89507228136062622e-01 -4.19361233711242676e-01 -7.64538288116455078e-01,
              9.32663381099700928e-01 -3.60225856304168701e-01 -1.93988718092441559e-02,
              -6.31322562694549561e-01 2.74772524833679199e-01 -7.25211620330810547e-01,
              -9.36320006847381592e-01 2.50343173742294312e-01 -2.46238052845001221e-01,
              6.27324759960174561e-01 2.74275243282318115e-01 -7.28859901428222656e-01,
              3.92353022471070290e-03 6.03885173797607422e-01 -7.97061681747436523e-01,
              9.41417992115020752e-01 2.48050525784492493e-01 -2.28480026125907898e-01,
              -9.24248456954956055e-01 -3.76811623573303223e-01 6.14636987447738647e-02,
              3.28087969683110714e-03 -9.99476611614227295e-01 3.21826823055744171e-02,
              9.26583945751190186e-01 -3.71377974748611450e-01 5.93346394598484039e-02,
              -9.34895694255828857e-01 -3.49713981151580811e-01 6.05814866721630096e-02,
              -9.24472451210021973e-01 -3.54420393705368042e-01 1.40488103032112122e-01,
              9.35491681098937988e-01 -3.48462045192718506e-01 5.85620403289794922e-02,
              9.27162468433380127e-01 -3.52666079998016357e-01 1.26476734876632690e-01,
              -9.74405646324157715e-01 2.18880146741867065e-01 5.12355901300907135e-02,
              -9.49662506580352783e-01 3.08429241180419922e-01 5.48865869641304016e-02,
              -9.28887188434600830e-01 2.25113615393638611e-01 2.94096022844314575e-01,
              2.99593294039368629e-03 9.99633371829986572e-01 2.69098766148090363e-02,
              9.74624216556549072e-01 2.18353331089019775e-01 4.92892488837242126e-02,
              9.51604962348937988e-01 3.02757471799850464e-01 5.27820438146591187e-02,
              9.33237850666046143e-01 2.24754899740219116e-01 2.80271857976913452e-01,
              2.32292618602514267e-03 -5.50439417362213135e-01 8.34871888160705566e-01,
              -3.61383914947509766e-01 -3.63533169031143188e-01 8.58629882335662842e-01,
              3.62921923398971558e-01 -3.63982468843460083e-01 8.57790470123291016e-01,
              1.54585682321339846e-03 -2.76290595531463623e-01 9.61072921752929688e-01,
              -5.28787076473236084e-01 2.20303818583488464e-01 8.19664835929870605e-01,
              5.29175519943237305e-01 2.20702603459358215e-01 8.19306790828704834e-01,
              2.35355156473815441e-03 5.44863760471343994e-01 8.38521301746368408e-01,
              1.23167550191283226e-03 1.43990769982337952e-01 9.89578247070312500e-01,
              1.03423791006207466e-03 1.84739857912063599e-01 -9.82786893844604492e-01,
              1.96677282452583313e-01 3.39310728013515472e-02 -9.79880988597869873e-01,
              -1.95318669080734253e-01 3.28978970646858215e-02 -9.80187892913818359e-01,
              -2.94363944703945890e-05 -1.59159302711486816e-01 -9.87252891063690186e-01,
              1.65774987544864416e-03 -6.78451219573616982e-03 -9.99975621700286865e-01,
              3.25007946230471134e-03 -8.05404722690582275e-01 -5.92716395854949951e-01,
              -2.45944738388061523e-01 -2.06203907728195190e-01 -9.47096168994903564e-01,
              -2.36421033740043640e-01 -2.09303975105285645e-01 -9.48839783668518066e-01,
              -2.58304983377456665e-01 -2.22866892814636230e-01 -9.40004706382751465e-01,
              2.43320673704147339e-01 -1.98278203606605530e-01 -9.49463427066802979e-01,
              7.86961172707378864e-04 1.03386647999286652e-01 -9.94640946388244629e-01,
              2.27277621626853943e-01 -2.03296020627021790e-01 -9.52373683452606201e-01,
              2.63915687799453735e-01 -2.25933179259300232e-01 -9.37711417675018311e-01,
              3.82166802883148193e-02 4.24268245697021484e-02 -9.98368382453918457e-01,
              -8.40165376663208008e-01 5.80594316124916077e-02 -5.39213478565216064e-01,
              1.13356404006481171e-01 3.12352012842893600e-02 -9.93063271045684814e-01,
              -1.59936025738716125e-02 1.66163906455039978e-01 -9.85968410968780518e-01,
              -1.12906709313392639e-01 3.05322557687759399e-02 -9.93136346340179443e-01,
              2.65967009909218177e-05 -1.17887914180755615e-01 -9.93026912212371826e-01,
              2.76891805697232485e-04 2.37661190330982208e-02 -9.99717533588409424e-01,
              -3.98144051432609558e-02 4.03927192091941833e-02 -9.98390316963195801e-01,
              1.50248780846595764e-02 1.63416713476181030e-01 -9.86442744731903076e-01,
              8.44389259815216064e-01 5.26213124394416809e-02 -5.33139526844024658e-01,
              -6.45702034235000610e-02 -3.48192378878593445e-02 -9.97305512428283691e-01,
              -3.27639013528823853e-01 4.10073578357696533e-01 -8.51171135902404785e-01,
              6.44024387001991272e-02 -3.54910753667354584e-02 -9.97292697429656982e-01,
              -1.80495611857622862e-04 -1.03344716131687164e-01 -9.94645595550537109e-01,
              3.27630519866943359e-01 4.07816499471664429e-01 -8.52258145809173584e-01,
              3.45227541401982307e-03 -8.78732323646545410e-01 -4.77302402257919312e-01,
              -2.53746688365936279e-01 -8.16581904888153076e-01 -5.18465638160705566e-01,
              -3.60346198081970215e-01 -7.63090550899505615e-01 -5.36510407924652100e-01,
              -3.51231247186660767e-01 -8.09211373329162598e-01 -4.70970898866653442e-01,
              2.49764770269393921e-01 -8.19600582122802734e-01 -5.15628218650817871e-01,
              3.70024114847183228e-01 -7.58912026882171631e-01 -5.35849511623382568e-01,
              3.59663903713226318e-01 -8.12264561653137207e-01 -4.59203809499740601e-01,
              -7.93973982334136963e-01 -3.00812840461730957e-01 -5.28315186500549316e-01,
              -8.51914882659912109e-01 -1.37898012995719910e-01 -5.05198180675506592e-01,
              -8.45403194427490234e-01 -2.93075770139694214e-01 -4.46542292833328247e-01,
              7.92287647724151611e-01 -3.06865692138671875e-01 -5.27364850044250488e-01,
              8.57692778110504150e-01 -1.19314126670360565e-01 -5.00127255916595459e-01,
              8.52971673011779785e-01 -2.97711640596389771e-01 -4.28727358579635620e-01,
              -8.77774953842163086e-01 5.87383471429347992e-02 -4.75458711385726929e-01,
              -8.57885420322418213e-01 1.51032686233520508e-01 -4.91143316030502319e-01,
              8.78737330436706543e-01 5.27444630861282349e-02 -4.74382519721984863e-01,
              8.60305905342102051e-01 1.39732584357261658e-01 -4.90253597497940063e-01,
              -6.71969056129455566e-01 4.97213780879974365e-01 -5.48849701881408691e-01,
              -8.52945506572723389e-01 4.58555638790130615e-01 -2.49420642852783203e-01,
              -3.95537108182907104e-01 7.39985048770904541e-01 -5.44033527374267578e-01,
              -2.00132746249437332e-03 8.91042172908782959e-01 -4.53916072845458984e-01,
              6.73677980899810791e-01 4.91513073444366455e-01 -5.51881194114685059e-01,
              3.92607688903808594e-01 7.39421367645263672e-01 -5.46914339065551758e-01,
              8.55484664440155029e-01 4.50944393873214722e-01 -2.54548877477645874e-01,
              -2.77300854213535786e-03 9.56033289432525635e-01 -2.93245106935501099e-01,
              -3.63246083259582520e-01 -9.05278384685516357e-01 -2.20280051231384277e-01,
              4.05181525275111198e-03 -9.79992330074310303e-01 -1.98994070291519165e-01,
              3.71337860822677612e-01 -9.02222692966461182e-01 -2.19322711229324341e-01,
              -5.00586211681365967e-01 -8.35405588150024414e-01 -2.26960152387619019e-01,
              -8.72826695442199707e-01 -4.34232801198959351e-01 -2.22745239734649658e-01,
              4.99342352151870728e-01 -8.36572289466857910e-01 -2.25397542119026184e-01,
              8.77514421939849854e-01 -4.25747096538543701e-01 -2.20698654651641846e-01,
              -9.29703295230865479e-01 -2.99577087163925171e-01 -2.14255318045616150e-01,
              9.28496599197387695e-01 -3.04060667753219604e-01 -2.13169321417808533e-01,
              -9.78592455387115479e-01 6.43419474363327026e-02 -1.95491462945938110e-01,
              9.78994965553283691e-01 5.62396794557571411e-02 -1.95974394679069519e-01,
              -8.62362086772918701e-01 4.58844125270843506e-01 -2.13994532823562622e-01,
              -8.54227721691131592e-01 4.73548144102096558e-01 -2.14585974812507629e-01,
              -3.75906050205230713e-01 9.02372479438781738e-01 -2.10756987333297729e-01,
              3.70252907276153564e-01 9.04171764850616455e-01 -2.13040366768836975e-01,
              8.65749776363372803e-01 4.51043903827667236e-01 -2.16879487037658691e-01,
              8.56885552406311035e-01 4.67325031757354736e-01 -2.17610880732536316e-01,
              -1.53244599699974060e-01 9.68260884284973145e-01 -1.97451204061508179e-01,
              1.52615785598754883e-01 9.68047678470611572e-01 -1.98977574706077576e-01,
              -3.32049001008272171e-03 9.81976687908172607e-01 -1.88972935080528259e-01,
              -3.66362959146499634e-01 -9.28705096244812012e-01 5.73151819407939911e-02,
              4.12249611690640450e-03 -9.98675525188446045e-01 5.12857921421527863e-02,
              3.74539792537689209e-01 -9.25481021404266357e-01 5.66109940409660339e-02,
              -5.44590473175048828e-01 -8.36532950401306152e-01 6.02816045284271240e-02,
              -8.76092851161956787e-01 -4.78407263755798340e-01 5.98985627293586731e-02,
              5.42172074317932129e-01 -8.38190674781799316e-01 5.90409450232982635e-02,
              8.81240367889404297e-01 -4.69077795743942261e-01 5.81499002873897552e-02,
              -9.51871752738952637e-01 -3.01121711730957031e-01 5.71480877697467804e-02,
              9.50353562831878662e-01 -3.06196630001068115e-01 5.54233416914939880e-02,
              -9.96417045593261719e-01 6.60360753536224365e-02 5.28426691889762878e-02,
              9.97045993804931641e-01 5.72763830423355103e-02 5.11736571788787842e-02,
              -8.84169578552246094e-01 4.63389664888381958e-01 5.92803508043289185e-02,
              -8.61405909061431885e-01 5.04378259181976318e-01 5.98537586629390717e-02,
              -8.58124613761901855e-01 4.61188077926635742e-01 2.25671708583831787e-01,
              -3.66828680038452148e-01 9.28513467311859131e-01 5.74408136308193207e-02,
              3.59963238239288330e-01 9.31234896183013916e-01 5.68155720829963684e-02,
              8.88475239276885986e-01 4.55260425806045532e-01 5.78760430216789246e-02,
              8.64246606826782227e-01 4.99647110700607300e-01 5.85712604224681854e-02,
              8.60175371170043945e-01 4.53342616558074951e-01 2.33621045947074890e-01,
              -2.42921799421310425e-01 9.68444287776947021e-01 5.57192601263523102e-02,
              2.46378406882286072e-01 9.67587471008300781e-01 5.54266050457954407e-02,
              -3.91997070983052254e-03 9.98658597469329834e-01 5.16294054687023163e-02,
              -3.59554946422576904e-01 -8.71973097324371338e-01 3.32239598035812378e-01,
              3.60351568087935448e-03 -9.54506576061248779e-01 2.98168092966079712e-01,
              3.66761922836303711e-01 -8.69785249233245850e-01 3.30089867115020752e-01,
              -4.41375017166137695e-01 -8.30701112747192383e-01 3.39298993349075317e-01,
              -8.66505146026611328e-01 -3.71752709150314331e-01 3.33119809627532959e-01,
              -3.55981230735778809e-01 -8.19416105747222900e-01 4.49260026216506958e-01,
              2.73433933034539223e-03 -8.16084146499633789e-01 5.77926695346832275e-01,
              4.41724181175231934e-01 -8.31791818141937256e-01 3.36157917976379395e-01,
              3.63028079271316528e-01 -8.21334660053253174e-01 4.40022677183151245e-01,
              8.70354115962982178e-01 -3.66846144199371338e-01 3.28492909669876099e-01,
              -8.98129105567932129e-01 -2.95575290918350220e-01 3.25575441122055054e-01,
              -8.54748725891113281e-01 -2.94051110744476318e-01 4.27713155746459961e-01,
              8.97943913936614990e-01 -3.00194978713989258e-01 3.21837991476058960e-01,
              8.59791576862335205e-01 -2.98600465059280396e-01 4.14241731166839600e-01,
              -9.52567040920257568e-01 6.34642690420150757e-02 2.97637909650802612e-01,
              -8.64938378334045410e-01 3.81184607744216919e-01 3.26465696096420288e-01,
              -8.53520095348358154e-01 5.94225004315376282e-02 5.17660498619079590e-01,
              9.53706979751586914e-01 5.56576587259769440e-02 2.95542299747467041e-01,
              8.66286337375640869e-01 3.77705007791519165e-01 3.26935708522796631e-01,
              8.56810927391052246e-01 5.34698143601417542e-02 5.12850821018218994e-01,
              -8.16007554531097412e-01 4.71688121557235718e-01 3.34128648042678833e-01,
              -3.88773441314697266e-01 8.59899342060089111e-01 3.30799490213394165e-01,
              8.20604145526885986e-01 4.63321089744567871e-01 3.34577977657318115e-01,
              3.82604837417602539e-01 8.62166166305541992e-01 3.32088977098464966e-01,
              -2.94903106987476349e-02 9.55993056297302246e-01 2.91903495788574219e-01,
              2.34985817223787308e-02 9.56077575683593750e-01 2.92170315980911255e-01,
              -3.55577887967228889e-03 9.57312464714050293e-01 2.89033263921737671e-01,
              -3.49132437258958817e-03 9.52879250049591064e-01 3.03329735994338989e-01,
              -3.88860613107681274e-01 -6.25242471694946289e-01 6.76652967929840088e-01,
              2.71955085918307304e-03 -8.13836693763732910e-01 5.81087291240692139e-01,
              3.95491927862167358e-01 -6.22913956642150879e-01 6.74955070018768311e-01,
              -6.72941207885742188e-01 -3.17845433950424194e-01 6.67925477027893066e-01,
              -3.02390575408935547e-01 -2.55151063203811646e-01 9.18399631977081299e-01,
              7.74329819250851870e-04 5.94656355679035187e-02 9.98230040073394775e-01,
              6.72801077365875244e-01 -3.22706341743469238e-01 6.65732145309448242e-01,
              3.06517213582992554e-01 -2.57901519536972046e-01 9.16260898113250732e-01,
              -8.13767194747924805e-01 5.68807609379291534e-02 5.78400850296020508e-01,
              7.51750869676470757e-03 3.89077663421630859e-02 9.99214529991149902e-01,
              1.05916275060735643e-04 1.85862481594085693e-02 9.99827265739440918e-01,
              8.15257668495178223e-01 5.15201613306999207e-02 5.76802074909210205e-01,
              -6.36127917096018791e-03 3.74372415244579315e-02 9.99278724193572998e-01,
              -5.44553995132446289e-01 5.01257061958312988e-01 6.72459900379180908e-01,
              -3.92199695110321045e-01 6.32585227489471436e-01 6.67843818664550781e-01,
              -3.52761656045913696e-01 4.46776747703552246e-01 8.22161614894866943e-01,
              -2.31078057549893856e-03 8.44798803329467773e-01 5.35079121589660645e-01,
              -8.61706037539988756e-04 1.95021450519561768e-01 9.80798602104187012e-01,
              5.45920729637145996e-01 4.96701240539550781e-01 6.74728393554687500e-01,
              3.88698548078536987e-01 6.32283866405487061e-01 6.70172035694122314e-01,
              3.50609987974166870e-01 4.43550527095794678e-01 8.24824571609497070e-01,
              -8.83150815963745117e-01 -2.11970403790473938e-01 -4.18465256690979004e-01,
              1.48514358443208039e-04 -1.32590234279632568e-01 -9.91170942783355713e-01,
              8.83338510990142822e-01 -2.11836963891983032e-01 -4.18136596679687500e-01,
              2.29356825002469122e-04 2.47077912092208862e-01 -9.68995571136474609e-01,
              -8.95510613918304443e-01 -2.37534999847412109e-01 -3.76348018646240234e-01,
              2.46885960223153234e-04 -8.98410439491271973e-01 -4.39156711101531982e-01,
              8.95597755908966064e-01 -2.37228304147720337e-01 -3.76334160566329956e-01,
              -9.04688000679016113e-01 -2.09380045533180237e-01 -3.71078938245773315e-01,
              -9.14784431457519531e-01 4.24973592162132263e-02 -4.01700615882873535e-01,
              9.04709875583648682e-01 -2.09249734878540039e-01 -3.71099233627319336e-01,
              9.14959847927093506e-01 4.05306704342365265e-02 -4.01504337787628174e-01,
              -8.22559595108032227e-01 3.66914987564086914e-01 -4.34475630521774292e-01,
              8.22425425052642822e-01 3.67096602916717529e-01 -4.34576243162155151e-01,
              4.34508430771529675e-04 8.79030346870422363e-01 -4.76765602827072144e-01,
              -9.17057394981384277e-01 -2.68005639314651489e-01 2.95260459184646606e-01,
              1.48418665048666298e-04 -9.42459523677825928e-01 3.34320157766342163e-01,
              9.17197227478027344e-01 -2.67816573381423950e-01 2.94997453689575195e-01,
              -9.34183120727539063e-01 -2.12064191699028015e-01 2.86933302879333496e-01,
              -8.99469494819641113e-01 3.02996426820755005e-01 3.14877390861511230e-01,
              -8.95545125007629395e-01 -2.18433305621147156e-01 3.87667208909988403e-01,
              5.50790828128810972e-05 -1.31028681993484497e-01 9.91378605365753174e-01,
              9.34297621250152588e-01 -2.11931228637695313e-01 2.86658525466918945e-01,
              9.00063514709472656e-01 3.01511198282241821e-01 3.14605653285980225e-01,
              8.95731210708618164e-01 -2.18315243721008301e-01 3.87303620576858521e-01,
              -8.81238698959350586e-01 3.50663453340530396e-01 3.16943973302841187e-01,
              8.81214737892150879e-01 3.50891619920730591e-01 3.16758096218109131e-01,
              3.73422371922060847e-04 9.30449962615966797e-01 3.66418719291687012e-01,
              1.28296946058981121e-04 2.33405053615570068e-01 9.72379565238952637e-01,
              3.06672445731237531e-04 -8.20859745144844055e-02 -9.96625185012817383e-01,
              -1.06709019746631384e-04 7.01036512851715088e-01 -7.13125348091125488e-01,
              1.34855706710368395e-03 -6.53725922107696533e-01 -7.56730198860168457e-01,
              -6.84474468231201172e-01 -1.44650742411613464e-01 -7.14542388916015625e-01,
              6.84403657913208008e-01 -1.45976752042770386e-01 -7.14340567588806152e-01,
              -5.45056238770484924e-02 7.28252410888671875e-01 -6.83138012886047363e-01,
              5.43794110417366028e-02 7.28185236454010010e-01 -6.83219671249389648e-01,
              -1.29986627143807709e-04 7.45704472064971924e-01 -6.66276812553405762e-01,
              1.54251523781567812e-03 -9.51742410659790039e-01 3.06894063949584961e-01,
              -9.61761295795440674e-01 -1.35827302932739258e-01 2.37836509943008423e-01,
              9.61487293243408203e-01 -1.37390762567520142e-01 2.38046035170555115e-01,
              3.11784824589267373e-04 -9.18152183294296265e-02 9.95775997638702393e-01,
              -3.27696561813354492e-01 8.89176070690155029e-01 3.19344431161880493e-01,
              3.28149139881134033e-01 8.88915121555328369e-01 3.19606095552444458e-01,
              -1.93896848941221833e-04 9.61587369441986084e-01 2.74499058723449707e-01,
              -9.04982734937220812e-05 7.53579914569854736e-01 6.57356262207031250e-01,
              -9.30733799934387207e-01 -2.26435989141464233e-01 -2.87160784006118774e-01,
              -6.01086707320064306e-04 -1.35889634490013123e-01 -9.90723788738250732e-01,
              9.29201245307922363e-01 -2.26255521178245544e-01 -2.92221665382385254e-01,
              -5.49428339581936598e-04 2.36704275012016296e-01 -9.71581578254699707e-01,
              -9.36419427394866943e-01 -1.34104639291763306e-01 -3.24244648218154907e-01,
              5.64476533327251673e-04 -7.88949877023696899e-02 -9.96882796287536621e-01,
              9.37719762325286865e-01 -1.35897636413574219e-01 -3.19708377122879028e-01,
              5.38812717422842979e-04 4.30121809244155884e-01 -9.02770698070526123e-01,
              -9.41266894340515137e-01 -2.57680356502532959e-01 -2.18214318156242371e-01,
              -1.69522478245198727e-03 -9.63471949100494385e-01 -2.67803907394409180e-01,
              9.40528631210327148e-01 -2.59529918432235718e-01 -2.19203308224678040e-01,
              -9.51513290405273438e-01 -2.20898911356925964e-01 -2.14070290327072144e-01,
              -9.61495041847229004e-01 1.39061376452445984e-01 -2.37042546272277832e-01,
              -9.63876008987426758e-01 -2.30005562305450439e-01 1.34314671158790588e-01,
              9.51475501060485840e-01 -2.20393493771553040e-01 -2.14758098125457764e-01,
              9.58311676979064941e-01 1.58142149448394775e-01 -2.37970083951950073e-01,
              9.61016118526458740e-01 -2.29249238967895508e-01 1.54572978615760803e-01,
              -8.96280944347381592e-01 3.66667926311492920e-01 -2.49469667673110962e-01,
              8.96561741828918457e-01 3.66366475820541382e-01 -2.48902812600135803e-01,
              -7.22512777429074049e-04 9.57427978515625000e-01 -2.88671284914016724e-01,
              -9.61032032966613770e-01 -1.95117399096488953e-01 -1.95822969079017639e-01,
              2.55902530625462532e-03 -9.74694490432739258e-01 -2.23526462912559509e-01,
              9.61347162723541260e-01 -1.94756567478179932e-01 -1.94631621241569519e-01,
              -9.74496364593505859e-01 -1.24375261366367340e-01 -1.86782151460647583e-01,
              -9.72097277641296387e-01 8.76719057559967041e-02 -2.17578694224357605e-01,
              9.74364101886749268e-01 -1.26548394560813904e-01 -1.86011165380477905e-01,
              9.72852468490600586e-01 8.17764028906822205e-02 -2.16496378183364868e-01,
              -7.06285238265991211e-01 6.44073367118835449e-01 -2.93820798397064209e-01,
              7.07238376140594482e-01 6.43102288246154785e-01 -2.93655067682266235e-01,
              5.23479306139051914e-04 9.65411007404327393e-01 -2.60732263326644897e-01,
              -1.63260358385741711e-03 -8.32381427288055420e-01 5.54200768470764160e-01,
              -8.51038396358489990e-01 -2.26100757718086243e-01 4.73932534456253052e-01,
              8.51861715316772461e-01 -2.24901601672172546e-01 4.73023146390914917e-01,
              -6.27386325504630804e-04 -1.44708573818206787e-01 9.89474117755889893e-01,
              -7.16618955135345459e-01 4.00835186243057251e-01 5.70778787136077881e-01,
              7.17056870460510254e-01 4.00828063488006592e-01 5.70233583450317383e-01,
              -6.89374865032732487e-04 8.13679277896881104e-01 5.81313669681549072e-01,
              -5.60209038667380810e-04 2.73795634508132935e-01 9.61787700653076172e-01,
              -9.18240249156951904e-01 -1.22175984084606171e-01 3.76706659793853760e-01,
              2.73092859424650669e-03 -8.86436045169830322e-01 4.62843000888824463e-01,
              9.18685257434844971e-01 -1.22480720281600952e-01 3.75520795583724976e-01,
              -9.18845295906066895e-01 -1.19628019630908966e-01 3.76048535108566284e-01,
              -9.18771982192993164e-01 -1.12771660089492798e-01 3.78339231014251709e-01,
              -9.17108178138732910e-01 -1.19861967861652374e-01 3.80191713571548462e-01,
              7.24467041436582804e-04 -7.33486562967300415e-02 9.97306108474731445e-01,
              9.18923616409301758e-01 -1.21472664177417755e-01 3.75264972448348999e-01,
              9.18905436992645264e-01 -1.18715681135654449e-01 3.76190602779388428e-01,
              9.18236196041107178e-01 -1.21562868356704712e-01 3.76914769411087036e-01,
              -4.67123508453369141e-01 6.61510229110717773e-01 5.86685478687286377e-01,
              4.68145638704299927e-01 6.60631477832794189e-01 5.86860835552215576e-01,
              6.47599459625780582e-04 8.56044709682464600e-01 5.16901373863220215e-01,
              7.34294764697551727e-04 5.16208589076995850e-01 8.56462597846984863e-01,
              6.46225234959274530e-04 -7.90736973285675049e-02 -9.96868550777435303e-01,
              6.75016082823276520e-04 -9.41287800669670105e-02 -9.95559811592102051e-01,
              1.54532806482166052e-03 -8.82997810840606689e-01 -4.69374507665634155e-01,
              -2.39055138081312180e-02 -9.03870463371276855e-02 -9.95619773864746094e-01,
              -3.84522765874862671e-01 -2.03056693077087402e-01 -9.00505542755126953e-01,
              2.48724706470966339e-02 -9.02610942721366882e-02 -9.95607495307922363e-01,
              5.98783313762396574e-04 4.76232357323169708e-03 -9.99988496303558350e-01,
              3.89785885810852051e-01 -2.03862607479095459e-01 -8.98057341575622559e-01,
              -3.33368808031082153e-01 1.38624966144561768e-01 -9.32549417018890381e-01,
              5.66960894502699375e-04 6.61661624908447266e-02 -9.97808456420898438e-01,
              3.39126765727996826e-01 1.39362350106239319e-01 -9.30360794067382813e-01,
              1.65109115187078714e-03 8.05525839328765869e-01 -5.92558324337005615e-01,
              1.54851074330508709e-03 -9.08533692359924316e-01 -4.17808771133422852e-01,
              -8.19742977619171143e-02 -8.93443286418914795e-01 -4.41632598638534546e-01,
              -5.14550209045410156e-01 -6.54259324073791504e-01 -5.54240763187408447e-01,
              -4.35171514749526978e-01 -8.70364129543304443e-01 -2.30417132377624512e-01,
              8.35813730955123901e-02 -8.93651545047760010e-01 -4.40909385681152344e-01,
              5.20608246326446533e-01 -6.50113523006439209e-01 -5.53461372852325439e-01,
              4.39698368310928345e-01 -8.70228767395019531e-01 -2.22187459468841553e-01,
              -8.17811846733093262e-01 -2.42718711495399475e-01 -5.21796345710754395e-01,
              8.18592548370361328e-01 -2.42785707116127014e-01 -5.20539522171020508e-01,
              -8.52297425270080566e-01 1.64742469787597656e-01 -4.96436357498168945e-01,
              -4.79620933532714844e-01 6.90202832221984863e-01 -5.41833698749542236e-01,
              8.53157043457031250e-01 1.64968386292457581e-01 -4.94882255792617798e-01,
              4.87776696681976318e-01 6.84913337230682373e-01 -5.41264832019805908e-01,
              -2.97762572765350342e-01 8.12996625900268555e-01 -5.00373721122741699e-01,
              -4.45071220397949219e-01 7.83967018127441406e-01 -4.32790130376815796e-01,
              2.94472396373748779e-01 8.15860509872436523e-01 -4.97652143239974976e-01,
              1.59280467778444290e-03 9.09761726856231689e-01 -4.15127813816070557e-01,
              4.50824826955795288e-01 7.85785138607025146e-01 -4.23436790704727173e-01,
              -4.34939503669738770e-01 -8.84702503681182861e-01 -1.67717218399047852e-01,
              1.86952215153723955e-03 -9.90378975868225098e-01 -1.38368844985961914e-01,
              4.39454525709152222e-01 -8.82608592510223389e-01 -1.66978359222412109e-01,
              -4.60738629102706909e-01 -8.71312737464904785e-01 -1.68920084834098816e-01,
              4.61933374404907227e-01 -8.70859682559967041e-01 -1.67991012334823608e-01,
              -9.60345864295959473e-01 -2.30830296874046326e-01 -1.56375154852867126e-01,
              9.60366129875183105e-01 -2.31287106871604919e-01 -1.55573770403862000e-01,
              -9.76087391376495361e-01 1.57988786697387695e-01 -1.49308323860168457e-01,
              9.76108133792877197e-01 1.58677041530609131e-01 -1.48440077900886536e-01,
              -5.67271888256072998e-01 8.04219901561737061e-01 -1.77293345332145691e-01,
              -4.57182317972183228e-01 8.72330605983734131e-01 -1.73273220658302307e-01,
              1.94530270528048277e-03 9.90181684494018555e-01 -1.39772936701774597e-01,
              5.67377865314483643e-01 8.04374396800994873e-01 -1.76250532269477844e-01,
              4.62260484695434570e-01 8.69790077209472656e-01 -1.72570079565048218e-01,
              -4.34872388839721680e-01 -8.85651469230651855e-01 1.62811204791069031e-01,
              1.95900700055062771e-03 -9.90833580493927002e-01 1.35073825716972351e-01,
              4.39535021781921387e-01 -8.83383810520172119e-01 1.62609934806823730e-01,
              -4.61782008409500122e-01 -8.71714591979980469e-01 1.63923799991607666e-01,
              -4.35136258602142334e-01 -8.70450913906097412e-01 2.30155661702156067e-01,
              4.62974518537521362e-01 -8.71144533157348633e-01 1.63590490818023682e-01,
              4.39871221780776978e-01 -8.70241343975067139e-01 2.21795707941055298e-01,
              -9.61207151412963867e-01 -2.30579435825347900e-01 1.51373431086540222e-01,
              9.61067497730255127e-01 -2.31082573533058167e-01 1.51492774486541748e-01,
              -9.76861357688903809e-01 1.57784909009933472e-01 1.44380852580070496e-01,
              9.76771771907806396e-01 1.58425465226173401e-01 1.44285202026367188e-01,
              -5.68539261817932129e-01 8.04468810558319092e-01 1.72026395797729492e-01,
              -4.57095921039581299e-01 8.73341202735900879e-01 1.68340384960174561e-01,
              -4.43963080644607544e-01 7.81217038631439209e-01 4.38858419656753540e-01,
              1.98687310330569744e-03 9.90690529346466064e-01 1.36118724942207336e-01,
              5.68609476089477539e-01 8.04538190364837646e-01 1.71468988060951233e-01,
              4.62272107601165771e-01 8.70686113834381104e-01 1.67958885431289673e-01,
              4.50069814920425415e-01 7.83145546913146973e-01 4.29092288017272949e-01,
              1.77354086190462112e-03 -9.04693126678466797e-01 4.26060110330581665e-01,
              -8.10225754976272583e-02 -8.89493346214294434e-01 4.49707627296447754e-01,
              -5.11208832263946533e-01 -6.48941874504089355e-01 5.63506841659545898e-01,
              8.29161405563354492e-02 -8.89675498008728027e-01 4.49001550674438477e-01,
              1.78726995363831520e-03 -8.77620995044708252e-01 4.79351818561553955e-01,
              5.17771244049072266e-01 -6.44192159175872803e-01 5.62964856624603271e-01,
              -8.11896026134490967e-01 -2.41554528474807739e-01 5.31484961509704590e-01,
              -3.66647958755493164e-01 -1.97533503174781799e-01 9.09147858619689941e-01,
              7.51398853026330471e-04 -6.98040053248405457e-02 9.97560441493988037e-01,
              8.12391877174377441e-01 -2.41671338677406311e-01 5.30673503875732422e-01,
              3.72701287269592285e-01 -1.98545888066291809e-01 9.06461954116821289e-01,
              -8.47030103206634521e-01 1.63929089903831482e-01 5.05635499954223633e-01,
              -4.77202206850051880e-01 6.85034751892089844e-01 5.50459265708923340e-01,
              -3.12168955802917480e-01 1.34687423706054688e-01 9.40430641174316406e-01,
              6.38308003544807434e-04 6.31057769060134888e-02 9.98006641864776611e-01,
              8.47701966762542725e-01 1.63995593786239624e-01 5.04486680030822754e-01,
              4.85807538032531738e-01 6.79232299327850342e-01 5.50122261047363281e-01,
              3.18678915500640869e-01 1.35451555252075195e-01 9.38134670257568359e-01,
              -2.96161741018295288e-01 8.08594405651092529e-01 5.08392810821533203e-01,
              2.92725354433059692e-01 8.11541497707366943e-01 5.05680024623870850e-01,
              1.70907203573733568e-03 9.06288802623748779e-01 4.22655493021011353e-01,
              1.81541685014963150e-03 7.94693112373352051e-01 6.07008755207061768e-01,
              1.20676727965474129e-03 -6.51259794831275940e-02 -9.97876346111297607e-01,
              1.43063021823763847e-03 3.20927947759628296e-01 -9.47102546691894531e-01,
              3.40348714962601662e-03 -3.77761572599411011e-01 -9.25896644592285156e-01,
              -4.40090447664260864e-01 -1.14907480776309967e-01 -8.90570998191833496e-01,
              -7.16979444026947021e-01 -1.30369409918785095e-01 -6.84795081615447998e-01,
              4.39022511243820190e-01 -1.16855889558792114e-01 -8.90844523906707764e-01,
              7.26519703865051270e-01 -1.33506864309310913e-01 -6.74051165580749512e-01,
              -1.47032260894775391e-01 3.78225892782211304e-01 -9.13962066173553467e-01,
              -7.71294772624969482e-01 4.50620651245117188e-01 -4.49494600296020508e-01,
              1.49398252367973328e-01 3.77825021743774414e-01 -9.13744151592254639e-01,
              1.56977609731256962e-03 4.41434532403945923e-01 -8.97292077541351318e-01,
              7.76483774185180664e-01 4.48426336050033569e-01 -4.42703962326049805e-01,
              -8.68359923362731934e-01 -4.51119095087051392e-01 -2.06015974283218384e-01,
              3.94292362034320831e-03 -9.92336153984069824e-01 -1.23504482209682465e-01,
              8.73928248882293701e-01 -4.41518574953079224e-01 -2.03250601887702942e-01,
              -9.75839495658874512e-01 -1.28624588251113892e-01 -1.76615521311759949e-01,
              9.75486338138580322e-01 -1.32600203156471252e-01 -1.75623297691345215e-01,
              -8.63092422485351563e-01 4.53177571296691895e-01 -2.22938373684883118e-01,
              -8.01253557205200195e-01 5.53022384643554688e-01 -2.28383377194404602e-01,
              1.76427140831947327e-03 9.78985786437988281e-01 -2.03920990228652954e-01,
              8.64936709403991699e-01 4.49925690889358521e-01 -2.22376555204391479e-01,
              8.05004417896270752e-01 5.47818243503570557e-01 -2.27734670042991638e-01,
              -8.58279585838317871e-01 -3.44025433063507080e-01 3.80792200565338135e-01,
              4.04828554019331932e-03 -9.61748600006103516e-01 2.73903667926788330e-01,
              8.62787544727325439e-01 -3.37594598531723022e-01 3.76334398984909058e-01,
              -9.32669103145599365e-01 -1.22794210910797119e-01 3.39190095663070679e-01,
              -7.29180276393890381e-01 -1.34057357907295227e-01 6.71062409877777100e-01,
              1.06060307007282972e-03 -6.67647123336791992e-02 9.97768163681030273e-01,
              9.32873487472534180e-01 -1.26332700252532959e-01 3.37323486804962158e-01,
              7.38149166107177734e-01 -1.36824682354927063e-01 6.60616993904113770e-01,
              -7.90166378021240234e-01 4.36257570981979370e-01 4.30483937263488770e-01,
              -7.63596236705780029e-01 4.77504253387451172e-01 4.34638351202011108e-01,
              -7.47297704219818115e-01 4.33683067560195923e-01 5.03453195095062256e-01,
              1.76132097840309143e-03 9.14551079273223877e-01 4.04466658830642700e-01,
              1.46515923552215099e-03 2.59945899248123169e-01 9.65622067451477051e-01,
              7.92292475700378418e-01 4.33456122875213623e-01 4.29404735565185547e-01,
              7.67286777496337891e-01 4.72706109285354614e-01 4.33381944894790649e-01,
              7.52090990543365479e-01 4.31416392326354980e-01 4.98235940933227539e-01,
              -5.41621702723205090e-04 1.29012554883956909e-01 -9.91642832756042480e-01,
              -5.38226857315748930e-04 8.33517462015151978e-02 -9.96520042419433594e-01,
              -5.47350794076919556e-02 5.29168210923671722e-02 -9.97097730636596680e-01,
              -3.83697569370269775e-01 -3.17694306373596191e-01 -8.67090821266174316e-01,
              5.37060685455799103e-02 5.22918254137039185e-02 -9.97186660766601563e-01,
              -4.81288356240838766e-04 1.04859948158264160e-01 -9.94486868381500244e-01,
              3.81061434745788574e-01 -3.18905174732208252e-01 -8.67808520793914795e-01,
              1.18473410606384277e-01 4.50420454144477844e-02 -9.91935133934020996e-01,
              -3.49252746673300862e-04 2.71134898066520691e-02 -9.99632298946380615e-01,
              -1.27329081296920776e-01 4.27008308470249176e-02 -9.90940928459167480e-01,
              -4.31239604949951172e-01 5.49655556678771973e-01 -7.15479671955108643e-01,
              -3.58014483936131001e-03 1.39990314841270447e-01 -9.90146398544311523e-01,
              4.21116650104522705e-01 5.43820738792419434e-01 -7.25892364978790283e-01,
              -4.72993075847625732e-01 -6.85133814811706543e-01 -5.53957760334014893e-01,
              5.34755199623759836e-05 -8.94284904003143311e-01 -4.47498053312301636e-01,
              1.99082191102206707e-04 -9.41431164741516113e-01 -3.37205231189727783e-01,
              4.71905738115310669e-01 -6.86532974243164063e-01 -5.53152322769165039e-01,
              -7.48577475547790527e-01 -3.79405796527862549e-01 -5.43767392635345459e-01,
              7.46972858905792236e-01 -3.83394390344619751e-01 -5.43176114559173584e-01,
              -8.94985795021057129e-01 6.00949861109256744e-02 -4.42028343677520752e-01,
              -9.72052931785583496e-01 6.34322986006736755e-02 -2.26029634475708008e-01,
              8.94484579563140869e-01 5.42036630213260651e-02 -4.43800926208496094e-01,
              9.68966007232666016e-01 5.62172904610633850e-02 -2.40716606378555298e-01,
              -5.94085633754730225e-01 5.93618988990783691e-01 -5.42843222618103027e-01,
              -4.61601287126541138e-01 7.05273628234863281e-01 -5.38064479827880859e-01,
              -5.27529092505574226e-03 9.12263810634613037e-01 -4.09569203853607178e-01,
              5.94180643558502197e-01 5.88576197624206543e-01 -5.48203825950622559e-01,
              4.50819492340087891e-01 7.08696305751800537e-01 -5.42688965797424316e-01,
              3.75241012079641223e-04 -9.90314126014709473e-01 -1.38844490051269531e-01,
              -2.44068816304206848e-01 -9.57549750804901123e-01 -1.53391465544700623e-01,
              -4.28775787353515625e-01 -8.88229310512542725e-01 -1.64924353361129761e-01,
              2.42298468947410583e-01 -9.58076655864715576e-01 -1.52906954288482666e-01,
              4.28690135478973389e-01 -8.88362646102905273e-01 -1.64428055286407471e-01,
              -9.22896981239318848e-01 -3.49752157926559448e-01 -1.61042392253875732e-01,
              -9.85894322395324707e-01 -8.20784419775009155e-02 -1.45861431956291199e-01,
              9.21154856681823730e-01 -3.54554414749145508e-01 -1.60514518618583679e-01,
              9.83582973480224609e-01 -1.04850806295871735e-01 -1.46870017051696777e-01,
              -9.88456547260284424e-01 6.40892758965492249e-02 -1.37281581759452820e-01,
              -9.80944633483886719e-01 1.32980585098266602e-01 -1.41646638512611389e-01,
              9.88822102546691895e-01 5.64772039651870728e-02 -1.37989625334739685e-01,
              9.79772984981536865e-01 1.39393761754035950e-01 -1.43576890230178833e-01,
              -7.86659419536590576e-01 5.93528330326080322e-01 -1.69973686337471008e-01,
              -4.72212463617324829e-01 8.65489363670349121e-01 -1.67163252830505371e-01,
              -7.01242778450250626e-03 9.90806341171264648e-01 -1.35106131434440613e-01,
              7.92890071868896484e-01 5.84591805934906006e-01 -1.71981945633888245e-01,
              4.57971066236495972e-01 8.72775852680206299e-01 -1.68892785906791687e-01,
              4.00712160626426339e-04 -9.88982498645782471e-01 1.48032054305076599e-01,
              -2.38652065396308899e-01 -9.57399845123291016e-01 1.62575230002403259e-01,
              -4.29788768291473389e-01 -8.85668456554412842e-01 1.75707072019577026e-01,
              2.36934274435043335e-01 -9.57892358303070068e-01 1.62186160683631897e-01,
              2.65534268692135811e-04 -9.39638495445251465e-01 3.42168748378753662e-01,
              4.29755896329879761e-01 -8.85752022266387939e-01 1.75365895032882690e-01,
              -9.20690834522247314e-01 -3.50407689809799194e-01 1.71880409121513367e-01,
              -9.85077500343322754e-01 -7.52201154828071594e-02 1.54803827404975891e-01,
              9.18892800807952881e-01 -3.55223715305328369e-01 1.71616241335868835e-01,
              9.82855618000030518e-01 -9.80488434433937073e-02 1.56145095825195313e-01,
              -9.87138032913208008e-01 6.41225352883338928e-02 1.46447420120239258e-01,
              -9.80014920234680176e-01 1.29824697971343994e-01 1.50719180703163147e-01,
              -9.70932722091674805e-01 6.36945515871047974e-02 2.30722010135650635e-01,
              9.87450778484344482e-01 5.65035417675971985e-02 1.47472977638244629e-01,
              9.78838264942169189e-01 1.35959327220916748e-01 1.52940049767494202e-01,
              9.67768967151641846e-01 5.64492158591747284e-02 2.45431751012802124e-01,
              -7.83563911914825439e-01 5.93949615955352783e-01 1.82349920272827148e-01,
              -4.72137391567230225e-01 8.62954199314117432e-01 1.79989695549011230e-01,
              -6.95828814059495926e-03 9.89472925662994385e-01 1.44550755620002747e-01,
              7.89711773395538330e-01 5.85052192211151123e-01 1.84578537940979004e-01,
              4.57979291677474976e-01 8.70169103145599365e-01 1.81825920939445496e-01,
              -8.71811330318450928e-01 -3.38241040706634521e-01 -3.54313373565673828e-01,
              7.29867606423795223e-04 -2.02895596623420715e-01 -9.79200124740600586e-01,
              8.74341428279876709e-01 -3.37507158517837524e-01 -3.48734915256500244e-01,
              -8.73397290706634521e-01 2.19180122017860413e-01 -4.34899121522903442e-01,
              5.35073224455118179e-04 1.20075806975364685e-01 -9.92764592170715332e-01,
              8.76354575157165527e-01 2.18440413475036621e-01 -4.29285973310470581e-01,
              -4.68696773052215576e-01 -6.61409199237823486e-01 5.85543513298034668e-01,
              1.04349252069368958e-04 -8.79622459411621094e-01 4.75672483444213867e-01,
              4.67715203762054443e-01 -6.62728488445281982e-01 5.84836244583129883e-01,
              -7.25989520549774170e-01 -3.76145303249359131e-01 5.75720310211181641e-01,
              -3.72168630361557007e-01 -3.08995157480239868e-01 8.75221431255340576e-01,
              -4.53512882813811302e-04 9.28929820656776428e-02 9.95675981044769287e-01,
              7.24293649196624756e-01 -3.80053550004959106e-01 5.75289487838745117e-01,
              3.69778931140899658e-01 -3.10234963893890381e-01 8.75795543193817139e-01,
              -8.82807970046997070e-01 5.93666881322860718e-02 4.65967446565628052e-01,
              2.33794040977954865e-02 4.46797460317611694e-02 9.98727738857269287e-01,
              -3.31081013428047299e-04 2.27378848940134048e-02 9.99741435050964355e-01,
              8.82061481475830078e-01 5.36189675331115723e-02 4.68073278665542603e-01,
              -3.18856388330459595e-02 4.23705056309700012e-02 9.98593032360076904e-01,
              -5.74341475963592529e-01 5.85309624671936035e-01 5.72315037250518799e-01,
              -4.55314606428146362e-01 6.85449302196502686e-01 5.68197011947631836e-01,
              -4.23488020896911621e-01 5.38953483104705811e-01 7.28139460086822510e-01,
              -5.04930270835757256e-03 9.04346227645874023e-01 4.26769733428955078e-01,
              -3.38947284035384655e-03 2.38536581397056580e-01 9.71127569675445557e-01,
              5.73928177356719971e-01 5.80318808555603027e-01 5.77785849571228027e-01,
              4.44810748100280762e-01 6.88390135765075684e-01 5.72941899299621582e-01,
              4.13407415151596069e-01 5.32867491245269775e-01 7.38340377807617188e-01,
              -8.81943464279174805e-01 -3.65902870893478394e-01 -2.97137737274169922e-01,
              1.33992626797407866e-03 -9.44609701633453369e-01 -3.28193068504333496e-01,
              8.83570611476898193e-01 -3.62901329994201660e-01 -2.95982360839843750e-01,
              -8.95251393318176270e-01 -3.35187107324600220e-01 -2.93555051088333130e-01,
              -8.88524532318115234e-01 -3.42932194471359253e-01 3.04830491542816162e-01,
              8.95738840103149414e-01 -3.34640324115753174e-01 -2.92690575122833252e-01,
              9.00867462158203125e-01 -3.46099019050598145e-01 2.62017637491226196e-01,
              -9.40465033054351807e-01 2.10284948348999023e-01 -2.67031341791152954e-01,
              -9.11963582038879395e-01 3.00396502017974854e-01 -2.79435843229293823e-01,
              9.44052240811288357e-04 9.56012547016143799e-01 -2.93324172496795654e-01,
              9.40830826759338379e-01 2.09575846791267395e-01 -2.66299277544021606e-01,
              9.13421392440795898e-01 2.96974450349807739e-01 -2.78329879045486450e-01,
              1.27114576753228903e-03 -8.72359871864318848e-01 4.88862544298171997e-01,
              -8.35604071617126465e-01 -3.33043307065963745e-01 4.36861574649810791e-01,
              -8.65650415420532227e-01 -2.53153622150421143e-01 4.31928932666778564e-01,
              8.34600627422332764e-01 -3.34354877471923828e-01 4.37776863574981689e-01,
              7.03613215591758490e-04 -2.24998399615287781e-01 9.74358856678009033e-01,
              8.72663497924804688e-01 -2.29955643415451050e-01 4.30788606405258179e-01,
              -8.96064937114715576e-01 2.06566065549850464e-01 3.92935246229171753e-01,
              -8.79978716373443604e-01 2.52958148717880249e-01 4.02056753635406494e-01,
              -8.57241034507751465e-01 2.09871649742126465e-01 4.70203936100006104e-01,
              9.11126961000263691e-04 8.94989728927612305e-01 4.46085840463638306e-01,
              5.09959296323359013e-04 1.24365650117397308e-01 9.92236316204071045e-01,
              8.96724104881286621e-01 2.05963984131813049e-01 3.91745775938034058e-01,
              8.81531298160552979e-01 2.50102400779724121e-01 4.00438994169235229e-01,
              8.60011518001556396e-01 2.09226787090301514e-01 4.65407669544219971e-01,
              -6.78491429425776005e-04 -3.45670431852340698e-01 -9.38355743885040283e-01,
              -4.14107111282646656e-04 8.00322294235229492e-01 -5.99569916725158691e-01,
              1.81342358700931072e-03 -1.15093074738979340e-01 -9.93353068828582764e-01,
              -1.44484906923025846e-03 -9.44338083267211914e-01 -3.28973442316055298e-01,
              -7.69654035568237305e-01 -4.69298869371414185e-01 -4.32887107133865356e-01,
              7.69413232803344727e-01 -4.68606799840927124e-01 -4.34063255786895752e-01,
              -2.94680637307465076e-03 -9.90423321723937988e-01 -1.38032287359237671e-01,
              -2.36806154251098633e-01 8.80092799663543701e-01 -4.11533087491989136e-01,
              2.36173480749130249e-01 8.80173921585083008e-01 -4.11723226308822632e-01,
              -3.40493832482025027e-04 9.41203355789184570e-01 -3.37840437889099121e-01,
              -6.81847275700420141e-04 9.15967762470245361e-01 -4.01251226663589478e-01,
              4.86521795392036438e-03 -9.70374524593353271e-01 -2.41556718945503235e-01,
              -9.48075890541076660e-01 -1.54340699315071106e-01 -2.78084725141525269e-01,
              9.48217749595642090e-01 -1.56748741865158081e-01 -2.76248037815093994e-01,
              1.64423498790711164e-03 9.63480651378631592e-01 -2.67773002386093140e-01,
              6.52381824329495430e-03 -4.12593632936477661e-01 9.10891830921173096e-01,
              -8.73532146215438843e-04 -2.33567133545875549e-01 -9.72340285778045654e-01,
              -6.08325703069567680e-04 3.24872046709060669e-01 -9.45757806301116943e-01,
              1.60015036817640066e-03 -1.37282460927963257e-01 -9.90530669689178467e-01,
              1.75391812808811665e-03 4.11343097686767578e-01 -9.11478877067565918e-01,
              -1.24311877880245447e-03 -5.65062403678894043e-01 -8.25047254562377930e-01,
              -7.01065719127655029e-01 -2.82480478286743164e-01 -6.54760718345642090e-01,
              -9.22832131385803223e-01 -2.70356893539428711e-01 -2.74386644363403320e-01,
              7.03581213951110840e-01 -2.81794995069503784e-01 -6.52353465557098389e-01,
              9.20236170291900635e-01 -2.70339727401733398e-01 -2.82987236976623535e-01,
              -5.73809027671813965e-01 4.14198458194732666e-01 -7.06528723239898682e-01,
              -9.29643034934997559e-01 3.67001563310623169e-01 3.27677987515926361e-02,
              5.74645280838012695e-01 4.14918750524520874e-01 -7.05425560474395752e-01,
              -3.34538985043764114e-04 6.10696017742156982e-01 -7.91865050792694092e-01,
              9.29159402847290039e-01 3.68560671806335449e-01 2.87382062524557114e-02,
              3.76977119594812393e-03 -5.26465833187103271e-01 -8.50187897682189941e-01,
              -7.72812247276306152e-01 -1.86231493949890137e-01 -6.06695175170898438e-01,
              -8.74462723731994629e-01 -1.82664170861244202e-01 -4.49387043714523315e-01,
              7.66129016876220703e-01 -1.88984394073486328e-01 -6.14272952079772949e-01,
              8.84471654891967773e-01 -1.84574559330940247e-01 -4.28534805774688721e-01,
              -3.95502537488937378e-01 5.72673261165618896e-01 -7.18069016933441162e-01,
              3.96887242794036865e-01 5.71046233177185059e-01 -7.18600511550903320e-01,
              1.26702478155493736e-03 6.79277777671813965e-01 -7.33880162239074707e-01,
              -9.35851693153381348e-01 -3.02644729614257813e-01 1.80520981550216675e-01,
              -1.04153424035757780e-03 -9.86961245536804199e-01 1.60954594612121582e-01,
              9.35304999351501465e-01 -3.03797930479049683e-01 1.81414768099784851e-01,
              -9.63063716888427734e-01 -2.08941712975502014e-01 1.69857665896415710e-01,
              -9.04184401035308838e-01 -2.20694750547409058e-01 3.65710794925689697e-01,
              -2.49243777943775058e-04 -1.12898938357830048e-01 9.93606448173522949e-01,
              9.63098585605621338e-01 -2.08276405930519104e-01 1.70475944876670837e-01,
              9.02873814105987549e-01 -2.20252707600593567e-01 3.69198560714721680e-01,
              -9.21663641929626465e-01 3.34140598773956299e-01 1.97195932269096375e-01,
              -9.07465875148773193e-01 3.69127601385116577e-01 2.00625360012054443e-01,
              -8.99801313877105713e-01 3.39325726032257080e-01 2.74254679679870605e-01,
              -1.64560959092341363e-04 9.83461320400238037e-01 1.81118264794349670e-01,
              -1.76651315996423364e-04 1.90299510955810547e-01 9.81726050376892090e-01,
              9.21318173408508301e-01 3.34906935691833496e-01 1.97509825229644775e-01,
              9.06775653362274170e-01 3.70619893074035645e-01 2.00994551181793213e-01,
              8.98872375488281250e-01 3.40128272771835327e-01 2.76299089193344116e-01,
              -9.56897914409637451e-01 -2.41563424468040466e-01 1.61224931478500366e-01,
              2.89150979369878769e-03 -9.90959286689758301e-01 1.34131968021392822e-01,
              9.57714796066284180e-01 -2.39766255021095276e-01 1.59042418003082275e-01,
              -9.82439756393432617e-01 -1.17516137659549713e-01 1.44921019673347473e-01,
              -9.35235977172851563e-01 2.88529425859451294e-01 2.05145046114921570e-01,
              -9.07897770404815674e-01 -1.32774651050567627e-01 3.97608578205108643e-01,
              5.87231596000492573e-04 -7.00848624110221863e-02 9.97540831565856934e-01,
              9.82317686080932617e-01 -1.20163656771183014e-01 1.43571183085441589e-01,
              9.38080132007598877e-01 2.80321300029754639e-01 2.03533008694648743e-01,
              9.10764217376708984e-01 -1.34792238473892212e-01 3.90307098627090454e-01,
              -8.02094399929046631e-01 5.50362825393676758e-01 2.31830358505249023e-01,
              8.02492201328277588e-01 5.50026357173919678e-01 2.31251612305641174e-01,
              8.00810928922146559e-04 9.84183251857757568e-01 1.77151620388031006e-01,
              7.08063715137541294e-04 3.45441401004791260e-01 9.38440024852752686e-01,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
    </Transform>
  </Scene>
</template>

<script>
export default {
  name: "TriangleXdom",
  mounted: function() {
    this.$nextTick(function() {
      this.$emit("loaded", true);
    });
  },
};
</script>
